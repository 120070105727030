import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEmpty from '../global-functions/isEmpty';
import {
  Button,
  IconButton,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Modal,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const ContentRepositoryScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesCreateFolderPOST = RisingCoachesApi.useCreateFolderPOST();
  const risingCoachesCreateAdminFolderPOST =
    RisingCoachesApi.useCreateAdminFolderPOST();
  const risingCoachesDeleteFolderDELETE =
    RisingCoachesApi.useDeleteFolderDELETE();
  const risingCoachesDeleteAdminFolderDELETE =
    RisingCoachesApi.useDeleteAdminFolderDELETE();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setShowModal(props.route?.params?.nav_show_modal ?? '');
        const user = await RisingCoachesApi.getUserDetailGET(Constants, {
          user_id: Constants['user_id'],
        });
        const admin_result = user.isAdmin;
        setIsAdmin(admin_result);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [folderName, setFolderName] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isDeleteFolder, setIsDeleteFolder] = React.useState(false);
  const [selectedFolderId, setSelectedFolderId] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [showPersonal, setShowPersonal] = React.useState(true);
  const [showRising, setShowRising] = React.useState(false);
  const [showShared, setShowShared] = React.useState(false);

  return (
    <ScreenContainer
      style={styles(theme).screen}
      scrollable={true}
      hasTopSafeArea={false}
      hasSafeArea={false}
    >
      {/* isPaying */}
      <>
        {!Constants['user_paying'] ? null : (
          <View>
            <>
              {!showModal ? null : (
                <Modal visible={showModal} animationType={'slide'}>
                  <>
                    {!'Cancel' ? null : (
                      <View style={styles(theme).View394507ff}>
                        <TextInput
                          onChangeText={newTextInputValue => {
                            try {
                              setFolderName(newTextInputValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={styles(theme).TextInputb2cb2d6f}
                          value={folderName}
                          placeholder={'Folder Name'}
                          placeholderTextColor={theme.colors.light}
                        />
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* isPersonal */}
                        <>
                          {!showPersonal ? null : (
                            <View>
                              <View>
                                {/* Normal Button Solid */}
                                <>
                                  {Constants['is_loading'] ? null : (
                                    <Button
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            setGlobalVariableValue({
                                              key: 'is_loading',
                                              value: true,
                                            });
                                            await risingCoachesCreateFolderPOST.mutateAsync(
                                              { folder_name: folderName }
                                            );
                                            setShowModal(false);
                                            setGlobalVariableValue({
                                              key: 'is_loading',
                                              value: false,
                                            });
                                            setFolderName('');
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      style={styles(theme).Button8ce897e6}
                                      title={'Create'}
                                    />
                                  )}
                                </>
                              </View>
                            </View>
                          )}
                        </>
                        {/* isRising */}
                        <>
                          {!showRising ? null : (
                            <View>
                              <>
                                {!isAdmin ? null : (
                                  <View>
                                    {/* Admin Button Solid */}
                                    <>
                                      {Constants['is_loading'] ? null : (
                                        <Button
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: true,
                                                });
                                                await risingCoachesCreateAdminFolderPOST.mutateAsync(
                                                  { name: folderName }
                                                );
                                                setShowModal(false);
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: false,
                                                });
                                                setFolderName('');
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          style={styles(theme).Button8ce897e6}
                                          title={'Create'}
                                        />
                                      )}
                                    </>
                                  </View>
                                )}
                              </>
                              <>
                                {isAdmin ? null : (
                                  <View>
                                    {/* Normal Button Solid */}
                                    <>
                                      {Constants['is_loading'] ? null : (
                                        <Button
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: true,
                                                });
                                                await risingCoachesCreateFolderPOST.mutateAsync(
                                                  { folder_name: folderName }
                                                );
                                                setShowModal(false);
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: false,
                                                });
                                                setFolderName('');
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          style={styles(theme).Button8ce897e6}
                                          title={'Create'}
                                        />
                                      )}
                                    </>
                                  </View>
                                )}
                              </>
                            </View>
                          )}
                        </>
                        {/* Button Loading */}
                        <>
                          {!Constants['is_loading'] ? null : (
                            <Button
                              style={styles(theme).Button8ce897e6}
                              title={''}
                              loading={true}
                              disabled={false}
                            />
                          )}
                        </>
                        <Spacer top={8} right={8} bottom={8} left={8} />
                        {/* Button Outline */}
                        <Button
                          onPress={() => {
                            try {
                              setShowModal(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={styles(theme).Button9ecd5924}
                          title={'Cancel'}
                        />
                      </View>
                    )}
                  </>
                </Modal>
              )}
            </>
            <View>
              <View style={styles(theme).Viewba976a13}>
                <View style={styles(theme).Viewc992f941}>
                  {/* GridButtonActive */}
                  <>
                    {!showPersonal ? null : (
                      <Button
                        style={styles(theme).Button30b595d1}
                        title={'Personal Folders'}
                      />
                    )}
                  </>
                  {/* GridButtonInactive */}
                  <>
                    {showPersonal ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setShowPersonal(true);
                            setShowRising(false);
                            setShowShared(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).Button41cde2db}
                        title={'Personal Folders'}
                      />
                    )}
                  </>
                </View>

                <View style={styles(theme).Viewc992f941}>
                  {/* ListButtonActive */}
                  <>
                    {!showRising ? null : (
                      <Button
                        style={styles(theme).Button30b595d1}
                        title={'Rising Coaches'}
                      />
                    )}
                  </>
                  {/* ListButtonInactive */}
                  <>
                    {showRising ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setShowRising(true);
                            setShowPersonal(false);
                            setShowShared(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).Button41cde2db}
                        title={'Rising Coaches'}
                      />
                    )}
                  </>
                </View>

                <View style={styles(theme).Viewc992f941}>
                  {/* ListButtonActive */}
                  <>
                    {!showShared ? null : (
                      <Button
                        style={styles(theme).Button30b595d1}
                        title={'Shared Folders'}
                      />
                    )}
                  </>
                  {/* ListButtonInactive */}
                  <>
                    {showShared ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setShowPersonal(false);
                            setShowRising(false);
                            setShowShared(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).Button41cde2db}
                        title={'Shared Folders'}
                      />
                    )}
                  </>
                </View>
              </View>
            </View>
            {/* Add Folder View */}
            <>
              {!showPersonal ? null : (
                <View style={styles(theme).View30dde730}>
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModal(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View style={styles(theme).Viewce4accf0}>
                      {/* Add */}
                      <Text style={styles(theme).Text8ee82204}>
                        {'Create New Folder'}
                      </Text>
                      <IconButton
                        onPress={() => {
                          try {
                            setShowModal(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                        icon={'Ionicons/add-circle-sharp'}
                      />
                    </View>
                  </Touchable>
                </View>
              )}
            </>
            {/* isAdmin */}
            <>
              {!isAdmin ? null : (
                <View>
                  {/* Admin Folder View */}
                  <>
                    {!showRising ? null : (
                      <View style={styles(theme).View30dde730}>
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModal(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewce4accf0}>
                            {/* Add */}
                            <Text style={styles(theme).Text8ee82204}>
                              {'Create New Folder'}
                            </Text>
                            <IconButton
                              onPress={() => {
                                try {
                                  setShowModal(true);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              icon={'Ionicons/add-circle-sharp'}
                              size={32}
                            />
                          </View>
                        </Touchable>
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
            {/* Shared Fetch */}
            <>
              {!showShared ? null : (
                <RisingCoachesApi.FetchGetSharedFoldersGET method={'GET'}>
                  {({ loading, error, data, refetchGetSharedFolders }) => {
                    const sharedFetchData = data;
                    if (!sharedFetchData || loading) {
                      return <ActivityIndicator />;
                    }

                    if (error) {
                      return (
                        <Text style={{ textAlign: 'center' }}>
                          There was a problem fetching this data
                        </Text>
                      );
                    }

                    return (
                      <>
                        {/* Shared Grid */}
                        <FlatList
                          data={sharedFetchData}
                          listKey={'uEMa45Am'}
                          keyExtractor={sharedGridData =>
                            sharedGridData?.id ||
                            sharedGridData?.uuid ||
                            JSON.stringify(sharedGridData)
                          }
                          renderItem={({ item }) => {
                            const sharedGridData = item;
                            return (
                              <View style={styles(theme).View74183626}>
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'FolderContentsScreen',
                                        {
                                          folder_id: sharedGridData?.id,
                                          isShared: showShared,
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View style={styles(theme).Viewb43494d3}>
                                    <View style={styles(theme).Viewbc3bd0d2}>
                                      <View style={styles(theme).Viewc28fe8ca}>
                                        <Text
                                          style={styles(theme).Text41710d7f}
                                        >
                                          {sharedGridData?.name}
                                        </Text>
                                      </View>
                                    </View>

                                    <View style={styles(theme).View77d458ae}>
                                      <Text
                                        style={styles(theme).Text56ff1cc0}
                                        numberOfLines={1}
                                        ellipsizeMode={'tail'}
                                      >
                                        {sharedGridData?.properties?.name}{' '}
                                      </Text>

                                      <View style={styles(theme).Viewce4accf0}>
                                        <Text
                                          style={styles(theme).Textc3380ce3}
                                        >
                                          {sharedGridData &&
                                            sharedGridData['_contentcount']}
                                        </Text>

                                        <Text
                                          style={styles(theme).Textc430b794}
                                        >
                                          {' media items'}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              </View>
                            );
                          }}
                          style={styles(theme).FlatList989db244}
                          contentContainerStyle={
                            styles(theme).FlatList8ba83a99Content
                          }
                          numColumns={2}
                        />
                      </>
                    );
                  }}
                </RisingCoachesApi.FetchGetSharedFoldersGET>
              )}
            </>
            {/* Personal Fetch */}
            <>
              {!showPersonal ? null : (
                <RisingCoachesApi.FetchGetUserFoldersGET method={'GET'}>
                  {({ loading, error, data, refetchGetUserFolders }) => {
                    const personalFetchData = data;
                    if (!personalFetchData || loading) {
                      return <ActivityIndicator />;
                    }

                    if (error) {
                      return (
                        <Text style={{ textAlign: 'center' }}>
                          There was a problem fetching this data
                        </Text>
                      );
                    }

                    return (
                      <>
                        {/* Delete Modal */}
                        <>
                          {!isDeleteFolder ? null : (
                            <Modal
                              visible={isDeleteFolder}
                              animationType={'none'}
                            >
                              <View style={styles(theme).View92a6058e}>
                                <View style={styles(theme).View39912261}>
                                  <Text style={styles(theme).Textb17d301e}>
                                    {
                                      'Are you sure you want to delete this folder? All contents will be lost. '
                                    }
                                  </Text>
                                </View>

                                <View style={styles(theme).Viewd18f4f81}>
                                  {/* Button Solid */}
                                  <Button
                                    onPress={() => {
                                      try {
                                        setIsDeleteFolder(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={styles(theme).Button70db6fe4}
                                    title={'No'}
                                  />
                                  {/* Button Solid */}
                                  <>
                                    {Constants['is_loading'] ? null : (
                                      <Button
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              setGlobalVariableValue({
                                                key: 'is_loading',
                                                value: true,
                                              });
                                              await risingCoachesDeleteFolderDELETE.mutateAsync(
                                                { folders_id: selectedFolderId }
                                              );
                                              await refetchGetUserFolders();
                                              setGlobalVariableValue({
                                                key: 'is_loading',
                                                value: false,
                                              });
                                              setIsDeleteFolder(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        style={styles(theme).Buttonc78a1651}
                                        title={'Yes'}
                                      />
                                    )}
                                  </>
                                  {/* Button Solid */}
                                  <>
                                    {!Constants['is_loading'] ? null : (
                                      <Button
                                        style={styles(theme).Buttonc78a1651}
                                        title={'Yes'}
                                        disabled={true}
                                        loading={true}
                                      />
                                    )}
                                  </>
                                </View>
                              </View>
                            </Modal>
                          )}
                        </>
                        {/* No Data */}
                        <>
                          {!isEmpty(personalFetchData) ? null : (
                            <View style={styles(theme).View95cfd3d1}>
                              <Text style={styles(theme).Text97a79954}>
                                {'You have not created a folder yet.'}
                              </Text>
                            </View>
                          )}
                        </>
                        {/* Personal Grid */}
                        <FlatList
                          data={personalFetchData}
                          listKey={'aSQHua74'}
                          keyExtractor={personalGridData =>
                            personalGridData?.id ||
                            personalGridData?.uuid ||
                            JSON.stringify(personalGridData)
                          }
                          renderItem={({ item }) => {
                            const personalGridData = item;
                            return (
                              <View style={styles(theme).View74183626}>
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'FolderContentsScreen',
                                        {
                                          folder_id: personalGridData?.id,
                                          isShared: showShared,
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View style={styles(theme).Viewb43494d3}>
                                    <View style={styles(theme).Viewbc3bd0d2}>
                                      <View style={styles(theme).Viewc28fe8ca}>
                                        <Text
                                          style={styles(theme).Text41710d7f}
                                        >
                                          {personalGridData?.name}
                                        </Text>
                                      </View>
                                    </View>

                                    <View style={styles(theme).View77d458ae}>
                                      <Text
                                        style={styles(theme).Text56ff1cc0}
                                        numberOfLines={1}
                                        ellipsizeMode={'tail'}
                                      >
                                        {personalGridData?.properties?.name}{' '}
                                      </Text>

                                      <View style={styles(theme).View4762dfc8}>
                                        <View
                                          style={styles(theme).Viewce4accf0}
                                        >
                                          <Text
                                            style={styles(theme).Textc3380ce3}
                                          >
                                            {personalGridData &&
                                              personalGridData['_contentcount']}
                                          </Text>

                                          <Text
                                            style={styles(theme).Textc430b794}
                                          >
                                            {' media items'}
                                          </Text>
                                        </View>

                                        <View
                                          style={styles(theme).View39912261}
                                        >
                                          <IconButton
                                            onPress={() => {
                                              try {
                                                setSelectedFolderId(
                                                  personalGridData?.id
                                                );
                                                setIsDeleteFolder(true);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            size={24}
                                            icon={'Feather/trash-2'}
                                            color={theme.colors.error}
                                          />
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              </View>
                            );
                          }}
                          style={styles(theme).FlatList989db244}
                          contentContainerStyle={
                            styles(theme).FlatList8ba83a99Content
                          }
                          numColumns={2}
                        />
                      </>
                    );
                  }}
                </RisingCoachesApi.FetchGetUserFoldersGET>
              )}
            </>
            {/* Rising Fetch */}
            <>
              {!showRising ? null : (
                <RisingCoachesApi.FetchGetAdminFoldersGET method={'GET'}>
                  {({ loading, error, data, refetchGetAdminFolders }) => {
                    const risingFetchData = data;
                    if (!risingFetchData || loading) {
                      return <ActivityIndicator />;
                    }

                    if (error) {
                      return (
                        <Text style={{ textAlign: 'center' }}>
                          There was a problem fetching this data
                        </Text>
                      );
                    }

                    return (
                      <>
                        {/* Delete Modal */}
                        <>
                          {!isDeleteFolder ? null : (
                            <Modal
                              visible={isDeleteFolder}
                              animationType={'none'}
                            >
                              <View style={styles(theme).View92a6058e}>
                                <View style={styles(theme).View39912261}>
                                  <Text style={styles(theme).Textb17d301e}>
                                    {
                                      'Are you sure you want to delete this folder? All contents will be lost. '
                                    }
                                  </Text>
                                </View>

                                <View style={styles(theme).Viewd18f4f81}>
                                  {/* Button Solid */}
                                  <Button
                                    onPress={() => {
                                      try {
                                        setIsDeleteFolder(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={styles(theme).Button70db6fe4}
                                    title={'No'}
                                  />
                                  {/* Button Solid */}
                                  <>
                                    {Constants['is_loading'] ? null : (
                                      <Button
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              setGlobalVariableValue({
                                                key: 'is_loading',
                                                value: true,
                                              });
                                              await risingCoachesDeleteAdminFolderDELETE.mutateAsync(
                                                { folders_id: selectedFolderId }
                                              );
                                              await refetchGetAdminFolders();
                                              setGlobalVariableValue({
                                                key: 'is_loading',
                                                value: false,
                                              });
                                              setIsDeleteFolder(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        style={styles(theme).Buttonc78a1651}
                                        title={'Yes'}
                                      />
                                    )}
                                  </>
                                  {/* Button Solid */}
                                  <>
                                    {!Constants['is_loading'] ? null : (
                                      <Button
                                        style={styles(theme).Buttonc78a1651}
                                        title={'Yes'}
                                        disabled={true}
                                        loading={true}
                                      />
                                    )}
                                  </>
                                </View>
                              </View>
                            </Modal>
                          )}
                        </>
                        {/* No Data */}
                        <>
                          {!isEmpty(risingFetchData) ? null : (
                            <View style={styles(theme).View95cfd3d1}>
                              <Text style={styles(theme).Text97a79954}>
                                {'You have not created a folder yet.'}
                              </Text>
                            </View>
                          )}
                        </>
                        {/* Admin Grid */}
                        <FlatList
                          data={risingFetchData}
                          listKey={'YmXI75RU'}
                          keyExtractor={adminGridData =>
                            adminGridData?.id ||
                            adminGridData?.uuid ||
                            JSON.stringify(adminGridData)
                          }
                          renderItem={({ item }) => {
                            const adminGridData = item;
                            return (
                              <View style={styles(theme).View74183626}>
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'RisingCoachesContentScreen',
                                        {
                                          folder_id: adminGridData?.id,
                                          isShared: isAdmin,
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View style={styles(theme).Viewb43494d3}>
                                    <View style={styles(theme).Viewbc3bd0d2}>
                                      <View style={styles(theme).Viewc28fe8ca}>
                                        <Text
                                          style={styles(theme).Text41710d7f}
                                        >
                                          {adminGridData?.name}
                                        </Text>
                                      </View>
                                    </View>

                                    <View style={styles(theme).View77d458ae}>
                                      <Text
                                        style={styles(theme).Text56ff1cc0}
                                        numberOfLines={1}
                                        ellipsizeMode={'tail'}
                                      >
                                        {adminGridData?.properties?.name}{' '}
                                      </Text>

                                      <View style={styles(theme).View4762dfc8}>
                                        <View
                                          style={styles(theme).Viewce4accf0}
                                        >
                                          <Text
                                            style={styles(theme).Textc3380ce3}
                                          >
                                            {adminGridData &&
                                              adminGridData['_contentcount']}
                                          </Text>

                                          <Text
                                            style={styles(theme).Textc430b794}
                                          >
                                            {' media items'}
                                          </Text>
                                        </View>

                                        <View
                                          style={styles(theme).View39912261}
                                        >
                                          <IconButton
                                            onPress={() => {
                                              try {
                                                setSelectedFolderId(
                                                  adminGridData?.id
                                                );
                                                setIsDeleteFolder(true);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            size={24}
                                            icon={'Feather/trash-2'}
                                            color={theme.colors.error}
                                          />
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              </View>
                            );
                          }}
                          style={styles(theme).FlatList989db244}
                          contentContainerStyle={
                            styles(theme).FlatList8ba83a99Content
                          }
                          numColumns={2}
                        />
                      </>
                    );
                  }}
                </RisingCoachesApi.FetchGetAdminFoldersGET>
              )}
            </>
          </View>
        )}
      </>
      {/* NotPaying */}
      <>
        {Constants['user_paying'] ? null : (
          <View>
            {/* PayWall */}
            <View style={styles(theme).View74763ff2}>
              <Text style={styles(theme).Textb17d301e}>
                {
                  'This feature is only available to Rising Coaches members. Join our membership program to take advantage of everything Rising Coaches has to offer.'
                }
              </Text>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://www.risingcoaches.com/membership'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button47b42b4a}
                title={'Subscribe'}
              />
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button30b595d1: {
      backgroundColor: theme.colors.primary,
      borderRadius: 0,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    Button41cde2db: {
      backgroundColor: theme.colors.divider,
      borderRadius: 0,
      color: theme.colors.light,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    Button47b42b4a: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      textAlign: 'center',
    },
    Button70db6fe4: {
      backgroundColor: theme.colors.light,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      minWidth: 150,
      textAlign: 'center',
    },
    Button8ce897e6: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Button9ecd5924: {
      backgroundColor: 'transparent',
      borderColor: theme.colors['Error'],
      borderRadius: 8,
      borderWidth: 1,
      color: theme.colors['Error'],
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Buttonc78a1651: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      minWidth: 150,
      textAlign: 'center',
    },
    FlatList8ba83a99Content: {
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Text41710d7f: {
      alignSelf: 'center',
      color: theme.colors.surface,
      fontFamily: 'Roboto_700Bold',
      textAlign: 'center',
    },
    Text56ff1cc0: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 12,
    },
    Text8ee82204: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
      marginRight: 5,
    },
    Text97a79954: { color: theme.colors.light, textAlign: 'center' },
    TextInputb2cb2d6f: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Textb17d301e: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    Textc3380ce3: {
      color: theme.colors.primary,
      fontFamily: 'Poppins_600SemiBold',
    },
    Textc430b794: {
      color: theme.colors.primary,
      fontFamily: 'Poppins_500Medium',
      fontSize: 10,
    },
    View30dde730: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 5,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 5,
    },
    View394507ff: {
      justifyContent: 'center',
      marginBottom: 50,
      marginLeft: 50,
      marginRight: 50,
      marginTop: 100,
    },
    View39912261: { alignItems: 'center' },
    View4762dfc8: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    View74183626: {
      flex: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    View77d458ae: {
      flex: 1,
      justifyContent: 'space-between',
      paddingBottom: 12,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
    },
    View92a6058e: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      flex: 1,
      justifyContent: 'center',
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    View95cfd3d1: {
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 50,
    },
    Viewb43494d3: {
      backgroundColor: theme.colors.surface,
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 12,
      borderRightWidth: 1,
      borderTopWidth: 1,
      overflow: 'hidden',
    },
    Viewba976a13: {
      alignItems: 'center',
      backgroundColor: theme.colors.divider,
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 14,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flexDirection: 'row',
    },
    Viewbc3bd0d2: {
      alignItems: 'center',
      backgroundColor: theme.colors.secondary,
      height: 140,
      justifyContent: 'center',
    },
    Viewc28fe8ca: {
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    Viewc992f941: { flex: 1 },
    Viewce4accf0: { alignItems: 'center', flexDirection: 'row' },
    Viewd18f4f81: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: 30,
    },
    screen: { backgroundColor: theme.colors.background },
  });

export default withTheme(ContentRepositoryScreen);
