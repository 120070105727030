// This import is required if you are defining react components in this module.
import React, { useState, useEffect, useCallback } from 'react';
import InstaStory from 'react-native-insta-story';
// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { Text, View } from 'react-native';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <LiveStories.MyExampleComponent />
export const LiveStories = props => {
  const { navigation, stories } = props;

  // const [stories, setLiveStories] = useState(props.stories);

  const propsValid = props =>
    Object.values(props).every(prop => prop !== undefined);

  return propsValid(props) ? (
    <InstaStory
      key={stories}
      data={stories}
      duration={10}
      onStart={item => console.log(item)}
      onClose={item => console.log('close: ', item)}
      customSwipeUpComponent={
        <View>
          <Text>Swipe</Text>
        </View>
      }
      style={{ marginTop: 30 }}
    />
  ) : (
    <View></View>
  );
};
