import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  CircleImage,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const RecentPostsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const isWinner = custom => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if ((custom = '')) {
      return true;
    } else false;
  };

  // Converts a date
  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const formatScoreDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return CustomCode.moment(dateString).format('MMM Do');
  };

  const todayDate = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return CustomCode.moment().format('MMM Do');
  };

  // Takes image obj and returns the url within
  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj?.url;
  };

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesDeleteFeedPostDELETE =
    RisingCoachesApi.useDeleteFeedPostDELETE();
  const risingCoachesUnfollowUserDELETE =
    RisingCoachesApi.useUnfollowUserDELETE();
  const risingCoachesFollowUserPOST = RisingCoachesApi.useFollowUserPOST();
  const risingCoachesAddLikePOST = RisingCoachesApi.useAddLikePOST();
  const risingCoachesRemoveLikeDELETE = RisingCoachesApi.useRemoveLikeDELETE();
  const risingCoachesSaveFeedToFolderPOST =
    RisingCoachesApi.useSaveFeedToFolderPOST();

  const [folderList, setFolderList] = React.useState(
    "['Test One', 'Test Two']"
  );
  const [isFolderModal, setIsFolderModal] = React.useState(false);
  const [isMineSettings, setIsMineSettings] = React.useState(false);
  const [isSettings, setIsSettings] = React.useState(false);
  const [pickerValue, setPickerValue] = React.useState('');
  const [searchBarValue, setSearchBarValue] = React.useState('Naufel');
  const [selectedFeedPost, setSelectedFeedPost] = React.useState({});
  const [selectedPost, setSelectedPost] = React.useState(0);
  const [showNav, setShowNav] = React.useState(false);
  const [tokenIsValid, setTokenIsValid] = React.useState(false);

  return (
    <ScreenContainer
      style={styles(theme).screen}
      hasTopSafeArea={false}
      hasSafeArea={false}
    >
      {/* isPaying */}
      <>
        {!Constants['user_paying'] ? null : (
          <View style={styles(theme).Viewc992f941}>
            {/* All Action Sheet */}
            <ActionSheet visible={isSettings}>
              {/* Save Action */}
              <ActionSheetItem
                onPress={() => {
                  try {
                    setIsFolderModal(true);
                    setIsSettings(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={styles(theme).ActionSheetItemc521608b}
                label={'Save Post to Folder'}
                color={theme.colors.strong}
              />
              <ActionSheetCancel
                onPress={() => {
                  try {
                    setIsSettings(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                label={'Cancel'}
              />
            </ActionSheet>
            {/* isMine Action Sheet */}
            <ActionSheet visible={isMineSettings}>
              {/* Save Action */}
              <ActionSheetItem
                onPress={() => {
                  try {
                    setIsMineSettings(false);
                    setIsFolderModal(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={styles(theme).ActionSheetItemc521608b}
                label={'Save Post to Folder'}
                color={theme.colors.strong}
              />
              <ActionSheetItem
                onPress={() => {
                  const handler = async () => {
                    try {
                      await risingCoachesDeleteFeedPostDELETE.mutateAsync({
                        news_feed_content_id: selectedPost,
                      });
                      setIsMineSettings(false);
                      await refetchGetRecentUserPosts();
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).ActionSheetItemc521608b}
                label={'Delete Post'}
                color={theme.colors.error}
              />
              <ActionSheetCancel
                onPress={() => {
                  try {
                    setIsMineSettings(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                label={'Cancel'}
              />
            </ActionSheet>
            {/* Main Body */}
            <View style={styles(theme).Viewc992f941}>
              {/* Body */}
              <View style={styles(theme).View98b256f7}>
                <KeyboardAwareScrollView
                  style={styles(theme).KeyboardAwareScrollView989db244}
                  contentContainerStyle={
                    styles(theme).KeyboardAwareScrollView39912261Content
                  }
                  showsVerticalScrollIndicator={false}
                  keyboardShouldPersistTaps={'never'}
                >
                  <RisingCoachesApi.FetchGetRecentUserPostsGET
                    user_id={props.route?.params?.navigate_user_id ?? ''}
                  >
                    {({ loading, error, data, refetchGetRecentUserPosts }) => {
                      const fetchData = data;
                      if (!fetchData || loading) {
                        return <ActivityIndicator />;
                      }

                      if (error) {
                        return (
                          <Text style={{ textAlign: 'center' }}>
                            There was a problem fetching this data
                          </Text>
                        );
                      }

                      return (
                        <FlatList
                          data={fetchData?.items}
                          listKey={'sYbOBUyI'}
                          keyExtractor={listData =>
                            listData?.id ||
                            listData?.uuid ||
                            JSON.stringify(listData)
                          }
                          renderItem={({ item }) => {
                            const listData = item;
                            return (
                              <>
                                {/* Post Touchable */}
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate('PostDetailsScreen', {
                                        feed_post_id: listData?.id,
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* NewFeedCard */}
                                  <Surface
                                    style={styles(theme).Surface25748f87}
                                    elevation={1}
                                  >
                                    {/* PostHeader */}
                                    <View style={styles(theme).View7963ad69}>
                                      {/* LeftAligned */}
                                      <View style={styles(theme).Viewce4accf0}>
                                        {/* Light BG */}
                                        <View
                                          style={styles(theme).Viewff6e3680}
                                        >
                                          <CircleImage
                                            source={{
                                              uri: `${listData?.author?.profile_image?.url}`,
                                            }}
                                            size={50}
                                          />
                                        </View>

                                        <View
                                          style={styles(theme).View589b1f61}
                                        >
                                          {/* Coach Name */}
                                          <Text
                                            style={styles(theme).Textbba75e6f}
                                          >
                                            {listData?.author?.name}
                                          </Text>
                                          {/* Coach Title */}
                                          <>
                                            {!'author.position' ? null : (
                                              <Text
                                                style={
                                                  styles(theme).Text3d1f55dd
                                                }
                                              >
                                                {listData?.author?.job_title}
                                              </Text>
                                            )}
                                          </>
                                        </View>
                                      </View>
                                      {/* RightAligned */}
                                      <View style={styles(theme).View967fd4f5}>
                                        <>
                                          {listData?.isMine ? null : (
                                            <View>
                                              <>
                                                {Constants[
                                                  'is_loading'
                                                ] ? null : (
                                                  <View
                                                    style={
                                                      styles(theme).View3b52982a
                                                    }
                                                  >
                                                    {/* Button Outline */}
                                                    <>
                                                      {!listData?.isFollowed ? null : (
                                                        <Button
                                                          onPress={() => {
                                                            const handler =
                                                              async () => {
                                                                try {
                                                                  setGlobalVariableValue(
                                                                    {
                                                                      key: 'is_loading',
                                                                      value: true,
                                                                    }
                                                                  );
                                                                  await risingCoachesUnfollowUserDELETE.mutateAsync(
                                                                    {
                                                                      followed_id:
                                                                        listData
                                                                          ?.author
                                                                          ?.id,
                                                                    }
                                                                  );
                                                                  await refetchGetRecentUserPosts();
                                                                  setGlobalVariableValue(
                                                                    {
                                                                      key: 'is_loading',
                                                                      value: false,
                                                                    }
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              };
                                                            handler();
                                                          }}
                                                          style={
                                                            styles(theme)
                                                              .Button514b1e5f
                                                          }
                                                          title={'Following'}
                                                        />
                                                      )}
                                                    </>
                                                    {/* Button Solid */}
                                                    <>
                                                      {listData?.isFollowed ? null : (
                                                        <Button
                                                          onPress={() => {
                                                            const handler =
                                                              async () => {
                                                                try {
                                                                  setGlobalVariableValue(
                                                                    {
                                                                      key: 'is_loading',
                                                                      value: true,
                                                                    }
                                                                  );
                                                                  await risingCoachesFollowUserPOST.mutateAsync(
                                                                    {
                                                                      followed_id:
                                                                        listData
                                                                          ?.author
                                                                          ?.id,
                                                                    }
                                                                  );
                                                                  await refetchGetRecentUserPosts();
                                                                  setGlobalVariableValue(
                                                                    {
                                                                      key: 'is_loading',
                                                                      value: false,
                                                                    }
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              };
                                                            handler();
                                                          }}
                                                          style={
                                                            styles(theme)
                                                              .Button35fe6c70
                                                          }
                                                          title={'Follow'}
                                                        />
                                                      )}
                                                    </>
                                                  </View>
                                                )}
                                              </>
                                              {/* Button Loading */}
                                              <>
                                                {!Constants[
                                                  'is_loading'
                                                ] ? null : (
                                                  <Button
                                                    style={
                                                      styles(theme)
                                                        .Button35fe6c70
                                                    }
                                                    title={''}
                                                    loading={true}
                                                    disabled={true}
                                                  />
                                                )}
                                              </>
                                            </View>
                                          )}
                                        </>
                                        {/* isMine Setting Button */}
                                        <>
                                          {!listData?.isMine ? null : (
                                            <IconButton
                                              onPress={() => {
                                                try {
                                                  setSelectedPost(listData?.id);
                                                  setIsMineSettings(true);

                                                  const value34xqcM0N =
                                                    listData;
                                                  setSelectedFeedPost(
                                                    value34xqcM0N
                                                  );
                                                  const selectedFeedObject =
                                                    value34xqcM0N;
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                              icon={
                                                'MaterialCommunityIcons/dots-horizontal-circle'
                                              }
                                              size={32}
                                              color={theme.colors.secondary}
                                            />
                                          )}
                                        </>
                                        {/* all Setting Button */}
                                        <>
                                          {listData?.isMine ? null : (
                                            <IconButton
                                              onPress={() => {
                                                try {
                                                  setSelectedPost(listData?.id);
                                                  setIsSettings(true);

                                                  const value4bqQF7Dx =
                                                    listData;
                                                  setSelectedFeedPost(
                                                    value4bqQF7Dx
                                                  );
                                                  const selectedFeedObject =
                                                    value4bqQF7Dx;
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                              icon={
                                                'MaterialCommunityIcons/dots-horizontal-circle'
                                              }
                                              size={32}
                                              color={theme.colors.secondary}
                                            />
                                          )}
                                        </>
                                      </View>
                                    </View>
                                    {/* Description */}
                                    <View style={styles(theme).View78aa819f}>
                                      <Text style={styles(theme).Texte9f9757c}>
                                        {listData?.text_content}
                                      </Text>
                                    </View>
                                    {/* Content Image */}
                                    <>
                                      {!listData?.isImage ? null : (
                                        <View
                                          style={styles(theme).View7237486d}
                                        >
                                          <ImageBackground
                                            style={
                                              styles(theme)
                                                .ImageBackground4e77c9d7
                                            }
                                            source={{
                                              uri: `${listData?.image?.url}`,
                                            }}
                                            resizeMode={'cover'}
                                          >
                                            <View
                                              style={styles(theme).View323c3df1}
                                            >
                                              <>
                                                {!listData?.isSponsored ? null : (
                                                  <View
                                                    style={
                                                      styles(theme).Viewfa68522b
                                                    }
                                                  >
                                                    <View
                                                      style={
                                                        styles(theme)
                                                          .Viewc992f941
                                                      }
                                                    >
                                                      <Text
                                                        style={
                                                          styles(theme)
                                                            .Text8be633e3
                                                        }
                                                      >
                                                        {'Sponsored'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </View>
                                          </ImageBackground>
                                        </View>
                                      )}
                                    </>
                                    {/* Content Video */}
                                    <>
                                      {!listData?.isVideo ? null : (
                                        <View
                                          style={styles(theme).View7237486d}
                                        >
                                          <View
                                            style={styles(theme).View6248e6e5}
                                          >
                                            <>
                                              {!listData?.isSponsored ? null : (
                                                <View
                                                  style={
                                                    styles(theme).Viewfa68522b
                                                  }
                                                >
                                                  <View
                                                    style={
                                                      styles(theme).Viewc992f941
                                                    }
                                                  >
                                                    <Text
                                                      style={
                                                        styles(theme)
                                                          .Text8be633e3
                                                      }
                                                    >
                                                      {'Sponsored'}
                                                    </Text>
                                                  </View>
                                                </View>
                                              )}
                                            </>
                                          </View>
                                          <WebView
                                            style={
                                              styles(theme).WebView4a0d7678
                                            }
                                            source={{
                                              uri: `${listData?.youtube_render_link}`,
                                            }}
                                          />
                                        </View>
                                      )}
                                    </>
                                    {/* Content File */}
                                    <>
                                      {!listData?.isFile ? null : (
                                        <View
                                          style={styles(theme).View7237486d}
                                        >
                                          <View
                                            style={styles(theme).View6248e6e5}
                                          >
                                            <>
                                              {!listData?.isSponsored ? null : (
                                                <View
                                                  style={
                                                    styles(theme).Viewfa68522b
                                                  }
                                                >
                                                  <View
                                                    style={
                                                      styles(theme).Viewc992f941
                                                    }
                                                  >
                                                    <Text
                                                      style={
                                                        styles(theme)
                                                          .Text8be633e3
                                                      }
                                                    >
                                                      {'Sponsored'}
                                                    </Text>
                                                  </View>
                                                </View>
                                              )}
                                            </>
                                          </View>
                                          <WebView
                                            style={
                                              styles(theme).WebView4a0d7678
                                            }
                                            source={{
                                              uri: `${listData?.file?.url}`,
                                            }}
                                          />
                                        </View>
                                      )}
                                    </>
                                    {/* Engagement */}
                                    <View style={styles(theme).Viewb7197252}>
                                      {/* LeftAligned */}
                                      <View style={styles(theme).Viewdebd3207}>
                                        {/* Loading Likes */}
                                        <>
                                          {!Constants['is_loading'] ? null : (
                                            <View
                                              style={styles(theme).Viewdebd3207}
                                            >
                                              <Touchable>
                                                <Icon
                                                  style={
                                                    styles(theme).Icon2cd37c54
                                                  }
                                                  name={'AntDesign/sync'}
                                                  size={20}
                                                  color={theme.colors.error}
                                                />
                                              </Touchable>

                                              <Text
                                                style={
                                                  styles(theme).Texte9f9757c
                                                }
                                              >
                                                {'-'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        {/* Likes */}
                                        <>
                                          {Constants['is_loading'] ? null : (
                                            <View
                                              style={styles(theme).Viewdebd3207}
                                            >
                                              <>
                                                {listData?.liked ? null : (
                                                  <Touchable
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: true,
                                                              }
                                                            );
                                                            await risingCoachesAddLikePOST.mutateAsync(
                                                              {
                                                                feed_post_id:
                                                                  listData?.id,
                                                              }
                                                            );
                                                            await refetchGetRecentUserPosts();
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: false,
                                                              }
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                  >
                                                    <>
                                                      {listData?.liked ? null : (
                                                        <Icon
                                                          style={
                                                            styles(theme)
                                                              .Icon2cd37c54
                                                          }
                                                          name={
                                                            'AntDesign/hearto'
                                                          }
                                                          size={20}
                                                          color={
                                                            theme.colors.error
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  </Touchable>
                                                )}
                                              </>
                                              <>
                                                {!listData?.liked ? null : (
                                                  <Touchable
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: true,
                                                              }
                                                            );
                                                            await risingCoachesRemoveLikeDELETE.mutateAsync(
                                                              {
                                                                post_id:
                                                                  listData?.id,
                                                              }
                                                            );
                                                            await refetchGetRecentUserPosts();
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: false,
                                                              }
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                  >
                                                    <>
                                                      {!listData?.liked ? null : (
                                                        <Icon
                                                          style={
                                                            styles(theme)
                                                              .Icon2cd37c54
                                                          }
                                                          name={
                                                            'AntDesign/heart'
                                                          }
                                                          size={20}
                                                          color={
                                                            theme.colors.error
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  </Touchable>
                                                )}
                                              </>
                                              <Text
                                                style={
                                                  styles(theme).Texte9f9757c
                                                }
                                              >
                                                {listData?.like_count}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        {/* Comments */}
                                        <View
                                          style={styles(theme).Viewdebd3207}
                                        >
                                          <Icon
                                            style={styles(theme).Icon2cd37c54}
                                            name={'Ionicons/chatbox-ellipses'}
                                            size={20}
                                            color={theme.colors.medium}
                                          />
                                          <Text
                                            style={styles(theme).Texte9f9757c}
                                          >
                                            {
                                              listData?.number_of_comments_of_news_feed_posts
                                            }
                                          </Text>
                                        </View>
                                      </View>
                                      {/* Right Aligned */}
                                      <View style={styles(theme).Viewa81770e9}>
                                        <Text
                                          style={styles(theme).Text00433c9a}
                                        >
                                          {convertDate(listData?.created_at)}
                                        </Text>
                                      </View>
                                    </View>
                                    {/* See More */}
                                    <View>
                                      {/* Make a Comment */}
                                      <Text style={styles(theme).Text5eb21b5e}>
                                        {'See more'}
                                      </Text>
                                    </View>
                                  </Surface>
                                </Touchable>
                              </>
                            );
                          }}
                          style={styles(theme).FlatListdf29e2e2}
                          contentContainerStyle={
                            styles(theme).FlatLista1614b10Content
                          }
                          numColumns={1}
                          horizontal={false}
                        />
                      );
                    }}
                  </RisingCoachesApi.FetchGetRecentUserPostsGET>
                </KeyboardAwareScrollView>
              </View>
            </View>
            <>
              {!isFolderModal ? null : (
                <Modal
                  visible={isFolderModal}
                  animationType={'slide'}
                  presentationStyle={'overFullScreen'}
                >
                  <View style={styles(theme).Viewc53c672f}>
                    <Text style={styles(theme).Text46b70f57}>
                      {'Select Which Folder to Save Post To'}
                    </Text>

                    <ScrollView
                      style={styles(theme).ScrollView989db244}
                      contentContainerStyle={
                        styles(theme).ScrollViewb1683a67Content
                      }
                      showsVerticalScrollIndicator={true}
                      bounces={true}
                    >
                      <RisingCoachesApi.FetchGetUserFoldersGET>
                        {({ loading, error, data, refetchGetUserFolders }) => {
                          const fetchData = data;
                          if (!fetchData || loading) {
                            return <ActivityIndicator />;
                          }

                          if (error) {
                            return (
                              <Text style={{ textAlign: 'center' }}>
                                There was a problem fetching this data
                              </Text>
                            );
                          }

                          return (
                            <FlatList
                              data={fetchData}
                              listKey={'oifralo3'}
                              keyExtractor={listData =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              renderItem={({ item }) => {
                                const listData = item;
                                return (
                                  <View style={styles(theme).View28f9c811}>
                                    {/* Button Outline */}
                                    <Button
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            await risingCoachesSaveFeedToFolderPOST.mutateAsync(
                                              {
                                                content:
                                                  selectedFeedPost?.image,
                                                content_title:
                                                  selectedFeedPost?.content_title,
                                                file_content:
                                                  selectedFeedPost?.file,
                                                folder_id: listData?.id,
                                                isFile:
                                                  selectedFeedPost?.isFile,
                                                isImage:
                                                  selectedFeedPost?.isImage,
                                                isShared:
                                                  selectedFeedPost?.isShared,
                                                isVideo:
                                                  selectedFeedPost?.isVideo,
                                                tags: selectedFeedPost?.tags,
                                                text_content:
                                                  selectedFeedPost?.text_content,
                                                youtubeId:
                                                  selectedFeedPost?.youtubeId,
                                              }
                                            );
                                            setIsFolderModal(false);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      style={styles(theme).Button1e9377d5}
                                      title={`${listData?.name}`}
                                    />
                                  </View>
                                );
                              }}
                              style={styles(theme).FlatList989db244}
                              contentContainerStyle={
                                styles(theme).FlatListc992f941Content
                              }
                              numColumns={1}
                            />
                          );
                        }}
                      </RisingCoachesApi.FetchGetUserFoldersGET>
                    </ScrollView>
                  </View>
                </Modal>
              )}
            </>
          </View>
        )}
      </>
      {/* NotPaying */}
      <>
        {Constants['user_paying'] ? null : (
          <View>
            {/* PayWall */}
            <View style={styles(theme).View74763ff2}>
              <Text style={styles(theme).Textb17d301e}>
                {
                  'This feature is only available to Rising Coaches members. Join our membership program to take advantage of everything Rising Coaches has to offer.'
                }
              </Text>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://www.risingcoaches.com/membership'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button47b42b4a}
                title={'Subscribe'}
              />
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    ActionSheetItemc521608b: { textAlign: 'center' },
    Button1e9377d5: {
      backgroundColor: 'transparent',
      borderRadius: 8,
      borderWidth: 1,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Button35fe6c70: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontSize: 12,
      textAlign: 'center',
    },
    Button47b42b4a: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      textAlign: 'center',
    },
    Button514b1e5f: {
      backgroundColor: 'transparent',
      borderRadius: 8,
      borderWidth: 1,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    Divider192bcda6: { height: 1, marginTop: 10, width: '100%' },
    Fetch431eb058: { minHeight: 40 },
    FlatLista1614b10Content: {
      alignContent: 'center',
      alignSelf: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    FlatListc992f941Content: { flex: 1 },
    FlatListdf29e2e2: { width: '100%' },
    Icon2cd37c54: { marginLeft: 10, marginRight: 5 },
    ImageBackground4e77c9d7: { height: '100%', minWidth: 320, width: '100%' },
    KeyboardAwareScrollView39912261Content: { alignItems: 'center' },
    ScrollViewb1683a67Content: { justifyContent: 'space-around' },
    Surface25748f87: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      backgroundColor: theme.colors.surface,
      borderRadius: 20,
      justifyContent: 'center',
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      minHeight: 400,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
    },
    Text00433c9a: { alignSelf: 'flex-end', color: theme.colors.strong },
    Text0ffa4cd0: { color: theme.colors.strong, textAlign: 'left' },
    Text20cad67c: {
      color: theme.colors.secondary,
      fontFamily: 'Roboto_700Bold',
      marginBottom: 2,
      marginTop: 5,
      textAlign: 'left',
    },
    Text3d1f55dd: {
      color: theme.colors.light,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
    },
    Text46b70f57: {
      color: theme.colors.strong,
      fontFamily: 'Mulish_700Bold',
      fontSize: 16,
    },
    Text5eb21b5e: {
      color: theme.colors.primary,
      fontFamily: 'Roboto_500Medium',
      fontSize: 12,
      marginTop: 10,
      textAlign: 'left',
    },
    Text8be633e3: {
      color: theme.colors.surface,
      fontFamily: 'Poppins_500Medium',
      fontSize: 16,
    },
    Textb17d301e: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    Textbba75e6f: {
      color: theme.colors.medium,
      fontFamily: 'Roboto_700Bold',
      fontSize: 16,
      marginBottom: 2,
    },
    Texte9f9757c: { color: theme.colors.strong },
    View28f9c811: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 10,
      marginTop: 10,
    },
    View323c3df1: { maxWidth: '40%', minHeight: 50 },
    View3b52982a: { flexDirection: 'row', marginRight: 5 },
    View589b1f61: { marginLeft: 10 },
    View612957e4: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      marginTop: 8,
    },
    View6248e6e5: {
      bottom: 1,
      maxWidth: '40%',
      minHeight: 50,
      position: 'absolute',
      right: 3,
    },
    View7237486d: {
      alignContent: 'center',
      alignSelf: 'stretch',
      borderRadius: 12,
      justifyContent: 'space-around',
      marginBottom: 8,
      marginTop: 5,
      maxHeight: 300,
      maxWidth: '100%',
      minHeight: 180,
      minWidth: 280,
      overflow: 'hidden',
    },
    View74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    View78aa819f: {
      alignContent: 'stretch',
      alignSelf: 'flex-start',
      marginBottom: 5,
      marginTop: 5,
    },
    View7963ad69: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 3,
    },
    View967fd4f5: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    View98b256f7: { backgroundColor: theme.colors.background, flex: 1 },
    Viewa81770e9: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    Viewb7197252: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      marginTop: 3,
    },
    Viewc53c672f: {
      alignContent: 'stretch',
      alignItems: 'center',
      alignSelf: 'stretch',
      justifyContent: 'space-around',
      marginTop: 100,
    },
    Viewc992f941: { flex: 1 },
    Viewce4accf0: { alignItems: 'center', flexDirection: 'row' },
    Viewdebd3207: { flexDirection: 'row' },
    Viewe051ef16: { alignSelf: 'stretch' },
    Viewfa68522b: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      flexDirection: 'row',
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 8,
      paddingTop: 6,
    },
    Viewff672992: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 35,
      justifyContent: 'center',
      marginRight: 5,
      width: 35,
    },
    Viewff6e3680: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 65,
      justifyContent: 'center',
      width: 65,
    },
    WebView4a0d7678: { flex: 1, minHeight: '100%' },
    screen: { backgroundColor: theme.colors['Background'], flex: 1 },
  });

export default withTheme(RecentPostsScreen);
