import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const MemberDetailScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const sendDirectMessage = (my_id, rec_id, auth_header) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    let result = [];
    result.push(my_id);
    result.push(rec_id);
    result = result.sort();

    try {
      const getOptions = {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + auth_header,
          'Content-Type': 'application/json',
        }),
      };
      fetch(
        'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chatrooms/existing/' +
          result.join('-'),
        getOptions
      ).then(response =>
        response.json().then(data => {
          if (data.id) {
            navigation.navigate('ChatScreen', {
              chatroom_id: data?.id,
            });
          } else {
            //Else navigate them to below logic that creates a new chatroom
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                Authorization: 'Bearer ' + auth_header,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                members_ids: result,
                title: result.join('-'),
              }),
            };
            fetch(
              'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chatrooms',
              requestOptions
            ).then(response =>
              response.json().then(data =>
                navigation.navigate('ChatScreen', {
                  chatroom_id: data?.id,
                })
              )
            );
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const { theme } = props;
  const { navigation } = props;

  const [imageBase, setImageBase] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={false}
    >
      <RisingCoachesApi.FetchGetUserDetailGET
        method={'GET'}
        user_id={props.route?.params?.navigate_user_Id ?? ''}
      >
        {({ loading, error, data, refetchGetUserDetail }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              <Touchable>
                <View style={styles(theme).View70b89981}>
                  <ImageBackground
                    style={styles(theme).ImageBackground4ebf21b5}
                    source={{ uri: `${fetchData?.profile_image?.url}` }}
                    resizeMode={'cover'}
                  >
                    <View style={styles(theme).View291ac3f1}>
                      <View style={styles(theme).Viewfa68522b}>
                        <View style={styles(theme).Viewc992f941}>
                          <Text style={styles(theme).Text8be633e3}>
                            {fetchData?.name}
                          </Text>
                        </View>

                        <View>
                          {/* Button Outline */}
                          <>
                            {fetchData?.isMe ? null : (
                              <Button
                                onPress={() => {
                                  try {
                                    sendDirectMessage(
                                      Constants['user_id'],
                                      props.route?.params?.navigate_user_Id ??
                                        '',
                                      Constants['auth_header']
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={styles(theme).Buttoneae56793}
                                title={'Message'}
                              />
                            )}
                          </>
                          {/* Button Outline */}
                          <>
                            {!fetchData?.isMe ? null : (
                              <Button
                                onPress={() => {
                                  try {
                                    navigation.navigate('OnboardingScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={styles(theme).Buttoneae56793}
                                title={'Edit Profile'}
                              />
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                  </ImageBackground>
                </View>
              </Touchable>

              <View>
                <View style={styles(theme).View291ac3f1}>
                  <Text style={styles(theme).Text00ef0353}>
                    {fetchData?.job_title}
                  </Text>

                  <Text
                    style={styles(theme).Text8cfc6364}
                    textBreakStrategy={'highQuality'}
                    ellipsizeMode={'tail'}
                    allowFontScaling={true}
                    numberOfLines={2}
                  >
                    {fetchData?.current_university}
                  </Text>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  <View>
                    <View style={styles(theme).Viewdebd3207}>
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('JobTimelineScreen', {
                              nav_user_id: fetchData?.id,
                              nav_is_me: fetchData?.isMe,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).Touchable93d77a0e}
                      >
                        <View style={styles(theme).View11851b7e}>
                          <Icon
                            size={24}
                            color={theme.colors.primary}
                            name={'MaterialIcons/work'}
                          />
                          <Spacer right={2} left={2} />
                          <Text style={styles(theme).Textde21574d}>
                            {'Job Timeline'}
                          </Text>
                        </View>
                      </Touchable>
                      <Spacer top={8} right={6} bottom={8} left={6} />
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('RecentPostsScreen', {
                              navigate_user_id: fetchData?.id,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).Touchable93d77a0e}
                      >
                        <View style={styles(theme).View11851b7e}>
                          <Icon
                            size={20}
                            color={theme.colors.primary}
                            name={'Entypo/back-in-time'}
                          />
                          <Spacer right={2} left={2} />
                          <Text style={styles(theme).Textde21574d}>
                            {'Recent Posts'}
                          </Text>
                        </View>
                      </Touchable>
                      <Spacer top={8} right={6} bottom={8} left={6} />
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ResumeScreen', {
                              nav_user_id: fetchData?.id,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).Touchable93d77a0e}
                      >
                        <View style={styles(theme).View0efbb706}>
                          <Icon
                            size={24}
                            color={theme.colors.primary}
                            name={'Ionicons/ios-document-text-outline'}
                          />
                          <Spacer right={2} left={2} />
                          <Text style={styles(theme).Textde21574d}>
                            {'Resume'}
                          </Text>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                  <Spacer top={12} right={8} bottom={12} left={8} />
                  <View>
                    <Text style={styles(theme).Text3f9840b9}>{'Bio'}</Text>
                    <Spacer top={6} right={8} bottom={6} left={8} />
                    <Text style={styles(theme).Textfe0e0a4d}>
                      {fetchData?.bio}
                    </Text>
                  </View>
                  <Spacer top={12} right={8} bottom={12} left={8} />
                  <View>
                    <Text style={styles(theme).Text3f9840b9}>
                      {'Social Media'}
                    </Text>
                    <Spacer top={6} right={8} bottom={6} left={8} />
                    <>
                      {!(fetchData && fetchData['_islinkedin']) ? null : (
                        <View style={styles(theme).Viewce4accf0}>
                          <View style={styles(theme).Viewc992f941}>
                            <Text style={styles(theme).Textbfc69c67}>
                              {'LinkedIn'}
                            </Text>
                          </View>

                          <View style={styles(theme).Viewbfdbee05}>
                            <IconButton
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${fetchData?.linkedin_link}`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              size={32}
                              icon={'AntDesign/linkedin-square'}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    <Divider
                      style={styles(theme).Dividera72a5a5e}
                      color={theme.colors.lightest}
                    />
                    <>
                      {!(fetchData && fetchData['_istwitter']) ? null : (
                        <View style={styles(theme).Viewce4accf0}>
                          <View style={styles(theme).Viewc992f941}>
                            <Text style={styles(theme).Textbfc69c67}>
                              {'Twitter'}
                            </Text>
                          </View>

                          <View style={styles(theme).Viewbfdbee05}>
                            <IconButton
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${fetchData?.twitter_link}`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              size={32}
                              icon={'AntDesign/twitter'}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    <Divider
                      style={styles(theme).Dividera72a5a5e}
                      color={theme.colors.lightest}
                    />
                    <>
                      {!(fetchData && fetchData['_isuniversitybio']) ? null : (
                        <View style={styles(theme).Viewce4accf0}>
                          <View style={styles(theme).Viewc992f941}>
                            <Text style={styles(theme).Textbfc69c67}>
                              {'University Bio'}
                            </Text>
                          </View>

                          <View style={styles(theme).Viewbfdbee05}>
                            <IconButton
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${fetchData?.university_bio_link}`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              size={32}
                              icon={'FontAwesome/university'}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                  <Spacer top={16} right={8} bottom={16} left={8} />
                </View>
              </View>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetUserDetailGET>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Buttoneae56793: {
      backgroundColor: 'transparent',
      borderBottomWidth: 2,
      borderColor: theme.colors.surface,
      borderLeftWidth: 2,
      borderRadius: 8,
      borderRightWidth: 2,
      borderTopWidth: 2,
      borderWidth: 1,
      color: theme.colors.surface,
      fontFamily: 'Poppins_700Bold',
      textAlign: 'center',
    },
    Dividera72a5a5e: { height: 1, marginBottom: 12, marginTop: 12 },
    ImageBackground4ebf21b5: {
      height: '100%',
      justifyContent: 'flex-end',
      width: '100%',
    },
    Text00ef0353: {
      color: theme.colors.light,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
    },
    Text3f9840b9: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 16,
    },
    Text8be633e3: {
      color: theme.colors.surface,
      fontFamily: 'Poppins_500Medium',
      fontSize: 16,
    },
    Text8cfc6364: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 22,
    },
    Textbfc69c67: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_400Regular',
    },
    Textde21574d: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
    },
    Textfe0e0a4d: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 14,
      lineHeight: 26,
      textAlign: 'left',
    },
    Touchable93d77a0e: { maxWidth: '30%' },
    View0efbb706: {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View11851b7e: {
      alignItems: 'center',
      alignSelf: 'stretch',
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View291ac3f1: {
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    View70b89981: { height: 420, width: '100%' },
    Viewbfdbee05: { alignItems: 'center', flex: 1 },
    Viewc992f941: { flex: 1 },
    Viewce4accf0: { alignItems: 'center', flexDirection: 'row' },
    Viewdebd3207: { flexDirection: 'row' },
    Viewfa68522b: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      flexDirection: 'row',
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 8,
      paddingTop: 6,
    },
  });

export default withTheme(MemberDetailScreen);
