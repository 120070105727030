import React from 'react';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { StyleSheet } from 'react-native';

const ConsultingScreen = props => {
  const { theme } = props;

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <WebView
        style={styles(theme).WebViewc992f941}
        source={{ uri: 'https://www.risingcoaches.com/consulting' }}
      />
    </ScreenContainer>
  );
};

const styles = theme => StyleSheet.create({ WebViewc992f941: { flex: 1 } });

export default withTheme(ConsultingScreen);
