import * as React from 'react';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/DraftbitTheme.js';
import LinkingConfiguration from './LinkingConfiguration.js';

import AccountSettingsScreen from './screens/AccountSettingsScreen';
import AddJobScreen from './screens/AddJobScreen';
import CalendarAddScreen from './screens/CalendarAddScreen';
import CalendarScreen_coaa8c4F from './screens/CalendarScreen_coaa8c4F';
import ChatScreen from './screens/ChatScreen';
import ChatVideoMeetingScreen from './screens/ChatVideoMeetingScreen';
import ConsultingScreen from './screens/ConsultingScreen';
import ContentDetailsScreen from './screens/ContentDetailsScreen';
import ContentRepositoryScreen from './screens/ContentRepositoryScreen';
import CreateFeedPostScreen from './screens/CreateFeedPostScreen';
import CreateStoryScreen from './screens/CreateStoryScreen';
import DEIAllianceScreen from './screens/DEIAllianceScreen';
import EventDetailsScreen from './screens/EventDetailsScreen';
import FeaturedVideoScreen from './screens/FeaturedVideoScreen';
import FolderContentsScreen from './screens/FolderContentsScreen';
import InboxScreen from './screens/InboxScreen';
import JobTimelineScreen from './screens/JobTimelineScreen';
import JobsBoardScreen from './screens/JobsBoardScreen';
import LiveMeetingScreen from './screens/LiveMeetingScreen';
import MemberDetailScreen from './screens/MemberDetailScreen';
import MemberDirectoryScreen from './screens/MemberDirectoryScreen';
import NewsFeedScreen from './screens/NewsFeedScreen';
import NotificationsScreen from './screens/NotificationsScreen';
import OnboardingScreen from './screens/OnboardingScreen';
import OpportunityDetailsScreen from './screens/OpportunityDetailsScreen';
import PasswordRecoveryScreen from './screens/PasswordRecoveryScreen';
import PostDetailsScreen from './screens/PostDetailsScreen';
import RecentPostsScreen from './screens/RecentPostsScreen';
import ResumeScreen from './screens/ResumeScreen';
import RisingCoachesContentDetailsScreen from './screens/RisingCoachesContentDetailsScreen';
import RisingCoachesContentScreen from './screens/RisingCoachesContentScreen';
import ShareFolderScreen from './screens/ShareFolderScreen';
import SignInScreen from './screens/SignInScreen';
import SignUpScreen from './screens/SignUpScreen';
import StartNewChatScreen from './screens/StartNewChatScreen';
import UploadAdminContentScreen from './screens/UploadAdminContentScreen';
import UploadContentScreen from './screens/UploadContentScreen';
import WelcomeScreen from './screens/WelcomeScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function Placeholder() {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#131A2A',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 36,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          fontSize: 24,
          fontWeight: 'bold',
          marginBottom: 12,
          color: '#FFF',
        }}
      >
        Missing Screen
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        This screen is not in a navigator.
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        Go to Navigation mode, and click the + (plus) icon in the Navigator tab
        on the left side to add this screen to a Navigator.
      </Text>
      <Text style={{ textAlign: 'center', fontSize: 16, color: '#FFF' }}>
        If the screen is in a Tab Navigator, make sure the screen is assigned to
        a tab in the Config panel on the right.
      </Text>
    </View>
  );
}
export default function RootAppNavigator() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator
        headerMode="float"
        initialRouteName="WelcomeScreen"
        screenOptions={{
          headerStyle: {
            backgroundColor: theme.colors.secondary,
          },
          headerTintColor: theme.colors.custom_rgb255_255_255,
          headerTransparent: false,
        }}
      >
        <Stack.Screen
          name="NewsFeedScreen"
          component={NewsFeedScreen}
          options={{
            headerShown: false,
            headerTransparent: false,
            title: 'News Feed',
          }}
        />
        <Stack.Screen
          name="PostDetailsScreen"
          component={PostDetailsScreen}
          options={{ title: 'Post Details' }}
        />
        <Stack.Screen
          name="WelcomeScreen"
          component={WelcomeScreen}
          options={{ headerShown: false, title: 'Welcome' }}
        />
        <Stack.Screen
          name="SignInScreen"
          component={SignInScreen}
          options={{ headerShown: false, title: 'Sign In' }}
        />
        <Stack.Screen
          name="SignUpScreen"
          component={SignUpScreen}
          options={{ headerShown: false, title: 'Sign Up' }}
        />
        <Stack.Screen
          name="MemberDirectoryScreen"
          component={MemberDirectoryScreen}
          options={{ title: 'Member Directory' }}
        />
        <Stack.Screen
          name="MemberDetailScreen"
          component={MemberDetailScreen}
          options={{ title: 'Member Detail Screen' }}
        />
        <Stack.Screen
          name="JobTimelineScreen"
          component={JobTimelineScreen}
          options={{ title: 'Job Timeline' }}
        />
        <Stack.Screen
          name="AddJobScreen"
          component={AddJobScreen}
          options={{ title: 'Add Job' }}
        />
        <Stack.Screen
          name="CalendarScreen_coaa8c4F"
          component={CalendarScreen_coaa8c4F}
          options={{ title: 'Calendar' }}
        />
        <Stack.Screen
          name="ChatScreen"
          component={ChatScreen}
          options={{
            headerShown: false,
            headerTransparent: false,
            title: 'Chat Screen',
          }}
        />
        <Stack.Screen
          name="ContentRepositoryScreen"
          component={ContentRepositoryScreen}
          options={{ title: 'Content Repository' }}
        />
        <Stack.Screen
          name="EventDetailsScreen"
          component={EventDetailsScreen}
          options={{ title: 'Event Details' }}
        />
        <Stack.Screen
          name="LiveMeetingScreen"
          component={LiveMeetingScreen}
          options={{ title: 'Live Meeting' }}
        />
        <Stack.Screen
          name="InboxScreen"
          component={InboxScreen}
          options={{ title: 'Inbox' }}
        />
        <Stack.Screen
          name="FolderContentsScreen"
          component={FolderContentsScreen}
          options={{ title: 'Folder Contents' }}
        />
        <Stack.Screen
          name="UploadContentScreen"
          component={UploadContentScreen}
          options={{ title: 'Upload Content' }}
        />
        <Stack.Screen
          name="ContentDetailsScreen"
          component={ContentDetailsScreen}
          options={{ title: 'Content Details' }}
        />
        <Stack.Screen
          name="StartNewChatScreen"
          component={StartNewChatScreen}
          options={{ title: 'Start New Chat' }}
        />
        <Stack.Screen
          name="JobsBoardScreen"
          component={JobsBoardScreen}
          options={{ title: 'Jobs Board' }}
        />
        <Stack.Screen
          name="OpportunityDetailsScreen"
          component={OpportunityDetailsScreen}
          options={{ title: 'Opportunity Details' }}
        />
        <Stack.Screen
          name="OnboardingScreen"
          component={OnboardingScreen}
          options={{ headerShown: false, title: 'Onboarding' }}
        />
        <Stack.Screen
          name="ResumeScreen"
          component={ResumeScreen}
          options={{ title: 'Resume' }}
        />
        <Stack.Screen
          name="CreateFeedPostScreen"
          component={CreateFeedPostScreen}
          options={{ headerTransparent: false, title: 'Create Feed Post' }}
        />
        <Stack.Screen
          name="ShareFolderScreen"
          component={ShareFolderScreen}
          options={{ title: 'Share Folder' }}
        />
        <Stack.Screen
          name="ChatVideoMeetingScreen"
          component={ChatVideoMeetingScreen}
          options={{ title: 'Chat Video Meeting' }}
        />
        <Stack.Screen
          name="ConsultingScreen"
          component={ConsultingScreen}
          options={{ title: 'Consulting' }}
        />
        <Stack.Screen
          name="DEIAllianceScreen"
          component={DEIAllianceScreen}
          options={{ title: 'DEI Alliance' }}
        />
        <Stack.Screen
          name="RecentPostsScreen"
          component={RecentPostsScreen}
          options={{ title: 'Recent Posts' }}
        />
        <Stack.Screen
          name="NotificationsScreen"
          component={NotificationsScreen}
          options={{ title: 'Notifications' }}
        />
        <Stack.Screen
          name="CreateStoryScreen"
          component={CreateStoryScreen}
          options={{ title: 'Create Story' }}
        />
        <Stack.Screen
          name="FeaturedVideoScreen"
          component={FeaturedVideoScreen}
          options={{ title: 'Featured Video' }}
        />
        <Stack.Screen
          name="CalendarAddScreen"
          component={CalendarAddScreen}
          options={{ title: 'Calendar Add' }}
        />
        <Stack.Screen
          name="AccountSettingsScreen"
          component={AccountSettingsScreen}
          options={{ title: 'Account Settings' }}
        />
        <Stack.Screen
          name="PasswordRecoveryScreen"
          component={PasswordRecoveryScreen}
          options={{ title: 'Password Recovery' }}
        />
        <Stack.Screen
          name="RisingCoachesContentScreen"
          component={RisingCoachesContentScreen}
          options={{ title: 'Rising Coaches Content' }}
        />
        <Stack.Screen
          name="UploadAdminContentScreen"
          component={UploadAdminContentScreen}
          options={{ title: 'Upload Admin Content' }}
        />
        <Stack.Screen
          name="RisingCoachesContentDetailsScreen"
          component={RisingCoachesContentDetailsScreen}
          options={{ title: 'Rising Coaches Content Details' }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({
    ios: {
      marginRight: 6,
    },
  }),
  headerIconRight: Platform.select({
    ios: {
      marginLeft: 6,
    },
  }),
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({
    ios: {
      marginLeft: 8,
    },
  }),
  headerContainerRight: Platform.select({
    ios: {
      marginRight: 8,
    },
  }),
  headerLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerLabel: {
    fontSize: 17,
    letterSpacing: 0.35,
  },
});
