import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as GooglePlacesInput from '../custom-files/GooglePlacesInput';
import * as Utils from '../utils';
import {
  Button,
  DatePicker,
  NumberInput,
  ScreenContainer,
  Spacer,
  TextField,
  withTheme,
} from '@draftbit/ui';
import {
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CalendarAddScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesCreateCalendarItemPOST =
    RisingCoachesApi.useCreateCalendarItemPOST();

  const [address, setAddress] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState('');
  const [detailsInput, setDetailsInput] = React.useState('');
  const [durationValue, setDurationValue] = React.useState(0);
  const [geo, setGeo] = React.useState({});
  const [isSetLocation, setIsSetLocation] = React.useState(false);
  const [locationInputValue, setLocationInputValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      style={styles(theme).screen}
      hasSafeArea={false}
      scrollable={false}
    >
      <>
        {!isSetLocation ? null : (
          <Utils.CustomCodeErrorBoundary>
            <GooglePlacesInput.GooglePlacesInput
              geo={geo}
              setGeo={setGeo}
              isSetLocation={isSetLocation}
              setIsSetLocation={setIsSetLocation}
              address={address}
              setAddress={setAddress}
              navigation={props.navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      <>
        {isSetLocation ? null : (
          <KeyboardAwareScrollView
            style={styles(theme).KeyboardAwareScrollView989db244}
            contentContainerStyle={
              styles(theme).KeyboardAwareScrollViewd0d594c2Content
            }
            extraScrollHeight={72}
          >
            <Spacer top={12} right={8} bottom={12} left={8} />
            <KeyboardAvoidingView enabled={true} behavior={'padding'}>
              {/* Simple */}
              <View style={styles(theme).View2481f309}>
                {/* Field */}
                <View style={styles(theme).Viewf9347e23}>
                  {/* Label */}
                  <Text style={styles(theme).Textc3544e87}>{'Event Name'}</Text>
                  <TextInput
                    onChangeText={newTextInputValue => {
                      const textInputValue = newTextInputValue;
                      try {
                        setTextInputValue(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={styles(theme).TextInput31122980}
                    value={textInputValue}
                    placeholder={'Event Name'}
                    keyboardType={'email-address'}
                    autoFocus={true}
                    enablesReturnKeyAutomatically={true}
                  />
                </View>
              </View>
              <Spacer top={12} right={8} bottom={12} left={8} />
              {/* Simple with Help Text */}
              <View>
                {/* Field */}
                <View style={styles(theme).Viewf9347e23}>
                  {/* Label */}
                  <Text style={styles(theme).Textc3544e87}>
                    {'Event Date & Start Time'}
                  </Text>
                  <DatePicker
                    onDateChange={newDatePickerValue => {
                      try {
                        setDatePickerValue(newDatePickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    label={''}
                    date={datePickerValue}
                    mode={'datetime'}
                    leftIconMode={'inset'}
                    type={'underline'}
                  />
                </View>
              </View>
              <Spacer top={12} right={8} bottom={12} left={8} />
              {/* Simple with Help Text */}
              <View>
                {/* Label */}
                <Text style={styles(theme).Textc3544e87}>{'Set Location'}</Text>
                {/* Field */}
                <View style={styles(theme).Viewf9347e23}>
                  <View style={styles(theme).Viewce4accf0}>
                    <Button
                      onPress={() => {
                        try {
                          setIsSetLocation(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={styles(theme).Button8ce897e6}
                      title={'Set'}
                    />
                    <Text style={styles(theme).Texte9f9757c}>{address}</Text>
                  </View>
                </View>
              </View>
              <Spacer top={12} right={8} bottom={12} left={8} />
              {/* Right Icon */}
              <View>
                {/* Field */}
                <View style={styles(theme).Viewf9347e23}>
                  {/* Label */}
                  <Text style={styles(theme).Textc3544e87}>{'Duration'}</Text>

                  <View style={styles(theme).View0aa36c3c}>
                    <View style={styles(theme).Viewc992f941}>
                      <NumberInput
                        onChangeText={newNumberInputValue => {
                          try {
                            setDurationValue(newNumberInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).NumberInputb2cb2d6f}
                        placeholder={'Enter duration in minutes'}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <Spacer top={12} right={8} bottom={12} left={8} />
              {/* Left Icon and Right Text */}
              <View>
                {/* Field */}
                <View style={styles(theme).Viewf9347e23}>
                  {/* Label */}
                  <Text style={styles(theme).Textc3544e87}>
                    {'Virtual Location (Optional)'}
                  </Text>

                  <View style={styles(theme).View0aa36c3c}>
                    <View style={styles(theme).Viewc992f941}>
                      <TextInput
                        style={styles(theme).TextInputcd2529d4}
                        placeholder={'URL of Virtual Meeting'}
                        keyboardType={'default'}
                        defaultValue={locationInputValue}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <Spacer top={12} right={8} bottom={12} left={8} />
              {/* Left Icon and Right Text */}
              <View>
                {/* Field */}
                <View style={styles(theme).Viewf9347e23}>
                  {/* Label */}
                  <Text style={styles(theme).Textc3544e87}>
                    {'Event Details'}
                  </Text>

                  <View style={styles(theme).View0aa36c3c}>
                    <View style={styles(theme).Viewc992f941}>
                      <TextField
                        onChangeText={newStyledTextAreaValue => {
                          try {
                            setDetailsInput(newStyledTextAreaValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextField2481f309}
                        placeholder={
                          'Let everyone know the details of your event'
                        }
                        type={'solid'}
                        multiline={true}
                        numberOfLines={4}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <Spacer top={12} right={8} bottom={12} left={8} />
              {/* Button Solid */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      await risingCoachesCreateCalendarItemPOST.mutateAsync({
                        creator_id: Constants['user_id'],
                        description: detailsInput,
                        duration_minutes: durationValue,
                        event_date: datePickerValue,
                        event_day: datePickerValue,
                        event_location: locationInputValue,
                        event_name: textInputValue,
                        geometry: geo,
                      });
                      navigation.navigate('CalendarScreen_coaa8c4F');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button8ce897e6}
                title={'Submit'}
              />
            </KeyboardAvoidingView>
          </KeyboardAwareScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button8ce897e6: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    KeyboardAwareScrollViewd0d594c2Content: {
      paddingBottom: 36,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    NumberInputb2cb2d6f: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    TextField2481f309: { backgroundColor: theme.colors.surface },
    TextInput31122980: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontSize: 16,
      fontWeight: '400',
      marginTop: 6,
    },
    TextInputcd2529d4: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontSize: 16,
      fontWeight: '400',
      marginLeft: 4,
    },
    Textc3544e87: {
      color: theme.colors.medium,
      fontFamily: 'System',
      fontSize: 12,
      fontWeight: '600',
    },
    Texte9f9757c: { color: theme.colors.strong },
    View0aa36c3c: { alignItems: 'center', flexDirection: 'row', marginTop: 6 },
    View2481f309: { backgroundColor: theme.colors.surface },
    Viewc992f941: { flex: 1 },
    Viewce4accf0: { alignItems: 'center', flexDirection: 'row' },
    Viewf9347e23: {
      backgroundColor: theme.colors.surface,
      borderBottomWidth: 2,
      borderColor: theme.colors.primary,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      paddingBottom: 8,
      paddingLeft: 14,
      paddingRight: 16,
      paddingTop: 8,
    },
    screen: { flex: 1 },
  });

export default withTheme(CalendarAddScreen);
