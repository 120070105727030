import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Utils from '../utils';
import {
  Button,
  CircleImage,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import {
  Image,
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CreateStoryScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesCreateStoryPOST = RisingCoachesApi.useCreateStoryPOST();

  const [fileBase, setFileBase] = React.useState('');
  const [imageBase, setImageBase] = React.useState('');
  const [showImage, setShowImage] = React.useState(false);

  return (
    <ScreenContainer
      style={styles(theme).screen}
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      <>
        {!Constants['user_paying'] ? null : (
          <KeyboardAvoidingView
            style={styles(theme).KeyboardAvoidingView6a955cc3}
            enabled={true}
            behavior={'position'}
          >
            <KeyboardAwareScrollView
              showsVerticalScrollIndicator={true}
              keyboardShouldPersistTaps={'never'}
              enableAutomaticScroll={true}
            >
              <View style={styles(theme).View69c8f9d6}>
                <View style={styles(theme).View4571e987}>
                  {/* Image Input */}
                  <View style={styles(theme).Vieweb8a4c38}>
                    <Text style={styles(theme).Texta9275998}>
                      {'Upload Your Image'}
                    </Text>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const base64_image = await Utils.openCamera({
                              allowsEditing: false,
                            });
                            setImageBase(base64_image);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View>
                        <>
                          {!imageBase ? null : (
                            <Image
                              style={styles(theme).Image636e6176}
                              source={{ uri: `${imageBase}` }}
                              resizeMode={'cover'}
                            />
                          )}
                        </>
                        <>
                          {imageBase ? null : (
                            <CircleImage
                              source={Images.UploadIcon}
                              size={100}
                            />
                          )}
                        </>
                      </View>
                    </Touchable>
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </View>

                <View style={styles(theme).View932040af}>
                  {/* Button Solid */}
                  <>
                    {Constants['is_loading'] ? null : (
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              const response =
                                await risingCoachesCreateStoryPOST.mutateAsync({
                                  content: imageBase,
                                });
                              navigation.navigate('NewsFeedScreen');
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: false,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={styles(theme).Buttonb69ef275}
                        title={'Submit Live Story'}
                      />
                    )}
                  </>
                  {/* Button Solid */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <Button
                        style={styles(theme).Buttonb69ef275}
                        title={''}
                        loading={true}
                        disabled={false}
                      />
                    )}
                  </>
                </View>
              </View>
            </KeyboardAwareScrollView>
          </KeyboardAvoidingView>
        )}
      </>
      {/* NotPaying */}
      <>
        {Constants['user_paying'] ? null : (
          <View>
            {/* PayWall */}
            <View style={styles(theme).View74763ff2}>
              <Text style={styles(theme).Textb17d301e}>
                {
                  'This feature is only available to Rising Coaches members. Join our membership program to take advantage of everything Rising Coaches has to offer.'
                }
              </Text>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://www.risingcoaches.com/membership'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button47b42b4a}
                title={'Subscribe'}
              />
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button47b42b4a: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      textAlign: 'center',
    },
    Buttonb69ef275: {
      backgroundColor: theme.colors.secondary,
      borderRadius: 64,
      fontFamily: 'System',
      fontWeight: '700',
      paddingBottom: 16,
      paddingTop: 16,
      textAlign: 'center',
    },
    Image636e6176: { height: 150, width: 150 },
    KeyboardAvoidingView6a955cc3: { justifyContent: 'center' },
    Texta9275998: {
      alignSelf: 'flex-start',
      color: theme.colors.strong,
      fontFamily: 'System',
      fontWeight: '600',
    },
    Textb17d301e: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    View4571e987: { paddingLeft: 40, paddingRight: 40 },
    View69c8f9d6: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      height: '100%',
      justifyContent: 'space-around',
    },
    View74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    View932040af: {
      marginBottom: 40,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 10,
    },
    Vieweb8a4c38: { alignItems: 'center', justifyContent: 'center' },
    screen: { justifyContent: 'center' },
  });

export default withTheme(CreateStoryScreen);
