import React from 'react';
import * as CustomCode from '../CustomCode';
import * as AutoTags from '../custom-files/AutoTags';
import * as Utils from '../utils';
import { ScreenContainer, withTheme } from '@draftbit/ui';

const StartNewChatScreen = props => {
  const { theme } = props;

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      <Utils.CustomCodeErrorBoundary>
        <AutoTags.MultiUserTags navigation={props.navigation} />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

export default withTheme(StartNewChatScreen);
