import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Utils from '../utils';
import {
  Button,
  CircleImage,
  DatePicker,
  IconButton,
  Link,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  KeyboardAvoidingView,
  Modal,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const OnboardingScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesUpdateUserPOST = RisingCoachesApi.useUpdateUserPOST();
  const risingCoachesDELETEACCOUNTDELETE =
    RisingCoachesApi.useDELETEACCOUNTDELETE();

  const [almaMaterInput, setAlmaMaterInput] = React.useState('');
  const [bioInput, setBioInput] = React.useState('');
  const [birthdayInput, setBirthdayInput] = React.useState('1991-11-28');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [fourActive, setFourActive] = React.useState(false);
  const [imageBase, setImageBase] = React.useState('');
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [linkedInInput, setLinkedInInput] = React.useState('');
  const [oneActive, setOneActive] = React.useState(true);
  const [positionInput, setPositionInput] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [threeActive, setThreeActive] = React.useState(false);
  const [twitterInput, setTwitterInput] = React.useState('');
  const [twoActive, setTwoActive] = React.useState(false);
  const [uniBioInput, setUniBioInput] = React.useState('');
  const [universityInput, setUniversityInput] = React.useState('');

  return (
    <ScreenContainer hasTopSafeArea={true}>
      <RisingCoachesApi.FetchGetUserDetailGET
        user_id={Constants['user_id']}
        onData={fetchData => {
          try {
            setUniversityInput(fetchData?.current_university);
            setAlmaMaterInput(fetchData?.alma_mater);
            setBioInput(fetchData?.bio);
            setPositionInput(fetchData?.job_title);
            setBirthdayInput(fetchData?.birthday);
            setLinkedInInput(fetchData?.linkedin_link);
            setTwitterInput(fetchData?.twitter_link);
            setUniBioInput(fetchData?.university_bio_link);
            setImageBase(fetchData?.profile_image?.url);
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ loading, error, data, refetchGetUserDetail }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <KeyboardAwareScrollView
              style={styles(theme).KeyboardAwareScrollView989db244}
              contentContainerStyle={
                styles(theme).KeyboardAwareScrollView5cf968baContent
              }
            >
              <KeyboardAvoidingView enabled={true} behavior={'position'}>
                {/* Stepper */}
                <View style={styles(theme).Viewdbe83d8f}>
                  {/* one-inactive */}
                  <>
                    {oneActive ? null : (
                      <IconButton
                        onPress={() => {
                          try {
                            setOneActive('ture');
                            setTwoActive(false);
                            setThreeActive(false);
                            setFourActive(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                        icon={'MaterialCommunityIcons/numeric-1-circle-outline'}
                      />
                    )}
                  </>
                  {/* one-active */}
                  <>
                    {!oneActive ? null : (
                      <IconButton
                        size={32}
                        icon={'MaterialCommunityIcons/numeric-1-circle'}
                      />
                    )}
                  </>
                  {/* two-inactive */}
                  <>
                    {twoActive ? null : (
                      <IconButton
                        onPress={() => {
                          try {
                            setOneActive(false);
                            setTwoActive(true);
                            setThreeActive(false);
                            setFourActive(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                        icon={'MaterialCommunityIcons/numeric-2-circle-outline'}
                      />
                    )}
                  </>
                  {/* two-active */}
                  <>
                    {!twoActive ? null : (
                      <IconButton
                        size={32}
                        icon={'MaterialCommunityIcons/numeric-2-circle'}
                      />
                    )}
                  </>
                  {/* three-inactive */}
                  <>
                    {threeActive ? null : (
                      <IconButton
                        onPress={() => {
                          try {
                            setOneActive(false);
                            setTwoActive(false);
                            setThreeActive(true);
                            setFourActive(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        icon={'MaterialCommunityIcons/numeric-3-circle-outline'}
                        size={32}
                      />
                    )}
                  </>
                  {/* three-active */}
                  <>
                    {!threeActive ? null : (
                      <IconButton
                        size={32}
                        icon={'MaterialCommunityIcons/numeric-3-circle'}
                      />
                    )}
                  </>
                  {/* four-inactive */}
                  <>
                    {fourActive ? null : (
                      <IconButton
                        onPress={() => {
                          try {
                            setOneActive(false);
                            setTwoActive(false);
                            setThreeActive(false);
                            setFourActive(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                        icon={'MaterialCommunityIcons/numeric-4-circle-outline'}
                      />
                    )}
                  </>
                  {/* four-active */}
                  <>
                    {!fourActive ? null : (
                      <IconButton
                        size={32}
                        icon={'MaterialCommunityIcons/numeric-4-circle'}
                      />
                    )}
                  </>
                </View>
                {/* Step One */}
                <>
                  {!oneActive ? null : (
                    <View style={styles(theme).View9110ad46}>
                      {/* Error Message */}
                      <Text style={styles(theme).Text23ad6e29}>
                        {Constants['error_message']}
                      </Text>
                      {/* Current Input */}
                      <TextInput
                        onChangeText={newCurrentInputValue => {
                          try {
                            setUniversityInput(newCurrentInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput20912c2c}
                        value={universityInput}
                        placeholder={'Current University'}
                        autoCapitalize={'words'}
                        autoFocus={true}
                        placeholderTextColor={theme.colors.light}
                      />
                      <Spacer top={12} right={8} bottom={12} left={8} />
                      {/* Title Input */}
                      <TextInput
                        onChangeText={newTitleInputValue => {
                          try {
                            setPositionInput(newTitleInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput20912c2c}
                        value={positionInput}
                        placeholder={'Position Title'}
                        autoCapitalize={'none'}
                        keyboardType={'email-address'}
                        textContentType={'emailAddress'}
                        placeholderTextColor={theme.colors.light}
                      />
                      <Spacer top={12} right={8} bottom={8} left={8} />
                      {/* Alma Mater Input */}
                      <TextInput
                        onChangeText={newAlmaMaterInputValue => {
                          try {
                            setAlmaMaterInput(newAlmaMaterInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput20912c2c}
                        value={almaMaterInput}
                        placeholder={'Alma Mater'}
                        autoCapitalize={'none'}
                        textContentType={'password'}
                        secureTextEntry={false}
                        placeholderTextColor={theme.colors.light}
                      />
                      <Spacer right={8} left={8} top={12} bottom={8} />
                      <DatePicker
                        onDateChange={newDatePickerValue => {
                          try {
                            setBirthdayInput(newDatePickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        label={'Birthday'}
                        date={birthdayInput}
                        leftIconMode={'inset'}
                        type={'underline'}
                        mode={'date'}
                      />
                      <Spacer right={8} left={8} top={12} bottom={8} />
                      {/* Next Button */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setOneActive(false);
                                setTwoActive(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={styles(theme).Button413ac881}
                            title={'Next\n'}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      {/* Next Button Loading */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            style={styles(theme).Button413ac881}
                            title={''}
                            disabled={true}
                            loading={true}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      <Spacer top={16} right={8} bottom={16} left={8} />
                      {/* SkipView */}
                      <View style={styles(theme).View863e7c01}>
                        {/* Sign In Link */}
                        <Link
                          onPress={() => {
                            try {
                              setOneActive(false);
                              setTwoActive(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={styles(theme).Linkd3707c9f}
                          title={'Skip this for now'}
                        />
                      </View>
                      {/* Delete Account */}
                      <View style={styles(theme).View98ac1366}>
                        {/* Sign In Link */}
                        <Link
                          onPress={() => {
                            try {
                              setIsDeleteModal(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={styles(theme).Link0500c24c}
                          title={'Delete My Account'}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Step Two */}
                <>
                  {!twoActive ? null : (
                    <View style={styles(theme).View9110ad46}>
                      {/* Error Message */}
                      <Text style={styles(theme).Text23ad6e29}>
                        {Constants['error_message']}
                      </Text>

                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const base64_image = await Utils.openImagePicker({
                                mediaTypes: 'Images',
                              });
                              setImageBase(base64_image);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <View style={styles(theme).View39912261}>
                          <Text style={styles(theme).Text0dc2e7e0}>
                            {'Click to Upload a Profile Picture'}
                          </Text>
                          <Spacer right={8} left={8} top={4} bottom={4} />
                          <>
                            {!imageBase ? null : (
                              <Image
                                style={styles(theme).Image30a8eef2}
                                source={{ uri: `${imageBase}` }}
                                resizeMode={'cover'}
                              />
                            )}
                          </>
                          <>
                            {imageBase ? null : (
                              <CircleImage
                                source={Images.UploadIcon}
                                size={180}
                              />
                            )}
                          </>
                        </View>
                      </Touchable>
                      <Spacer top={12} right={8} bottom={8} left={8} />
                      {/* Next Button */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setTwoActive(false);
                                setThreeActive(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={styles(theme).Button413ac881}
                            title={'Next\n'}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      {/* Next Button Loading */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            style={styles(theme).Button413ac881}
                            title={''}
                            disabled={true}
                            loading={true}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      <Spacer right={8} left={8} bottom={16} top={16} />
                      {/* SkipView */}
                      <View style={styles(theme).View863e7c01}>
                        {/* Sign In Link */}
                        <Link
                          onPress={() => {
                            try {
                              setTwoActive(false);
                              setThreeActive(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={styles(theme).Linkd3707c9f}
                          title={'Skip this for now'}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Step Three */}
                <>
                  {!threeActive ? null : (
                    <View style={styles(theme).View9110ad46}>
                      {/* Error Message */}
                      <Text style={styles(theme).Text23ad6e29}>
                        {Constants['error_message']}
                      </Text>

                      <Text style={styles(theme).Text0dc2e7e0}>
                        {'Biography'}
                      </Text>
                      <Spacer right={8} left={8} top={4} bottom={4} />
                      {/* Bio Text Area */}
                      <TextInput
                        onChangeText={newBioTextAreaValue => {
                          try {
                            setBioInput(newBioTextAreaValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput63f9c6dd}
                        placeholder={'Write your bio'}
                        value={bioInput}
                        multiline={true}
                        placeholderTextColor={theme.colors.light}
                      />
                      <Spacer top={12} right={8} bottom={12} left={8} />
                      {/* Next Button */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setThreeActive(false);
                                setFourActive(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={styles(theme).Button413ac881}
                            title={'Next\n'}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      {/* Next Button Loading */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            style={styles(theme).Button413ac881}
                            title={''}
                            disabled={true}
                            loading={true}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      <Spacer top={16} right={8} bottom={16} left={8} />
                      {/* SkipView */}
                      <View style={styles(theme).View863e7c01}>
                        {/* Sign In Link */}
                        <Link
                          onPress={() => {
                            try {
                              setThreeActive(false);
                              setFourActive(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={styles(theme).Linkd3707c9f}
                          title={'Skip this for now'}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Step Four */}
                <>
                  {!fourActive ? null : (
                    <View style={styles(theme).View9110ad46}>
                      {/* Error Message */}
                      <Text style={styles(theme).Text23ad6e29}>
                        {Constants['error_message']}
                      </Text>

                      <Text style={styles(theme).Text0dc2e7e0}>
                        {'Social Media Links'}
                      </Text>
                      <Spacer right={8} left={8} top={4} bottom={4} />
                      {/* Linkedin */}
                      <TextInput
                        onChangeText={newLinkedinValue => {
                          try {
                            setLinkedInInput(newLinkedinValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput20912c2c}
                        value={linkedInInput}
                        placeholder={'LinkedIn URL'}
                        autoCapitalize={'words'}
                        placeholderTextColor={theme.colors.light}
                      />
                      <Spacer top={12} right={8} bottom={12} left={8} />
                      {/* Twitter Input */}
                      <TextInput
                        onChangeText={newTwitterInputValue => {
                          try {
                            setTwitterInput(newTwitterInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput20912c2c}
                        value={twitterInput}
                        placeholder={'Twitter URL'}
                        autoCapitalize={'none'}
                        placeholderTextColor={theme.colors.light}
                      />
                      <Spacer top={12} right={8} bottom={8} left={8} />
                      {/* Uni Bio */}
                      <TextInput
                        onChangeText={newUniBioValue => {
                          try {
                            setUniBioInput(newUniBioValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput20912c2c}
                        value={uniBioInput}
                        placeholder={'University Bio Link'}
                        secureTextEntry={false}
                        autoCapitalize={'none'}
                        textContentType={'password'}
                        keyboardAppearance={'default'}
                        keyboardType={'default'}
                        placeholderTextColor={theme.colors.light}
                      />
                      <Spacer top={12} right={8} bottom={8} left={8} />
                      {/* Next Button */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: true,
                                  });
                                  const response =
                                    await risingCoachesUpdateUserPOST.mutateAsync(
                                      {
                                        alma_mater: almaMaterInput,
                                        bio: bioInput,
                                        birthday: birthdayInput,
                                        current_university: universityInput,
                                        email: fetchData?.email,
                                        handle: fetchData?.handle,
                                        isOnboarded: true,
                                        job_title: positionInput,
                                        linkedin: linkedInInput,
                                        name: fetchData?.name,
                                        photo_file: imageBase,
                                        twitter: twitterInput,
                                        university_bio_link: uniBioInput,
                                        user_id: Constants['user_id'],
                                      }
                                    );
                                  const avatar = response.profile_image;
                                  setGlobalVariableValue({
                                    key: 'user_image',
                                    value: avatar?.url,
                                  });
                                  navigation.navigate('NewsFeedScreen');
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: false,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={styles(theme).Button413ac881}
                            title={'Finish'}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      {/* Next Button Loading */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            style={styles(theme).Button413ac881}
                            title={''}
                            disabled={true}
                            loading={true}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      <Spacer top={16} right={8} bottom={16} left={8} />
                    </View>
                  )}
                </>
              </KeyboardAvoidingView>
            </KeyboardAwareScrollView>
          );
        }}
      </RisingCoachesApi.FetchGetUserDetailGET>
      <>
        {!true ? null : (
          <Modal
            visible={isDeleteModal}
            animationType={'slide'}
            presentationStyle={'fullScreen'}
          >
            <View style={styles(theme).Viewbf78ff24}>
              <View style={styles(theme).View5942f196}>
                <Text style={styles(theme).Textf3762b55}>
                  {
                    'ARE YOU SURE YOU WANT TO DELETE YOUR ACCOUNT? THIS ACTION IS PERMANENT AND CANNOT BE REVERSED. '
                  }
                </Text>
              </View>

              <View style={styles(theme).Viewc0755b9c}>
                <Button
                  onPress={() => {
                    try {
                      setIsDeleteModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={styles(theme).Button8ce897e6}
                  title={'CANCEL'}
                />
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        navigation.navigate('WelcomeScreen');
                        setGlobalVariableValue({
                          key: 'auth_header',
                          value: '',
                        });
                        await risingCoachesDELETEACCOUNTDELETE.mutateAsync({
                          user_id: Constants['user_id'],
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={styles(theme).Buttonbd8fdfae}
                  title={'DELETE ACCOUNT'}
                />
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button413ac881: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      textAlign: 'center',
    },
    Button8ce897e6: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Buttonbd8fdfae: {
      backgroundColor: theme.colors['Error'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Fetch431eb058: { minHeight: 40 },
    Image30a8eef2: { height: 250, width: 250 },
    KeyboardAwareScrollView5cf968baContent: {
      flex: 1,
      justifyContent: 'center',
    },
    Link0500c24c: { color: theme.colors['Error'] },
    Linkd3707c9f: { color: theme.colors.primary },
    Text0dc2e7e0: {
      color: theme.colors.strong,
      fontFamily: 'Mulish_400Regular',
    },
    Text23ad6e29: {
      color: theme.colors.error,
      fontSize: 12,
      marginBottom: 16,
      textAlign: 'center',
    },
    TextInput20912c2c: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
    },
    TextInput63f9c6dd: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      minHeight: 300,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
    },
    Textf3762b55: {
      color: theme.colors['Error'],
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    View39912261: { alignItems: 'center' },
    View5942f196: { marginLeft: 20, marginRight: 20 },
    View863e7c01: { flexDirection: 'row', justifyContent: 'center' },
    View9110ad46: { marginTop: 24, paddingLeft: 36, paddingRight: 36 },
    View98ac1366: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 50,
    },
    Viewbf78ff24: { alignItems: 'center', flex: 1, justifyContent: 'center' },
    Viewc0755b9c: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: 30,
      width: '100%',
    },
    Viewdbe83d8f: { flexDirection: 'row', justifyContent: 'space-around' },
  });

export default withTheme(OnboardingScreen);
