import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { Button, ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, Text, View } from 'react-native';

const ChatVideoMeetingScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;

  return (
    <ScreenContainer
      style={styles(theme).screen}
      hasSafeArea={true}
      scrollable={false}
    >
      <>
        {!Constants['user_paying'] ? null : (
          <WebView
            style={styles(theme).WebViewc992f941}
            optimizeVideoChat={true}
            source={{
              uri: `${
                props.route?.params?.nav_roomUrl ??
                'https://rising-coaches.whereby.com/d7a494b9-90d6-4286-8cbe-cd15d784fa24'
              }`,
            }}
          />
        )}
      </>
      {/* NotPaying */}
      <>
        {Constants['user_paying'] ? null : (
          <View>
            {/* PayWall */}
            <View style={styles(theme).View74763ff2}>
              <Text style={styles(theme).Textb17d301e}>
                {
                  'This feature is only available to Rising Coaches members. Join our membership program to take advantage of everything Rising Coaches has to offer.'
                }
              </Text>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://www.risingcoaches.com/membership'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button47b42b4a}
                title={'Subscribe'}
              />
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button47b42b4a: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      textAlign: 'center',
    },
    Textb17d301e: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    View74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    WebViewc992f941: { flex: 1 },
    screen: { backgroundColor: theme.colors['Background'] },
  });

export default withTheme(ChatVideoMeetingScreen);
