// Place any imports required by your custom components at the top of
// this file. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section. The first import from
// 'react' is required.
// KSL Added 'Button' for Global Variables
import {
  Text,
  View,
  TouchableOpacity,
  Alert,
  ActivityIndicator,
} from 'react-native';
import React, { useState, useEffect, useCallback } from 'react';
import * as GlobalVariableContext from '../config/GlobalVariableContext';
import { Actions, GiftedChat } from 'react-native-gifted-chat-expo';
import moment from 'moment';
import { Icon } from '@draftbit/ui';
import { configureAbly, useChannel } from '@ably-labs/react-hooks';
export { moment };

export const ChatComponent = props => {
  const { navigation } = props;
  const variables = GlobalVariableContext.useValues();

  configureAbly({
    key: 'YtqgTA.WDAdsg:lEzZt7we5sZhXonrI9LDkUnFcX3vQDfwASFr_p60-Q0',
  });

  const [messages, setMessages] = useState(variables.return_chat);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + variables.auth_header,
        'Content-Type': 'application/json',
      }),
    };
    fetch(
      'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chat/chatroom/' +
        props.chat_id,
      requestOptions
    ).then(response =>
      response.json().then(data => setMessages(data), setIsLoading(false))
    );
  }, []);

  const [channel] = useChannel(props.chat_id, 'naufel', message => {
    setMessages(previousMessages =>
      GiftedChat.append(previousMessages, message.data)
    );
  });

  const startVideoChat = () => {
    Alert.alert(
      'Start Video Chat',
      'Are you sure you want to begin a live video call?',
      [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'Yes',
          onPress: () => {
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                Authorization: 'Bearer ' + variables.auth_header,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                chatroom_id: props.chat_id,
              }),
            };
            fetch(
              'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/video/chat',
              requestOptions
            ).then(response => {
              response.json().then(data => {
                try {
                  navigation.navigate('ChatVideoMeetingScreen', {
                    nav_roomUrl: data.message,
                  });
                } catch (err) {
                  console.error(err);
                }
              });
            });
          },
        },
      ]
    );
  };

  const renderActions = () => {
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Actions
          {...props}
          onPressActionButton={startVideoChat}
          icon={() => (
            <Icon
              color="grey"
              type="material"
              name="videocam"
              size={28}
              onPress={startVideoChat}
            />
          )}
          onSend={args => console.log(args)}
        />
      </View>
    );
  };

  const onSend = useCallback((messages = []) => {
    channel.publish('naufel', messages);

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + variables.auth_header,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        message: messages[0].text,
        chatroom_id: props.chat_id,
      }),
    };
    fetch(
      'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chat',
      requestOptions
    ).then(response => resonse.json());
  }, []);

  const renderMessageVideo = p => {
    return (
      <View style={{ position: 'relative' }}>
        <TouchableOpacity
          onPress={() => {
            try {
              navigation.navigate('ChatVideoMeetingScreen', {
                nav_roomUrl: p.currentMessage.video,
              });
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <Text
            style={{
              padding: 10,
              color: '#0000EE',
              fontSize: 16,
            }}
          >
            {'Video Call has started. Click here to join.'}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <>
      {isLoading ? (
        <ActivityIndicator
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          size="large"
          color="#F16834"
        />
      ) : (
        <GiftedChat
          messages={messages}
          renderActions={renderActions}
          renderMessageVideo={renderMessageVideo}
          onSend={messages => onSend(messages)}
          user={{
            _id: variables.user_id,
          }}
        />
      )}
    </>
  );
};
