import React from 'react';
import * as MagicLinkApi from '../apis/MagicLinkApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { StyleSheet, Text, TextInput, View } from 'react-native';

const PasswordRecoveryScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View style={styles(theme).View04a43099}>
        <Text style={styles(theme).Textb94cc396}>
          {Constants['error_message']}
        </Text>
        <TextInput
          onChangeText={newTextInputValue => {
            try {
              setTextInputValue(newTextInputValue);
            } catch (err) {
              console.error(err);
            }
          }}
          style={styles(theme).TextInput5b3e5826}
          value={textInputValue}
          placeholder={'Enter email here'}
          editable={true}
        />
        <Button
          onPress={() => {
            const handler = async () => {
              try {
                const response = await MagicLinkApi.resetPasswordGET(
                  Constants,
                  { email: textInputValue }
                );
                const message = response.message;
                setGlobalVariableValue({
                  key: 'error_message',
                  value: message,
                });
                if (message) {
                  return;
                }
                setGlobalVariableValue({
                  key: 'auth_header',
                  value: '',
                });
                navigation.navigate('WelcomeScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={styles(theme).Buttoned8fc43c}
          title={'Reset Password'}
        />
      </View>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Buttoned8fc43c: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginTop: 10,
      textAlign: 'center',
    },
    TextInput5b3e5826: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      fontFamily: 'Manrope_400Regular',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Textb94cc396: {
      alignSelf: 'center',
      color: theme.colors['Error'],
      fontFamily: 'Manrope_400Regular',
      marginBottom: 10,
      textAlign: 'center',
    },
    View04a43099: {
      alignSelf: 'stretch',
      flex: 1,
      justifyContent: 'center',
      marginLeft: 30,
      marginRight: 30,
    },
  });

export default withTheme(PasswordRecoveryScreen);
