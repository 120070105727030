import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const addJobToTimelinePOST = (
  Constants,
  { end_date, isCurrentJob, job_description, job_title, organization, user_id }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/job_timeline`, {
    body: JSON.stringify({
      job_title: job_title,
      organization: organization,
      user_id: user_id,
      end_date: end_date,
      isCurrentJob: isCurrentJob,
      job_description: job_description,
      start_date: null,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useAddJobToTimelinePOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => addJobToTimelinePOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchAddJobToTimelinePOST = ({
  children,
  onData = () => {},
  refetchInterval,
  end_date,
  isCurrentJob,
  job_description,
  job_title,
  organization,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useAddJobToTimelinePOST(
    {
      end_date,
      isCurrentJob,
      job_description,
      job_title,
      organization,
      user_id,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchAddJobToTimeline: refetch });
};

export const addLikePOST = (Constants, { feed_post_id }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/likes`, {
    body: JSON.stringify({ feed_post_id: feed_post_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useAddLikePOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => addLikePOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Likes', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Like');
        queryClient.invalidateQueries('Likes');
      },
    }
  );
};

export const FetchAddLikePOST = ({
  children,
  onData = () => {},
  refetchInterval,
  feed_post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useAddLikePOST({ feed_post_id }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchAddLike: refetch });
};

export const applyPOST = (Constants, { opportunity_id }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/applicants`, {
    body: JSON.stringify({ opportunities_id: opportunity_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useApplyPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => applyPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Opportunities', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Opportunity');
        queryClient.invalidateQueries('Opportunities');
      },
    }
  );
};

export const FetchApplyPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  opportunity_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useApplyPOST({ opportunity_id }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchApply: refetch });
};

export const checkAuthGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/auth/me`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCheckAuthGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/auth/me`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  });
};

export const FetchCheckAuthGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/auth/me`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCheckAuth: refetch });
};

export const checkLiveEventGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/live_event`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCheckLiveEventGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Live Event', args],
    () => checkLiveEventGET(Constants, args),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Live Events']),
    }
  );
};

export const FetchCheckLiveEventGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useCheckLiveEventGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCheckLiveEvent: refetch });
};

export const checkPayingGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/paying/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCheckPayingGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['User', args], () => checkPayingGET(Constants, args), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['Users']),
  });
};

export const FetchCheckPayingGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useCheckPayingGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCheckPaying: refetch });
};

export const createAccountPOST = (
  Constants,
  { email, handle, name, password, profile_image }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/auth/signup`, {
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      handle: handle,
      profile_image: profile_image,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateAccountPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createAccountPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchCreateAccountPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
  handle,
  name,
  password,
  profile_image,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAccountPOST(
    { email, handle, name, password, profile_image },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateAccount: refetch });
};

export const createAdminContentPOST = (
  Constants,
  {
    content_title,
    content_type,
    content_uploader_id,
    file_content,
    folder_id,
    image_content,
    isFile,
    isImage,
    isShared,
    isVideo,
    tags,
    text_content,
    youtubeID,
  }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/admin/folder_content`, {
    body: JSON.stringify({
      folders_id: folder_id,
      content_uploader: content_uploader_id,
      content_title: content_title,
      tags: tags,
      content_type: content_type,
      text_content: text_content,
      content: image_content,
      isShared: isShared,
      youtubeId: youtubeID,
      isVideo: isVideo,
      isFile: isFile,
      isImage: isImage,
      file_content: file_content,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateAdminContentPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createAdminContentPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateAdminContentPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  content_title,
  content_type,
  content_uploader_id,
  file_content,
  folder_id,
  image_content,
  isFile,
  isImage,
  isShared,
  isVideo,
  tags,
  text_content,
  youtubeID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAdminContentPOST(
    {
      content_title,
      content_type,
      content_uploader_id,
      file_content,
      folder_id,
      image_content,
      isFile,
      isImage,
      isShared,
      isVideo,
      tags,
      text_content,
      youtubeID,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateAdminContent: refetch });
};

export const createAdminFolderPOST = (Constants, { name }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/admin/folders`, {
    body: JSON.stringify({ name: name }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateAdminFolderPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createAdminFolderPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateAdminFolderPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAdminFolderPOST({ name }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateAdminFolder: refetch });
};

export const createCalendarItemPOST = (
  Constants,
  {
    creator_id,
    description,
    duration_minutes,
    event_date,
    event_day,
    event_location,
    event_name,
    geometry,
  }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/calendar`, {
    body: JSON.stringify({
      event_name: event_name,
      height: 100,
      day: event_day,
      datetime: event_date,
      creator_id: creator_id,
      location: event_location,
      start_time: null,
      duration_minutes: duration_minutes,
      description: description,
      geo: geometry,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateCalendarItemPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createCalendarItemPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Calendar', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Calendar');
        queryClient.invalidateQueries('Calendars');
      },
    }
  );
};

export const FetchCreateCalendarItemPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  creator_id,
  description,
  duration_minutes,
  event_date,
  event_day,
  event_location,
  event_name,
  geometry,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCalendarItemPOST(
    {
      creator_id,
      description,
      duration_minutes,
      event_date,
      event_day,
      event_location,
      event_name,
      geometry,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateCalendarItem: refetch });
};

export const createCommentPOST = (
  Constants,
  { author_user_id, comment_text, feed_post_id }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/comment`, {
    body: JSON.stringify({
      comment_text: comment_text,
      author_user_id: author_user_id,
      feed_post_id: feed_post_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateCommentPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createCommentPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Comment', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Comment');
        queryClient.invalidateQueries('Comments');
      },
    }
  );
};

export const FetchCreateCommentPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  author_user_id,
  comment_text,
  feed_post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCommentPOST(
    { author_user_id, comment_text, feed_post_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateComment: refetch });
};

export const createContentPOST = (
  Constants,
  {
    content_title,
    content_type,
    content_uploader_id,
    file_content,
    folder_id,
    image_content,
    isFile,
    isImage,
    isShared,
    isVideo,
    tags,
    text_content,
    youtubeID,
  }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folder_content`, {
    body: JSON.stringify({
      folders_id: folder_id,
      content_uploader: content_uploader_id,
      content_title: content_title,
      tags: tags,
      content_type: content_type,
      text_content: text_content,
      content: image_content,
      isShared: isShared,
      youtubeId: youtubeID,
      isVideo: isVideo,
      isFile: isFile,
      isImage: isImage,
      file_content: file_content,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateContentPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createContentPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateContentPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  content_title,
  content_type,
  content_uploader_id,
  file_content,
  folder_id,
  image_content,
  isFile,
  isImage,
  isShared,
  isVideo,
  tags,
  text_content,
  youtubeID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateContentPOST(
    {
      content_title,
      content_type,
      content_uploader_id,
      file_content,
      folder_id,
      image_content,
      isFile,
      isImage,
      isShared,
      isVideo,
      tags,
      text_content,
      youtubeID,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateContent: refetch });
};

export const createFeedPostPOST = (
  Constants,
  {
    content_title,
    content_type,
    file_content,
    image_content,
    isFile,
    isImage,
    isText,
    isVideo,
    tags,
    text_content,
    youtubeID,
  }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/news_feed_content`, {
    body: JSON.stringify({
      content_title: content_title,
      tags: tags,
      content_type: content_type,
      text_content: text_content,
      content: image_content,
      youtubeId: youtubeID,
      isVideo: isVideo,
      isFile: isFile,
      isImage: isImage,
      isText: isText,
      file_content: file_content,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateFeedPostPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createFeedPostPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Feed', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Feed');
        queryClient.invalidateQueries('Feeds');
      },
    }
  );
};

export const FetchCreateFeedPostPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  content_title,
  content_type,
  file_content,
  image_content,
  isFile,
  isImage,
  isText,
  isVideo,
  tags,
  text_content,
  youtubeID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateFeedPostPOST(
    {
      content_title,
      content_type,
      file_content,
      image_content,
      isFile,
      isImage,
      isText,
      isVideo,
      tags,
      text_content,
      youtubeID,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateFeedPost: refetch });
};

export const createFolderPOST = (Constants, { folder_name }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folders`, {
    body: JSON.stringify({ name: folder_name }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateFolderPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createFolderPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateFolderPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  folder_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateFolderPOST({ folder_name }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateFolder: refetch });
};

export const createRSVPPOST = (Constants, { event_id }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/rsvps`, {
    body: JSON.stringify({ event_id: event_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateRSVPPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createRSVPPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Calendar', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Calendar');
        queryClient.invalidateQueries('Calendars');
      },
    }
  );
};

export const FetchCreateRSVPPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  event_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateRSVPPOST({ event_id }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateRSVP: refetch });
};

export const createStoryPOST = (Constants, { content }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/live_stories`, {
    body: JSON.stringify({ content: content }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useCreateStoryPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => createStoryPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Live Stories', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Live Story');
        queryClient.invalidateQueries('Live Stories');
      },
    }
  );
};

export const FetchCreateStoryPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  content,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useCreateStoryPOST({ content }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchCreateStory: refetch });
};

export const dELETEACCOUNTDELETE = (Constants, { user_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user/${user_id ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDELETEACCOUNTDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => dELETEACCOUNTDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const deleteAccountDELETE = (Constants, { user_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user/${user_id ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteAccountDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteAccountDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const deleteAdminFolderDELETE = (Constants, { folders_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/admin/folders/${
      folders_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteAdminFolderDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteAdminFolderDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const deleteContentItemDELETE = (Constants, { folder_content_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folder_content/${
      folder_content_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteContentItemDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteContentItemDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const deleteFeedPostDELETE = (Constants, { news_feed_content_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/news_feed_content/${
      news_feed_content_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteFeedPostDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteFeedPostDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Feed', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Feed');
        queryClient.invalidateQueries('Feeds');
      },
    }
  );
};

export const deleteFolderDELETE = (Constants, { folders_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folders/${
      folders_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteFolderDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteFolderDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const deleteJobDELETE = (Constants, { job_timeline_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/job_timeline/${
      job_timeline_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteJobDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteJobDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const deleteNotificationDELETE = (Constants, { notifications_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/notifications/${
      notifications_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteNotificationDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteNotificationDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Notifications', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Notification');
        queryClient.invalidateQueries('Notifications');
      },
    }
  );
};

export const deleteRSVPDELETE = (Constants, { rsvp_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/rsvps/${rsvp_id ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useDeleteRSVPDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => deleteRSVPDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Calendar', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Calendar');
        queryClient.invalidateQueries('Calendars');
      },
    }
  );
};

export const followUserPOST = (Constants, { followed_id }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/follows`, {
    body: JSON.stringify({ followed_id: followed_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useFollowUserPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => followUserPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Follows', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Follow');
        queryClient.invalidateQueries('Follows');
      },
    }
  );
};

export const FetchFollowUserPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  followed_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useFollowUserPOST({ followed_id }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchFollowUser: refetch });
};

export const getAdminContentGET = (Constants, { folder_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/admin/folder_content/folder/${
      folder_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetAdminContentGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getAdminContentGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAdminContentGET = ({
  children,
  onData = () => {},
  refetchInterval,
  folder_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetAdminContentGET(
    { folder_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetAdminContent: refetch });
};

export const getAdminContentDetailsGET = (Constants, { folder_content_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/admin/folder_content/${
      folder_content_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetAdminContentDetailsGET = (
  args,
  { refetchInterval } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Respository', args],
    () => getAdminContentDetailsGET(Constants, args),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Respositories']),
    }
  );
};

export const FetchGetAdminContentDetailsGET = ({
  children,
  onData = () => {},
  refetchInterval,
  folder_content_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetAdminContentDetailsGET(
    { folder_content_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetAdminContentDetails: refetch,
  });
};

export const getAdminFoldersGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/admin/folders`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetAdminFoldersGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getAdminFoldersGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAdminFoldersGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetAdminFoldersGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetAdminFolders: refetch });
};

export const getAllUsersGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetAllUsersGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Users', args], () => getAllUsersGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchGetAllUsersGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetAllUsersGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetAllUsers: refetch });
};

export const getBirthdaysGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/birthdays/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetBirthdaysGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Users', args], () => getBirthdaysGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchGetBirthdaysGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetBirthdaysGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetBirthdays: refetch });
};

export const getCalendarGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/calendar`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetCalendarGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Calendars', args], () => getCalendarGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchGetCalendarGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetCalendarGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetCalendar: refetch });
};

export const getContentDetailsGET = (Constants, { folder_content_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folder_content/${
      folder_content_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetContentDetailsGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Respository', args],
    () => getContentDetailsGET(Constants, args),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Respositories']),
    }
  );
};

export const FetchGetContentDetailsGET = ({
  children,
  onData = () => {},
  refetchInterval,
  folder_content_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetContentDetailsGET(
    { folder_content_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetContentDetails: refetch });
};

export const getEventDetailGET = (Constants, { calendar_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/calendar/${
      calendar_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetEventDetailGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Calendar', args],
    () => getEventDetailGET(Constants, args),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Calendars']),
    }
  );
};

export const FetchGetEventDetailGET = ({
  children,
  onData = () => {},
  refetchInterval,
  calendar_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetEventDetailGET(
    { calendar_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetEventDetail: refetch });
};

export const getFeaturedVideosGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/featured_videos`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetFeaturedVideosGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Feeds', args],
    () => getFeaturedVideosGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFeaturedVideosGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetFeaturedVideosGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetFeaturedVideos: refetch });
};

export const getFeedPostGET = (Constants, { post_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/news_feed_content/${
      post_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetFeedPostGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['Feed', args], () => getFeedPostGET(Constants, args), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['Feeds']),
  });
};

export const FetchGetFeedPostGET = ({
  children,
  onData = () => {},
  refetchInterval,
  post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetFeedPostGET(
    { post_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetFeedPost: refetch });
};

export const getFeedPostsGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/news_feed_content`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetFeedPostsGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Feeds', args], () => getFeedPostsGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchGetFeedPostsGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetFeedPostsGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetFeedPosts: refetch });
};

export const getFeedWithFilterGET = (Constants, { category }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/filter/news_feed_content/${
      category ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetFeedWithFilterGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Feeds', args],
    () => getFeedWithFilterGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFeedWithFilterGET = ({
  children,
  onData = () => {},
  refetchInterval,
  category,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetFeedWithFilterGET(
    { category },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetFeedWithFilter: refetch });
};

export const getJobTimelineGET = (Constants, { user_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/job_timeline/${
      user_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetJobTimelineGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Profiles', args],
    () => getJobTimelineGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetJobTimelineGET = ({
  children,
  onData = () => {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetJobTimelineGET(
    { user_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetJobTimeline: refetch });
};

export const getNotificationsGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/notifications`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetNotificationsGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Notifications', args],
    () => getNotificationsGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetNotificationsGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetNotificationsGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetNotifications: refetch });
};

export const getOpportunitiesGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/opportunities`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetOpportunitiesGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Opportunities', args],
    () => getOpportunitiesGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetOpportunitiesGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetOpportunitiesGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetOpportunities: refetch });
};

export const getOpportunityDetailGET = (Constants, { opportunity_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/opportunities/${
      opportunity_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetOpportunityDetailGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Opportunity', args],
    () => getOpportunityDetailGET(Constants, args),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Opportunities']),
    }
  );
};

export const FetchGetOpportunityDetailGET = ({
  children,
  onData = () => {},
  refetchInterval,
  opportunity_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetOpportunityDetailGET(
    { opportunity_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetOpportunityDetail: refetch,
  });
};

export const getPostGET = (Constants, { post_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/feedposts/${post_id ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetPostGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['SinglePost', args], () => getPostGET(Constants, args), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['SinglePosts']),
  });
};

export const FetchGetPostGET = ({
  children,
  onData = () => {},
  refetchInterval,
  post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetPostGET(
    { post_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetPost: refetch });
};

export const getRecentUserPostsGET = (Constants, { user_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/recent/news_feed_content/${
      user_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetRecentUserPostsGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Feeds', args],
    () => getRecentUserPostsGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetRecentUserPostsGET = ({
  children,
  onData = () => {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetRecentUserPostsGET(
    { user_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetRecentUserPosts: refetch });
};

export const getScoresGET = (Constants, { leagues }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/livescores?leagues=${
      leagues ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetScoresGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Scoreboards', args], () => getScoresGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchGetScoresGET = ({
  children,
  onData = () => {},
  refetchInterval,
  leagues,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetScoresGET(
    { leagues },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetScores: refetch });
};

export const getSharedFoldersGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/shared/folders`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetSharedFoldersGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getSharedFoldersGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetSharedFoldersGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetSharedFoldersGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetSharedFolders: refetch });
};

export const getStoriesGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/live_stories`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetStoriesGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Live Stories', args],
    () => getStoriesGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetStoriesGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetStoriesGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetStories: refetch });
};

export const getUserDetailGET = (Constants, { user_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user/${user_id ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetUserDetailGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Users', args], () => getUserDetailGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchGetUserDetailGET = ({
  children,
  onData = () => {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetUserDetailGET(
    { user_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetUserDetail: refetch });
};

export const getUserFolderContentsGET = (Constants, { folder_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folder_content/folder/${
      folder_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetUserFolderContentsGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getUserFolderContentsGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserFolderContentsGET = ({
  children,
  onData = () => {},
  refetchInterval,
  folder_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetUserFolderContentsGET(
    { folder_id },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({
    loading,
    data,
    error,
    refetchGetUserFolderContents: refetch,
  });
};

export const getUserFoldersGET = Constants =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folders`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetUserFoldersGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getUserFoldersGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserFoldersGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useGetUserFoldersGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetUserFolders: refetch });
};

export const loginPOST = (Constants, { email, password }) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useLoginPOST = ({ email, password }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchLogin: refetch });
};

export const removeApplicationDELETE = (Constants, { applicant_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/applicants/${
      applicant_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useRemoveApplicationDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => removeApplicationDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Opportunities', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Opportunity');
        queryClient.invalidateQueries('Opportunities');
      },
    }
  );
};

export const removeLikeDELETE = (Constants, { post_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/likes/${post_id ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useRemoveLikeDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => removeLikeDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Likes', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Like');
        queryClient.invalidateQueries('Likes');
      },
    }
  );
};

export const saveFeedToFolderPOST = (
  Constants,
  {
    content,
    content_title,
    file_content,
    folder_id,
    isFile,
    isImage,
    isShared,
    isVideo,
    tags,
    text_content,
    uploader_id,
    youtubeId,
  }
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/clone/folder_content`, {
    body: JSON.stringify({
      folders_id: folder_id,
      content_title: content_title,
      tags: tags,
      text_content: text_content,
      isShared: isShared,
      youtubeId: youtubeId,
      isVideo: isVideo,
      isFile: isFile,
      isImage: isImage,
      content: content,
      file_content: file_content,
      user_id: uploader_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useSaveFeedToFolderPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => saveFeedToFolderPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Feed', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Feed');
        queryClient.invalidateQueries('Feeds');
      },
    }
  );
};

export const FetchSaveFeedToFolderPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  content,
  content_title,
  file_content,
  folder_id,
  isFile,
  isImage,
  isShared,
  isVideo,
  tags,
  text_content,
  uploader_id,
  youtubeId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useSaveFeedToFolderPOST(
    {
      content,
      content_title,
      file_content,
      folder_id,
      isFile,
      isImage,
      isShared,
      isVideo,
      tags,
      text_content,
      uploader_id,
      youtubeId,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchSaveFeedToFolder: refetch });
};

export const searchDirectoryGET = (Constants, { input }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/search/coaches_directory?search=${
      input ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useSearchDirectoryGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Directories', args],
    () => searchDirectoryGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchSearchDirectoryGET = ({
  children,
  onData = () => {},
  refetchInterval,
  input,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useSearchDirectoryGET(
    { input },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchSearchDirectory: refetch });
};

export const unfollowUserDELETE = (Constants, { followed_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/follows/${
      followed_id ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useUnfollowUserDELETE = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => unfollowUserDELETE(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Follows', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Follow');
        queryClient.invalidateQueries('Follows');
      },
    }
  );
};

export const updateProfilePhotoPOST = (
  Constants,
  {
    alma_mater,
    bio,
    content,
    current_uni,
    email,
    handle,
    job_title,
    name,
    user_id,
  }
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user/${user_id ?? ''}`,
    {
      body: JSON.stringify({
        user_id: user_id,
        handle: handle,
        name: name,
        email: email,
        job_title: job_title,
        current_university: current_uni,
        alma_mater: alma_mater,
        bio: bio,
        content: content,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useUpdateProfilePhotoPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => updateProfilePhotoPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchUpdateProfilePhotoPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  alma_mater,
  bio,
  content,
  current_uni,
  email,
  handle,
  job_title,
  name,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateProfilePhotoPOST(
    {
      alma_mater,
      bio,
      content,
      current_uni,
      email,
      handle,
      job_title,
      name,
      user_id,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchUpdateProfilePhoto: refetch });
};

export const updatePushTokenPOST = (Constants, { expo_push_token, user_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/push/user/${user_id ?? ''}`,
    {
      body: JSON.stringify({
        user_id: user_id,
        expo_push_token: expo_push_token,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useUpdatePushTokenPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => updatePushTokenPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchUpdatePushTokenPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  expo_push_token,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useUpdatePushTokenPOST({ expo_push_token, user_id }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchUpdatePushToken: refetch });
};

export const updateResumePOST = (Constants, { file_base_64, user_id }) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user/${
      user_id ?? ''
    }/resume`,
    {
      body: JSON.stringify({ resume_content: file_base_64 }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useUpdateResumePOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => updateResumePOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchUpdateResumePOST = ({
  children,
  onData = () => {},
  refetchInterval,
  file_base_64,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateResumePOST({ file_base_64, user_id }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchUpdateResume: refetch });
};

export const updateUserPOST = (
  Constants,
  {
    alma_mater,
    bio,
    birthday,
    current_university,
    email,
    handle,
    isOnboarded,
    job_title,
    linkedin,
    name,
    photo_file,
    twitter,
    university_bio_link,
    user_id,
  }
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user/${user_id ?? ''}`,
    {
      body: JSON.stringify({
        user_id: 17,
        handle: handle,
        name: name,
        email: email,
        job_title: job_title,
        current_university: current_university,
        alma_mater: alma_mater,
        bio: bio,
        content: photo_file,
        twitter_link: twitter,
        linkedin_link: linkedin,
        university_bio_link: university_bio_link,
        birthday: birthday,
        isOnboarded: isOnboarded,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useUpdateUserPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => updateUserPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchUpdateUserPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  alma_mater,
  bio,
  birthday,
  current_university,
  email,
  handle,
  isOnboarded,
  job_title,
  linkedin,
  name,
  photo_file,
  twitter,
  university_bio_link,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateUserPOST(
    {
      alma_mater,
      bio,
      birthday,
      current_university,
      email,
      handle,
      isOnboarded,
      job_title,
      linkedin,
      name,
      photo_file,
      twitter,
      university_bio_link,
      user_id,
    },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchUpdateUser: refetch });
};
