import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  CircleImage,
  Icon,
  ScreenContainer,
  Spacer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const EventDetailsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const formatEventDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // return CustomCode.moment(dateString).format("MMM Do");

    return CustomCode.moment(dateString).format('LLL');
  };

  const { theme } = props;

  const risingCoachesCreateRSVPPOST = RisingCoachesApi.useCreateRSVPPOST();
  const risingCoachesDeleteRSVPDELETE = RisingCoachesApi.useDeleteRSVPDELETE();

  const mapViewe8WCbSIjRef = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={false}
    >
      <RisingCoachesApi.FetchGetEventDetailGET
        method={'GET'}
        calendar_id={props.route?.params?.navigate_event_id ?? 6}
      >
        {({ loading, error, data, refetchGetEventDetail }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              <View style={styles(theme).View70b89981}>
                <MapView
                  style={styles(theme).MapViewc992f941}
                  latitude={fetchData?.geo?.data?.lat}
                  longitude={fetchData?.geo?.data?.lng}
                  zoom={16}
                  zoomEnabled={true}
                  rotateEnabled={true}
                  scrollEnabled={true}
                  loadingEnabled={true}
                  showsPointsOfInterest={true}
                  provider={'google'}
                  showsUserLocation={true}
                  apiKey={'AIzaSyBzktToWosjNgrrUawZnbslB9NSXSXCkwo'}
                  ref={mapViewe8WCbSIjRef}
                >
                  <MapMarker
                    longitude={fetchData?.geo?.data?.lng}
                    title={fetchData?.event_name}
                    latitude={fetchData?.geo?.data?.lat}
                    pinColor={theme.colors.primary}
                  />
                </MapView>

                <View style={styles(theme).View291ac3f1}>
                  <View style={styles(theme).Viewfa68522b}>
                    <View style={styles(theme).Viewc992f941}>
                      <Text style={styles(theme).Text8be633e3}>
                        {formatEventDate(fetchData?.datetime)}{' '}
                      </Text>
                    </View>
                    <>
                      {fetchData?.isRSVP ? null : (
                        <View>
                          {/* Button Outline */}
                          <>
                            {fetchData?.isRSVP ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await risingCoachesCreateRSVPPOST.mutateAsync(
                                        { event_id: fetchData?.id }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={styles(theme).Buttoneae56793}
                                title={'RSVP'}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                    <>
                      {!fetchData?.isRSVP ? null : (
                        <View>
                          {/* Button Outline */}
                          <>
                            {!fetchData?.isRSVP ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await risingCoachesDeleteRSVPDELETE.mutateAsync(
                                        {
                                          rsvp_id:
                                            fetchData?.rsvps_of_calendar_of_user
                                              ?.id,
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={styles(theme).Buttoneae56793}
                                title={'Attending'}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                </View>
              </View>

              <View>
                <View style={styles(theme).View291ac3f1}>
                  <Text style={styles(theme).Text00ef0353}>
                    {fetchData?.location}
                  </Text>

                  <Text
                    style={styles(theme).Text8cfc6364}
                    textBreakStrategy={'highQuality'}
                    ellipsizeMode={'tail'}
                    allowFontScaling={true}
                    numberOfLines={2}
                  >
                    {fetchData?.event_name}
                  </Text>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  <View>
                    <View style={styles(theme).Viewdebd3207}>
                      <View style={styles(theme).View11851b7e}>
                        <Icon
                          name={'Feather/users'}
                          size={24}
                          color={theme.colors.primary}
                        />
                        <Spacer right={2} left={2} />
                        <Text style={styles(theme).Text4e9b4f62}>
                          {fetchData?.rsvps_of_event?.length}
                          {' RSVPs'}
                        </Text>
                      </View>
                      <Spacer top={8} right={6} bottom={8} left={6} />
                      <View style={styles(theme).View11851b7e}>
                        <Icon
                          name={'AntDesign/clockcircle'}
                          size={20}
                          color={theme.colors.primary}
                        />
                        <Spacer right={2} left={2} />
                        <Text style={styles(theme).Text4e9b4f62}>
                          {fetchData?.duration_minutes}
                          {' minutes'}
                        </Text>
                      </View>
                      <Spacer top={8} right={6} bottom={8} left={6} />
                    </View>
                  </View>
                  <Spacer top={12} right={8} bottom={12} left={8} />
                  <Spacer top={12} right={8} bottom={12} left={8} />
                  <View>
                    <Text style={styles(theme).Text3f9840b9}>
                      {'Description'}
                    </Text>
                    <Spacer top={6} right={8} bottom={6} left={8} />
                    <Text style={styles(theme).Textfe0e0a4d}>
                      {fetchData?.description}
                    </Text>
                  </View>
                  <Spacer top={16} right={8} bottom={16} left={8} />
                  <View>
                    <Text style={styles(theme).Text3f9840b9}>
                      {'Attendees'}
                    </Text>
                    <Spacer top={6} right={8} bottom={6} left={8} />
                    <FlatList
                      data={fetchData?.rsvps_of_event}
                      listKey={'87RWhd7B'}
                      keyExtractor={listData =>
                        listData?.id ||
                        listData?.uuid ||
                        JSON.stringify(listData)
                      }
                      renderItem={({ item }) => {
                        const listData = item;
                        return (
                          <View style={styles(theme).View9ae2daf4}>
                            <CircleImage
                              style={styles(theme).CircleImage65bacc84}
                              size={60}
                              source={{
                                uri: `${listData?.rsvp_user?.profile_image?.url}`,
                              }}
                            />
                            <Text style={styles(theme).Text3ad66854}>
                              {listData?.rsvp_user?.name}
                            </Text>
                          </View>
                        );
                      }}
                      style={styles(theme).FlatList989db244}
                      contentContainerStyle={
                        styles(theme).FlatListc992f941Content
                      }
                      numColumns={1}
                    />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetEventDetailGET>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Buttoneae56793: {
      backgroundColor: 'transparent',
      borderBottomWidth: 2,
      borderColor: theme.colors.surface,
      borderLeftWidth: 2,
      borderRadius: 8,
      borderRightWidth: 2,
      borderTopWidth: 2,
      borderWidth: 1,
      color: theme.colors.surface,
      fontFamily: 'Poppins_700Bold',
      textAlign: 'center',
    },
    CircleImage65bacc84: { marginRight: 20 },
    FlatListc992f941Content: { flex: 1 },
    MapViewc992f941: { flex: 1 },
    Text00ef0353: {
      color: theme.colors.light,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
    },
    Text3ad66854: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_700Bold',
      textAlign: 'left',
    },
    Text3f9840b9: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 16,
    },
    Text4e9b4f62: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
      textAlign: 'center',
    },
    Text8be633e3: {
      color: theme.colors.surface,
      fontFamily: 'Poppins_500Medium',
      fontSize: 16,
    },
    Text8cfc6364: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 22,
    },
    Textfe0e0a4d: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 14,
      lineHeight: 26,
      textAlign: 'left',
    },
    View11851b7e: {
      alignItems: 'center',
      alignSelf: 'stretch',
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View291ac3f1: {
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    View70b89981: { height: 420, width: '100%' },
    View9ae2daf4: { alignItems: 'center', flexDirection: 'row', marginTop: 10 },
    Viewc992f941: { flex: 1 },
    Viewdebd3207: { flexDirection: 'row' },
    Viewfa68522b: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      flexDirection: 'row',
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 8,
      paddingTop: 6,
    },
  });

export default withTheme(EventDetailsScreen);
