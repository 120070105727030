import {
  Text,
  View,
  StyleSheet,
  Platform,
  TouchableHighlight,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import React, { useState, useEffect, useCallback } from 'react';
import * as GlobalVariableContext from '../config/GlobalVariableContext';
import moment from 'moment';
import AutoTags from 'react-native-tag-autocomplete-expo';
import { ButtonSolid } from '@draftbit/ui';

export { moment };

export const MultiUserTags = props => {
  const variables = GlobalVariableContext.useValues();
  const [tagsSelected, setTagsSelected] = useState([]);
  const [suggestions, setSuggestions] = useState();
  const [users, setUsers] = useState();

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + variables.auth_header,
        'Content-Type': 'application/json',
      }),
    };
    fetch(
      'https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user',
      requestOptions
    ).then(response =>
      response.json().then(data => setSuggestions(data.items))
    );
  }, []);

  const customFilterData = query => {
    //override suggestion filter, we can search by specific attributes
    query = query.toUpperCase();
    let searchResults = suggestions.filter(s => {
      return (
        s.name.toUpperCase().includes(query) ||
        s.email.toUpperCase().includes(query)
      );
    });
    return searchResults;
  };

  const customRenderTags = tags => {
    //override the tags render
    return (
      <View style={styles.customTagsContainer}>
        {tagsSelected.map((t, i) => {
          return (
            <TouchableHighlight
              key={i}
              style={styles.customTag}
              onPress={() => handleDelete(i)}
            >
              <Text style={{ color: 'white' }}>{t.name || t.email}</Text>
            </TouchableHighlight>
          );
        })}
      </View>
    );
  };

  const customRenderSuggestion = suggestion => {
    //override suggestion render the drop down
    const name = suggestion.name;
    return <Text>{name}</Text>;
  };

  const handleDelete = ind => {
    //tag deleted, remove from our tags array
    setTagsSelected(tagsSelected.filter((value, index) => index !== ind));
  };

  const handleAddition = contact => {
    //suggestion clicked, push it to our tags array
    // this.setState({ tagsSelected: this.state.tagsSelected.concat([contact]) });
    setTagsSelected(tagsSelected.concat([contact]));
  };

  const onCustomTagCreated = userInput => {
    //user pressed enter, create a new tag from their input
    const contact = {
      email: userInput,
      name: null,
    };
    handleAddition(contact);
  };

  const { navigation } = props;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.keyboardContainer}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.autoContainer}>
          <View style={styles.autocompleteContainer}>
            <Text style={styles.label}>Recipients</Text>

            <AutoTags
              //required
              suggestions={suggestions}
              tagsSelected={tagsSelected}
              handleAddition={handleAddition}
              handleDelete={handleDelete}
              //optional
              placeholder="Add a contact.."
              filterData={customFilterData}
              renderSuggestion={customRenderSuggestion}
              renderTags={customRenderTags}
              onCustomTagCreated={onCustomTagCreated}
              createTagOnSpace
            />
          </View>
          <View style={styles.messageContainer}>
            <ButtonSolid
              style={[styles.ButtonSolidKk]}
              onPress={() => {
                try {
                  let result = tagsSelected.map(a => a.id);
                  result.push(variables.user_id);
                  result = result.sort();
                  //TODO IF logic the result array matches an existing GET call - navigate user to existing Chat Room
                  const getOptions = {
                    method: 'GET',
                    headers: new Headers({
                      Authorization: 'Bearer ' + variables.auth_header,
                      'Content-Type': 'application/json',
                    }),
                  };
                  fetch(
                    'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chatrooms/existing/' +
                      result.join('-'),
                    getOptions
                  ).then(response =>
                    response.json().then(data => {
                      if (data.id) {
                        navigation.navigate('ChatScreen', {
                          chatroom_id: data?.id,
                        });
                      } else {
                        //Else navigate them to below logic that creates a new chatroom
                        const requestOptions = {
                          method: 'POST',
                          headers: new Headers({
                            Authorization: 'Bearer ' + variables.auth_header,
                            'Content-Type': 'application/json',
                          }),
                          body: JSON.stringify({
                            members_ids: result,
                            title: result.join('-'),
                          }),
                        };
                        fetch(
                          'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chatrooms',
                          requestOptions
                        ).then(response =>
                          response.json().then(data =>
                            navigation.navigate('ChatScreen', {
                              chatroom_id: data?.id,
                            })
                          )
                        );
                      }
                    })
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              title={'Create'}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

export const ShareFolder = props => {
  const variables = GlobalVariableContext.useValues();
  const [tagsSelected, setTagsSelected] = useState([]);
  const [suggestions, setSuggestions] = useState();
  const [users, setUsers] = useState();

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + variables.auth_header,
        'Content-Type': 'application/json',
      }),
    };
    fetch(
      'https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/user',
      requestOptions
    ).then(response =>
      response.json().then(data => setSuggestions(data.items))
    );
  }, []);

  const customFilterData = query => {
    //override suggestion filter, we can search by specific attributes
    query = query.toUpperCase();
    let searchResults = suggestions.filter(s => {
      return (
        s.name.toUpperCase().includes(query) ||
        s.email.toUpperCase().includes(query)
      );
    });
    return searchResults;
  };

  const customRenderTags = tags => {
    //override the tags render
    return (
      <View style={styles.customTagsContainer}>
        {tagsSelected.map((t, i) => {
          return (
            <TouchableHighlight
              key={i}
              style={styles.customTag}
              onPress={() => handleDelete(i)}
            >
              <Text style={{ color: 'white' }}>{t.name || t.email}</Text>
            </TouchableHighlight>
          );
        })}
      </View>
    );
  };

  const customRenderSuggestion = suggestion => {
    //override suggestion render the drop down
    const name = suggestion.name;
    return <Text>{name}</Text>;
  };

  const handleDelete = ind => {
    //tag deleted, remove from our tags array
    setTagsSelected(tagsSelected.filter((value, index) => index !== ind));
  };

  const handleAddition = contact => {
    //suggestion clicked, push it to our tags array
    // this.setState({ tagsSelected: this.state.tagsSelected.concat([contact]) });
    setTagsSelected(tagsSelected.concat([contact]));
  };

  const onCustomTagCreated = userInput => {
    //user pressed enter, create a new tag from their input
    const contact = {
      email: userInput,
      name: null,
    };
    handleAddition(contact);
  };

  const { navigation } = props;

  return (
    <View style={styles.autoContainer}>
      <View style={styles.autocompleteContainer}>
        <Text style={styles.label}>Share This Folder With</Text>

        <AutoTags
          //required
          suggestions={suggestions}
          tagsSelected={tagsSelected}
          handleAddition={handleAddition}
          handleDelete={handleDelete}
          //optional
          placeholder="Add a contact.."
          filterData={customFilterData}
          renderSuggestion={customRenderSuggestion}
          renderTags={customRenderTags}
          onCustomTagCreated={onCustomTagCreated}
          createTagOnSpace
        />
      </View>
      <View style={styles.messageContainer}>
        <ButtonSolid
          style={[styles.ButtonSolidKk]}
          onPress={() => {
            try {
              let result = tagsSelected.map(a => a.id);
              result = result.sort();
              const requestOptions = {
                method: 'POST',
                headers: new Headers({
                  Authorization: 'Bearer ' + variables.auth_header,
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                  shared_user_ids: result,
                  folders_id: variables.current_folder_id,
                }),
              };
              fetch(
                'https://xjz0-jmv4-gghx.n7.xano.io/api:qSqAPJML/folders/' +
                  variables.current_folder_id,
                requestOptions
              ).then(response =>
                response.json().then(data =>
                  navigation.navigate('FolderContentsScreen', {
                    folder_id: variables.current_folder_id,
                  })
                )
              );
            } catch (err) {
              console.error(err);
            }
          }}
          title={'Share'}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  keyboardContainer: {
    flex: 1,
  },
  customTagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    backgroundColor: '#efeaea',
    width: 300,
  },
  item: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
    marginTop: 17,
    flexDirection: 'row',
  },
  emptyDate: {
    height: 15,
    flex: 1,
    paddingTop: 30,
  },
  ButtonSolidKk: {
    borderRadius: 8,
    fontFamily: 'System',
    marginBottom: 50,
    fontWeight: '700',
    width: 150,
    textAlign: 'center',
    backgroundColor: '#F16834',
  },
  customTag: {
    backgroundColor: '#F16834',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    marginLeft: 5,
    marginTop: 5,
    borderRadius: 30,
    padding: 8,
  },
  autoContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  header: {
    backgroundColor: '#011145',
    height: 80,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 15,
    marginBottom: 10,
  },
  autocompleteContainer: {
    flex: 1,
    left: 20,
    position: 'absolute',
    right: 20,
    top: 100,
    zIndex: 1,
  },
  label: {
    color: '#614b63',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    // marginTop: 160,
    height: 200,
    // alignSelf: 'stretch',
    // marginLeft: 20,
    // marginRight: 20,
  },
  message: {
    backgroundColor: '#efeaea',
    height: 200,
    textAlignVertical: 'top',
  },
  TextVc: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
  },
  Textsu: {
    fontSize: 14,
    fontFamily: 'Roboto_400Regular',
  },
  Viewaa: {
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Textub: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 18,
  },
  Texttq: {
    marginLeft: 5,
    fontFamily: 'Roboto_400Regular',
  },
  ViewXm: {
    flexDirection: 'row',
  },
  View_39: {
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
  ViewLm: {
    flexDirection: 'row',
    marginLeft: 30,
    marginTop: 50,
    marginRight: 30,
    paddingTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 15,
  },
});
