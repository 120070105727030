import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  Button,
  Icon,
  ScreenContainer,
  Spacer,
  Surface,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const OpportunityDetailsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;

  const risingCoachesRemoveApplicationDELETE =
    RisingCoachesApi.useRemoveApplicationDELETE();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <ScrollView
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        bounces={true}
      >
        <RisingCoachesApi.FetchGetOpportunityDetailGET
          opportunity_id={props.route?.params?.opportunity_id ?? 9}
        >
          {({ loading, error, data, refetchGetOpportunityDetail }) => {
            const fetchData = data;
            if (!fetchData || loading) {
              return <ActivityIndicator />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center' }}>
                  There was a problem fetching this data
                </Text>
              );
            }

            return (
              <>
                {/* NewFeedCard */}
                <Surface style={styles(theme).Surfaceb83606e0} elevation={1}>
                  {/* PostHeader */}
                  <View style={styles(theme).View7963ad69}>
                    {/* LeftAligned */}
                    <View style={styles(theme).Viewce4accf0}>
                      {/* Light BG */}
                      <View style={styles(theme).Viewff6e3680}>
                        <Image
                          style={styles(theme).Image8dbb3d0d}
                          source={{ uri: `${fetchData?.company_logo?.url}` }}
                          resizeMode={'contain'}
                        />
                      </View>

                      <View style={styles(theme).View4d148d43}>
                        {/* Comp Name */}
                        <Text style={styles(theme).Textbba75e6f}>
                          {fetchData?.company_name}
                        </Text>
                      </View>
                    </View>
                    {/* RightAligned */}
                    <View style={styles(theme).View967fd4f5} />
                  </View>
                  {/* Details */}
                  <View style={styles(theme).View0641ebe8}>
                    {/* First Row */}
                    <View style={styles(theme).View396d0aa4}>
                      {/* LeftSide */}
                      <View style={styles(theme).View84be2fa1}>
                        <Text style={styles(theme).Textc36ed116}>
                          {fetchData?.opportunity_title}
                        </Text>
                      </View>
                    </View>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    {/* Desc Row */}
                    <View style={styles(theme).View7056af44}>
                      {/* LeftSide */}
                      <View>
                        <Text style={styles(theme).Textc36ed116}>
                          {'Description\n'}
                        </Text>
                      </View>
                    </View>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    <View>
                      <Text style={styles(theme).Text6ec42223}>
                        {fetchData?.description}
                      </Text>
                    </View>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    {/* Second Row */}
                    <View style={styles(theme).View396d0aa4}>
                      {/* LeftSide */}
                      <View style={styles(theme).Viewce4accf0}>
                        <Icon
                          style={styles(theme).Iconfe2670fd}
                          name={'FontAwesome/money'}
                          size={20}
                          color={theme.colors.light}
                        />
                        <Text style={styles(theme).Text98a40b93}>
                          {fetchData?.price_range}{' '}
                          {fetchData?.compensation_rate}
                        </Text>
                      </View>
                    </View>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    {/* Third Row */}
                    <View style={styles(theme).View396d0aa4}>
                      {/* LeftSide */}
                      <View style={styles(theme).Viewce4accf0}>
                        <Icon
                          style={styles(theme).Iconfe2670fd}
                          name={'FontAwesome/map-marker'}
                          size={20}
                          color={theme.colors.light}
                        />
                        <Text style={styles(theme).Texta3059d3e}>
                          {fetchData?.city}
                          {', '}
                          {fetchData?.state}
                        </Text>
                      </View>
                      {/* RightSide */}
                      <View>
                        <Text style={styles(theme).Text1f3d5812}>
                          {fetchData?.opportunity_type}
                        </Text>
                      </View>
                    </View>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    {/* Fourth Row */}
                    <View style={styles(theme).View396d0aa4}>
                      {/* LeftSide */}
                      <View style={styles(theme).View01ac7b75}>
                        <Icon
                          style={styles(theme).Icon8bd683a8}
                          name={'Feather/briefcase'}
                          size={20}
                          color={theme.colors.light}
                        />
                        <Text style={styles(theme).Text5e591866}>
                          {fetchData?.minimum_education}
                        </Text>
                      </View>
                      {/* RightSide */}
                      <View>
                        <Text style={styles(theme).Text9cf1a5d1}>
                          {'2 Days Left'}
                        </Text>
                      </View>
                    </View>
                    <Spacer top={8} right={8} bottom={8} left={8} />
                    {/* NotLoading */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <View>
                          {/* Button Solid */}
                          <>
                            {fetchData?.isApplied ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await WebBrowser.openBrowserAsync(
                                        `${fetchData?.url_link}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={styles(theme).Button8ce897e6}
                                title={'Apply'}
                              />
                            )}
                          </>
                          {/* Button Solid */}
                          <>
                            {!fetchData?.isApplied ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setGlobalVariableValue({
                                        key: 'is_loading',
                                        value: true,
                                      });
                                      await risingCoachesRemoveApplicationDELETE.mutateAsync(
                                        {
                                          applicant_id:
                                            fetchData?.applicants_of_opp_of_user
                                              ?.id,
                                        }
                                      );
                                      await refetchGetOpportunityDetail();
                                      setGlobalVariableValue({
                                        key: 'is_loading',
                                        value: false,
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={styles(theme).Button8ce897e6}
                                title={'Remove Application'}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* Loading */}
                    <>
                      {!Constants['is_loading'] ? null : (
                        <View>
                          {/* Button Solid */}
                          <>
                            {!Constants['is_loading'] ? null : (
                              <Button
                                style={styles(theme).Button8ce897e6}
                                title={''}
                                loading={true}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                </Surface>
              </>
            );
          }}
        </RisingCoachesApi.FetchGetOpportunityDetailGET>
      </ScrollView>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button8ce897e6: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Fetch431eb058: { minHeight: 40 },
    Icon8bd683a8: { marginRight: 6 },
    Iconfe2670fd: { marginRight: 3 },
    Image8dbb3d0d: { height: 50, width: 50 },
    Surfaceb83606e0: {
      alignContent: 'stretch',
      alignItems: 'center',
      alignSelf: 'stretch',
      backgroundColor: theme.colors.surface,
      borderRadius: 20,
      justifyContent: 'space-around',
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
    },
    Text1f3d5812: {
      color: theme.colors.primary,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
    },
    Text5e591866: { color: theme.colors.light, fontSize: 12 },
    Text6ec42223: {
      color: theme.colors.medium,
      marginBottom: 10,
      marginLeft: 3,
      marginTop: 10,
    },
    Text98a40b93: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
    },
    Text9cf1a5d1: { color: theme.colors.error, fontSize: 12 },
    Texta3059d3e: { color: theme.colors.strong, fontSize: 12 },
    Textbba75e6f: {
      color: theme.colors.medium,
      fontFamily: 'Roboto_700Bold',
      fontSize: 16,
      marginBottom: 2,
    },
    Textc36ed116: { color: theme.colors.strong, fontFamily: 'Roboto_700Bold' },
    Textd8d33cc8: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
    },
    View01ac7b75: { alignItems: 'center', flexDirection: 'row', maxWidth: 220 },
    View0641ebe8: {
      alignContent: 'center',
      alignSelf: 'stretch',
      borderRadius: 12,
      justifyContent: 'space-between',
      marginBottom: 8,
      marginTop: 5,
      maxWidth: '100%',
      minWidth: 280,
      overflow: 'hidden',
    },
    View396d0aa4: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: 5,
      marginRight: 5,
    },
    View4d148d43: { marginLeft: 10, maxWidth: 150 },
    View7056af44: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginLeft: 5,
      marginRight: 5,
    },
    View7963ad69: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 3,
    },
    View84be2fa1: { maxWidth: 200 },
    View967fd4f5: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    Viewb0908538: {
      backgroundColor: theme.colors.background,
      borderRadius: 10,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Viewce4accf0: { alignItems: 'center', flexDirection: 'row' },
    Viewff6e3680: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 65,
      justifyContent: 'center',
      width: 65,
    },
  });

export default withTheme(OpportunityDetailsScreen);
