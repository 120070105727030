import React from 'react';
import * as MagicLinkApi from '../apis/MagicLinkApi.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  Button,
  Icon,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  ActivityIndicator,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';

const AccountSettingsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesDeleteAccountDELETE =
    RisingCoachesApi.useDeleteAccountDELETE();
  const magicLinkChangePasswordPOST = MagicLinkApi.useChangePasswordPOST();

  const [confirm, setConfirm] = React.useState('');
  const [error_message, setError_message] = React.useState('');
  const [isDelete, setIsDelete] = React.useState(false);
  const [isReset, setIsReset] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer hasTopSafeArea={true} hasBottomSafeArea={true}>
      {/* Content Scroll Frame */}
      <ScrollView showsVerticalScrollIndicator={true} bounces={true}>
        {/* Featured Frame */}
        <View style={styles(theme).Viewb792de4b}>
          {/* Title Featured */}
          <Text style={styles(theme).Texta16ac0d0}>{'Settings'}</Text>
        </View>
        {/* App Menu Frame */}
        <View style={styles(theme).View25967461}>
          {/* Touchable Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  setIsReset(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* App Option Frame */}
              <View style={styles(theme).View08b70666}>
                {/* FAQ Text */}
                <Text style={styles(theme).Text03791f6f}>
                  {'Reset Password'}
                </Text>
                {/* Icon Flex Attributes */}
                <View>
                  {/* Right Arrow */}
                  <Icon
                    name={'AntDesign/arrowright'}
                    size={18}
                    color={theme.colors.nFTTIMEBlack}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* Touchable Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  setIsDelete(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* App Option Frame */}
              <View style={styles(theme).View08b70666}>
                {/* FAQ Text */}
                <Text style={styles(theme).Text4e6f40c9}>
                  {'Delete Account'}
                </Text>
                {/* Icon Flex Attributes */}
                <View>
                  {/* Right Arrow */}
                  <Icon
                    name={'AntDesign/arrowright'}
                    size={18}
                    color={theme.colors.nFTTIMEBlack}
                  />
                </View>
              </View>
            </Touchable>
          </View>
        </View>
      </ScrollView>
      {/* DeleteModal */}
      <>
        {!isDelete ? null : (
          <Modal visible={isDelete} animationType={'slide'}>
            {/* Main */}
            <View style={styles(theme).Viewfcc0e289}>
              {/* Header */}
              <View style={styles(theme).View1c246ec1}>
                <Text style={styles(theme).Textb94f5252}>
                  {
                    'Are you sure you want to delete your account? This action cannot be undone!'
                  }
                </Text>
              </View>
              {/* Buttons */}
              <View style={styles(theme).Vieweae7619e}>
                <Button
                  onPress={() => {
                    try {
                      setIsDelete(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={styles(theme).Button98b165f1}
                  title={'NO'}
                />
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDelete(false);
                        navigation.navigate('WelcomeScreen');
                        await risingCoachesDeleteAccountDELETE.mutateAsync({
                          user_id: Constants['user_id'],
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={styles(theme).Button2070a25f}
                  title={'YES'}
                />
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* ResetModal */}
      <>
        {!isReset ? null : (
          <Modal visible={isReset} animationType={'slide'}>
            {/* Main */}
            <View style={styles(theme).Viewdcd038e3}>
              {/* Header */}
              <>
                {Constants['is_loading'] ? null : (
                  <View style={styles(theme).View1f34cbc1}>
                    <Text style={styles(theme).Text085e18a5}>
                      {'Reset Your Password'}
                    </Text>

                    <Text style={styles(theme).Text0500c24c}>
                      {error_message}
                    </Text>
                  </View>
                )}
              </>
              {/* Form */}
              <View style={styles(theme).View71644159}>
                {/* Password Input */}
                <TextInput
                  onChangeText={newPasswordInputValue => {
                    try {
                      setPassword(newPasswordInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={styles(theme).TextInputb2cb2d6f}
                  placeholder={'Enter New Password'}
                  editable={true}
                />
                <Spacer top={8} right={8} bottom={8} left={8} />
                {/* Confirm Input */}
                <TextInput
                  onChangeText={newConfirmInputValue => {
                    try {
                      setConfirm(newConfirmInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={styles(theme).TextInputb2cb2d6f}
                  placeholder={'Confirm New Password'}
                  editable={true}
                />
                <Spacer top={8} right={8} bottom={8} left={8} />
              </View>
              <>
                {Constants['is_loading'] ? null : (
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: true,
                          });
                          const response =
                            await magicLinkChangePasswordPOST.mutateAsync({
                              confirm: confirm,
                              password: password,
                            });
                          const message = response.message;
                          setError_message(message);
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: false,
                          });
                          if (message) {
                            return;
                          }
                          setGlobalVariableValue({
                            key: 'auth_header',
                            value: '',
                          });
                          navigation.navigate('WelcomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={styles(theme).Button364e84f9}
                    title={'Change Password'}
                  />
                )}
              </>
              <>
                {!Constants['is_loading'] ? null : (
                  <ActivityIndicator
                    style={styles(theme).ActivityIndicator6eafe5f2}
                    size={'small'}
                    animating={true}
                    hidesWhenStopped={true}
                  />
                )}
              </>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    ActivityIndicator6eafe5f2: { height: 36, width: 36 },
    Button2070a25f: {
      backgroundColor: theme.colors['Error'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
      width: 100,
    },
    Button364e84f9: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
      textAlign: 'center',
    },
    Button98b165f1: {
      backgroundColor: theme.colors['Secondary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
      width: 100,
    },
    Text03791f6f: {
      color: theme.colors.nFTTIMEBlack,
      fontFamily: 'System',
      fontSize: 12,
      fontWeight: '500',
      textTransform: 'uppercase',
    },
    Text0500c24c: { color: theme.colors['Error'] },
    Text085e18a5: { fontFamily: 'Manrope_700Bold', fontSize: 24 },
    Text4e6f40c9: {
      color: theme.colors['Error'],
      fontFamily: 'System',
      fontSize: 12,
      fontWeight: '500',
      textTransform: 'uppercase',
    },
    TextInputb2cb2d6f: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Texta16ac0d0: {
      color: theme.colors.nFTTimeUIBlack,
      fontFamily: 'System',
      fontSize: 30,
      fontWeight: '600',
    },
    Textb94f5252: {
      alignSelf: 'center',
      color: theme.colors['Error'],
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    View08b70666: {
      borderBottomWidth: 1,
      borderColor: theme.colors.nFTTIMEBlack,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 18,
      paddingTop: 18,
    },
    View1c246ec1: {
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 10,
    },
    View1f34cbc1: {
      alignContent: 'center',
      alignSelf: 'center',
      marginBottom: 20,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 20,
    },
    View25967461: {
      paddingBottom: 18,
      paddingLeft: 21,
      paddingRight: 21,
      paddingTop: 18,
    },
    View71644159: { marginLeft: 30, marginRight: 30 },
    Viewb792de4b: {
      paddingBottom: 18,
      paddingLeft: 21,
      paddingRight: 21,
      paddingTop: 36,
    },
    Viewdcd038e3: { alignContent: 'center', flex: 1, justifyContent: 'center' },
    Vieweae7619e: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: 10,
    },
    Viewfcc0e289: { alignSelf: 'center', flex: 1, justifyContent: 'center' },
  });

export default withTheme(AccountSettingsScreen);
