import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as LiveStories from '../custom-files/LiveStories';
import isEmpty from '../global-functions/isEmpty';
import * as Utils from '../utils';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Checkbox,
  Circle,
  CircleImage,
  Divider,
  Icon,
  IconButton,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  Surface,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const NewsFeedScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const isWinner = custom => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if ((custom = '')) {
      return true;
    } else false;
  };

  // Converts a date
  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const todayDate = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return CustomCode.moment().format('MMM Do');
  };

  // Takes image obj and returns the url within
  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj?.url;
  };

  const checkValidity = invalidAuth => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if (invalidAuth) {
      return '';
    }
    return Constants.auth_header;
  };

  const navigateOnFalseOnboarding = onboarded => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if (!onboarded) {
      setTimeout(() => props.navigation.navigate('OnboardingScreen'));
      return true;
    }
    return false;
  };

  const changeScoreTag = id => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if (id == 20) {
      setLeagueName('NBA');
    }
    if (id == 1) {
      setLeagueName('NCAAM');
    }
    if (id == 2) {
      setLeagueName('NCAAW');
    }
    if (id == 10) {
      setLeagueName('WNBA');
    }
    if (id == 21) {
      setLeagueName('G');
    }
  };

  const formatScoreDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    // "MMM Do"
    return CustomCode.moment(dateString).format('MMM Do');
  };

  const navigateOnInvalidAuth = onboarded => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    if (onboarded === undefined) {
      setTimeout(() => props.navigation.navigate('SignInScreen'));
      return true;
    }

    return false;
  };

  const { theme } = props;
  const { navigation } = props;

  React.useEffect(() => {
    StatusBar.setBarStyle('dark-content');
  }, []);

  const risingCoachesDeleteFeedPostDELETE =
    RisingCoachesApi.useDeleteFeedPostDELETE();
  const risingCoachesUnfollowUserDELETE =
    RisingCoachesApi.useUnfollowUserDELETE();
  const risingCoachesFollowUserPOST = RisingCoachesApi.useFollowUserPOST();
  const risingCoachesAddLikePOST = RisingCoachesApi.useAddLikePOST();
  const risingCoachesRemoveLikeDELETE = RisingCoachesApi.useRemoveLikeDELETE();
  const risingCoachesSaveFeedToFolderPOST =
    RisingCoachesApi.useSaveFeedToFolderPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });
        const auth_response = await RisingCoachesApi.checkAuthGET(Constants);
        const onboarded = auth_response.isOnboarded;
        console.log(Constants['auth_header']);
        const invalidAuth = navigateOnInvalidAuth(onboarded);
        const newAuth = checkValidity(invalidAuth);
        setGlobalVariableValue({
          key: 'auth_header',
          value: newAuth,
        });
        if (invalidAuth) {
          return;
        }
        const notOnboarded = navigateOnFalseOnboarding(onboarded);
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });
        const response = await RisingCoachesApi.checkPayingGET(Constants);
        const isPaying = response.isPaying;
        setGlobalVariableValue({
          key: 'user_paying',
          value: isPaying,
        });
        const event = await RisingCoachesApi.checkLiveEventGET(Constants);
        const live = event.isLive;
        setIsLive(live);
        setEvent_name(event?.event_name);
        setEvent_url(event?.event_url);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [categories, setCategories] = React.useState("['All', 'Recruiting']");
  const [event_name, setEvent_name] = React.useState('');
  const [event_url, setEvent_url] = React.useState('');
  const [folderList, setFolderList] = React.useState(
    "['Test One', 'Test Two']"
  );
  const [isFilterModal, setIsFilterModal] = React.useState(false);
  const [isFolderModal, setIsFolderModal] = React.useState(false);
  const [isLive, setIsLive] = React.useState(false);
  const [isMineSettings, setIsMineSettings] = React.useState(false);
  const [isScoreFilter, setIsScoreFilter] = React.useState(false);
  const [isSettings, setIsSettings] = React.useState(false);
  const [leagueName, setLeagueName] = React.useState('NCCAM');
  const [league_id, setLeague_id] = React.useState(0);
  const [liveStories, setLiveStories] = React.useState({});
  const [pickerValue, setPickerValue] = React.useState('');
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [searchBarValue, setSearchBarValue] = React.useState('Naufel');
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [selectedFeedPost, setSelectedFeedPost] = React.useState({});
  const [selectedPost, setSelectedPost] = React.useState(0);
  const [showNav, setShowNav] = React.useState(false);
  const [tokenIsValid, setTokenIsValid] = React.useState(false);

  return (
    <ScreenContainer
      style={styles(theme).screen}
      hasTopSafeArea={false}
      hasSafeArea={false}
    >
      <RisingCoachesApi.FetchGetStoriesGET
        onData={fetchData => {
          try {
            setGlobalVariableValue({
              key: 'is_loading',
              value: true,
            });
            setLiveStories(fetchData);
            setGlobalVariableValue({
              key: 'is_loading',
              value: false,
            });
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ loading, error, data, refetchGetStories }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              {/* All Action Sheet */}
              <ActionSheet visible={isSettings}>
                {/* Save Action */}
                <ActionSheetItem
                  onPress={() => {
                    try {
                      setIsFolderModal(true);
                      setIsSettings(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={styles(theme).ActionSheetItemc521608b}
                  label={'Save Post to Folder'}
                  color={theme.colors.strong}
                />
                <ActionSheetCancel
                  onPress={() => {
                    try {
                      setIsSettings(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  label={'Cancel'}
                />
              </ActionSheet>
              {/* isMine Action Sheet */}
              <ActionSheet visible={isMineSettings}>
                {/* Save Action */}
                <ActionSheetItem
                  onPress={() => {
                    try {
                      setIsMineSettings(false);
                      setIsFolderModal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={styles(theme).ActionSheetItemc521608b}
                  label={'Save Post to Folder'}
                  color={theme.colors.strong}
                />
                <ActionSheetItem
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await risingCoachesDeleteFeedPostDELETE.mutateAsync({
                          news_feed_content_id: selectedPost,
                        });
                        setIsMineSettings(false);
                        await refetchGetFeedWithFilter();
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={styles(theme).ActionSheetItemc521608b}
                  label={'Delete Post'}
                  color={theme.colors.error}
                />
                <ActionSheetCancel
                  onPress={() => {
                    try {
                      setIsMineSettings(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  label={'Cancel'}
                />
              </ActionSheet>
              {/* Drawer */}
              <>
                {!showNav ? null : (
                  <Surface style={styles(theme).Surface3493a395}>
                    <View style={styles(theme).Viewb990361f}>
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('MemberDetailScreen', {
                              navigate_user_Id: Constants['user_id'],
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View style={styles(theme).Viewce4accf0}>
                          <Circle size={64} bgColor={theme.colors.surface}>
                            <>
                              {!Constants['user_image'] ? null : (
                                <CircleImage
                                  source={{ uri: `${Constants['user_image']}` }}
                                  size={80}
                                />
                              )}
                            </>
                          </Circle>

                          <View style={styles(theme).View8e23439a}>
                            <Text style={styles(theme).Text3171c9b1}>
                              {Constants['user_name']}
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                    </View>

                    <ScrollView
                      bounces={true}
                      showsVerticalScrollIndicator={false}
                    >
                      <View style={styles(theme).Viewf82d10d1}>
                        {/* Home */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon name={'Feather/home'} size={24} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Home'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Inbox */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('InboxScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon name={'Feather/message-square'} size={24} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Inbox'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Repository */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('ContentRepositoryScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon size={24} name={'Feather/folder'} />
                            {/* Content Repository */}
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Content Library'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Calendar */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('CalendarScreen_coaa8c4F');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon size={24} name={'AntDesign/calendar'} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Events'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Directory */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('MemberDirectoryScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon size={24} name={'AntDesign/contacts'} />
                            {/* Content Repository */}
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Directory'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Opportunities */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('JobsBoardScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon
                              size={24}
                              name={'Ionicons/business-outline'}
                            />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Jobs Board'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* DEI Alliance */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('DEIAllianceScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon size={24} name={'AntDesign/team'} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'DEI Alliance'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Profile */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('MemberDetailScreen', {
                                navigate_user_Id: Constants['user_id'],
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon name={'Feather/user'} size={24} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Profile'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Notifications */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('NotificationsScreen');
                              setShowNav(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon size={24} name={'Feather/bell'} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Notifications'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Settings */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('AccountSettingsScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon size={24} name={'AntDesign/setting'} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Settings'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Logout */}
                        <Touchable
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'auth_header',
                                value: '',
                              });
                              navigation.navigate('WelcomeScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles(theme).Viewa2f3c3e0}>
                            <Icon name={'Feather/log-out'} size={24} />
                            <Text style={styles(theme).Text1dae38a6}>
                              {'Sign out'}
                            </Text>
                          </View>
                        </Touchable>
                      </View>
                    </ScrollView>
                  </Surface>
                )}
              </>
              {/* Main Body */}
              <View style={styles(theme).View56fa1414}>
                {/* Home Header */}
                <View style={styles(theme).View77b39bc7}>
                  <View style={styles(theme).View8d4fc31a}>
                    <Image
                      style={styles(theme).Image056264fa}
                      resizeMode={'contain'}
                      source={Images.Risingcoacheswhite}
                    />
                    <Checkbox
                      onPress={newCheckboxValue => {
                        try {
                          setShowNav(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      status={showNav}
                      color={theme.colors.primary}
                      checkedIcon={'Feather/x'}
                      uncheckedIcon={'Feather/menu'}
                      size={32}
                      uncheckedColor={theme.colors.custom_rgb255_255_255}
                    />
                  </View>
                </View>
                {/* Body */}
                <View style={styles(theme).View98b256f7}>
                  <KeyboardAwareScrollView
                    keyboardShouldPersistTaps={'never'}
                    showsVerticalScrollIndicator={false}
                  >
                    {/* PoweredBy */}
                    <View style={styles(theme).Viewa46b0be4}>
                      <Text style={styles(theme).Text2be49fc2}>
                        {'Scoreboard powered by'}
                      </Text>
                      <Image
                        style={styles(theme).Image31d6c46d}
                        resizeMode={'cover'}
                        source={Images.PivotIcon}
                      />
                    </View>
                    {/* Scoreboard View */}
                    <View style={styles(theme).Viewa7cc7886}>
                      <Touchable
                        onPress={() => {
                          try {
                            setIsScoreFilter(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Surface style={styles(theme).Surface6822e2d0}>
                          <Text style={styles(theme).Text5fe97391}>
                            {todayDate()}
                          </Text>

                          <Text style={styles(theme).Text3060bf70}>
                            {leagueName}
                          </Text>
                        </Surface>
                      </Touchable>

                      <ScrollView
                        style={styles(theme).ScrollViewb284e5b0}
                        contentContainerStyle={
                          styles(theme).ScrollView86250d6fContent
                        }
                        bounces={true}
                        showsVerticalScrollIndicator={false}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                      >
                        {/* ScoresFetch */}
                        <RisingCoachesApi.FetchGetScoresGET leagues={league_id}>
                          {({ loading, error, data, refetchGetScores }) => {
                            const scoresFetchData = data;
                            if (!scoresFetchData || loading) {
                              return <ActivityIndicator />;
                            }

                            if (error) {
                              return (
                                <Text style={{ textAlign: 'center' }}>
                                  There was a problem fetching this data
                                </Text>
                              );
                            }

                            return (
                              <>
                                {/* ScoresList */}
                                <>
                                  {!scoresFetchData?.response?.result?.results
                                    ?.length ? null : (
                                    <FlatList
                                      data={
                                        scoresFetchData?.response?.result
                                          ?.results
                                      }
                                      listKey={'Lx3h5Ob9'}
                                      keyExtractor={scoresListData =>
                                        scoresListData?.id ||
                                        scoresListData?.uuid ||
                                        JSON.stringify(scoresListData)
                                      }
                                      renderItem={({ item }) => {
                                        const scoresListData = item;
                                        return (
                                          <View
                                            style={styles(theme).View04e5be62}
                                          >
                                            <Surface
                                              style={
                                                styles(theme).Surface94216a62
                                              }
                                              elevation={0}
                                            >
                                              <View
                                                style={
                                                  styles(theme).Viewb579045b
                                                }
                                              >
                                                <View
                                                  style={
                                                    styles(theme).Viewbf609868
                                                  }
                                                >
                                                  {/* HomeTeam */}
                                                  <View
                                                    style={
                                                      styles(theme).Viewce4accf0
                                                    }
                                                  >
                                                    <CircleImage
                                                      style={
                                                        styles(theme)
                                                          .CircleImagee0bcd704
                                                      }
                                                      source={{
                                                        uri: `${scoresListData?.home?.image}`,
                                                      }}
                                                      size={60}
                                                    />
                                                    {/* Coming Soon */}
                                                    <Text
                                                      style={
                                                        styles(theme)
                                                          .Textfa827c01
                                                      }
                                                    >
                                                      {
                                                        scoresListData?.home
                                                          ?.name_short
                                                      }
                                                      {': '}
                                                      {
                                                        scoresListData?.home_points
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* VisitorTeam */}
                                                  <View
                                                    style={
                                                      styles(theme).View65b17fb5
                                                    }
                                                  >
                                                    <CircleImage
                                                      style={
                                                        styles(theme)
                                                          .CircleImagee0bcd704
                                                      }
                                                      source={{
                                                        uri: `${scoresListData?.visitor?.image}`,
                                                      }}
                                                      size={60}
                                                    />
                                                    <Text
                                                      style={
                                                        styles(theme)
                                                          .Textfa827c01
                                                      }
                                                    >
                                                      {
                                                        scoresListData?.visitor
                                                          ?.name_short
                                                      }
                                                      {': '}
                                                      {
                                                        scoresListData?.visitor_points
                                                      }
                                                    </Text>
                                                  </View>
                                                </View>

                                                <View
                                                  style={
                                                    styles(theme).View1c24b933
                                                  }
                                                >
                                                  {/* TopOption */}
                                                  <View
                                                    style={
                                                      styles(theme).Viewce4accf0
                                                    }
                                                  >
                                                    {/* Coming Soon */}
                                                    <Text
                                                      style={
                                                        styles(theme)
                                                          .Textde3925a8
                                                      }
                                                    >
                                                      {'Final'}
                                                    </Text>
                                                  </View>
                                                  {/* Bottom Option */}
                                                  <View
                                                    style={
                                                      styles(theme).View65b17fb5
                                                    }
                                                  >
                                                    <Text
                                                      style={
                                                        styles(theme)
                                                          .Textde3925a8
                                                      }
                                                    >
                                                      {formatScoreDate(
                                                        scoresListData?.start_time
                                                      )}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                            </Surface>
                                          </View>
                                        );
                                      }}
                                      style={styles(theme).FlatList989db244}
                                      contentContainerStyle={
                                        styles(theme).FlatList2fbd360fContent
                                      }
                                      numColumns={1}
                                      horizontal={true}
                                    />
                                  )}
                                </>
                                <>
                                  {scoresFetchData?.response?.result?.results
                                    ?.length ? null : (
                                    <View style={styles(theme).View10eb8625}>
                                      <Text style={styles(theme).Text4505c294}>
                                        {'No games found for this date/filter.'}
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </>
                            );
                          }}
                        </RisingCoachesApi.FetchGetScoresGET>
                      </ScrollView>
                    </View>

                    <Text style={styles(theme).Texte86f7e03}>
                      {'Featured Videos'}
                    </Text>
                    {/* FeaturedVideos */}
                    <View style={styles(theme).View3817e2ab}>
                      {/* FeaturedVideos */}
                      <RisingCoachesApi.FetchGetFeaturedVideosGET>
                        {({
                          loading,
                          error,
                          data,
                          refetchGetFeaturedVideos,
                        }) => {
                          const featuredVideosData = data;
                          if (!featuredVideosData || loading) {
                            return <ActivityIndicator />;
                          }

                          if (error) {
                            return (
                              <Text style={{ textAlign: 'center' }}>
                                There was a problem fetching this data
                              </Text>
                            );
                          }

                          return (
                            <ScrollView
                              style={styles(theme).ScrollViewb284e5b0}
                              contentContainerStyle={
                                styles(theme).ScrollView989db244Content
                              }
                              bounces={true}
                              horizontal={true}
                              showsVerticalScrollIndicator={false}
                            >
                              {/* FeaturedList */}
                              <FlatList
                                data={featuredVideosData}
                                listKey={'wahSy7Xp'}
                                keyExtractor={featuredListData =>
                                  featuredListData?.id ||
                                  featuredListData?.uuid ||
                                  JSON.stringify(featuredListData)
                                }
                                renderItem={({ item }) => {
                                  const featuredListData = item;
                                  return (
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'FeaturedVideoScreen',
                                            {
                                              video_url:
                                                featuredListData?.video_url,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View style={styles(theme).View7edaa38d}>
                                        <Text
                                          style={styles(theme).Text0d54818a}
                                        >
                                          {featuredListData?.title}
                                        </Text>
                                      </View>

                                      <View style={styles(theme).Viewc5ec2460}>
                                        <Image
                                          style={styles(theme).Image08e5e149}
                                          resizeMode={'cover'}
                                          source={{
                                            uri: `${featuredListData?.thumbnail}`,
                                          }}
                                        />
                                      </View>
                                    </Touchable>
                                  );
                                }}
                                style={styles(theme).FlatList989db244}
                                contentContainerStyle={
                                  styles(theme).FlatList55a10db3Content
                                }
                                numColumns={1}
                                horizontal={true}
                              />
                            </ScrollView>
                          );
                        }}
                      </RisingCoachesApi.FetchGetFeaturedVideosGET>
                    </View>
                    {/* Live Stories */}
                    <>
                      {!Constants['user_paying'] ? null : (
                        <View style={styles(theme).View19b57072}>
                          <ScrollView
                            style={styles(theme).ScrollView989db244}
                            contentContainerStyle={
                              styles(theme).ScrollViewdebd3207Content
                            }
                            showsVerticalScrollIndicator={false}
                            bounces={true}
                            horizontal={true}
                          >
                            <View style={styles(theme).View81bf3e2f}>
                              <IconButton
                                onPress={() => {
                                  try {
                                    navigation.navigate('CreateStoryScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                icon={'Ionicons/md-add-circle-outline'}
                                size={65}
                              />
                            </View>
                            <>
                              {Constants['is_loading'] ? null : (
                                <Utils.CustomCodeErrorBoundary>
                                  <LiveStories.LiveStories
                                    navigation={props.navigation}
                                    stories={fetchData?.data}
                                  />
                                </Utils.CustomCodeErrorBoundary>
                              )}
                            </>
                          </ScrollView>
                        </View>
                      )}
                    </>
                    {/* Live Event */}
                    <>
                      {!Constants['user_paying'] ? null : (
                        <View>
                          {/* Live Event */}
                          <>
                            {!isLive ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('LiveMeetingScreen', {
                                      event_url: event_url,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={styles(theme).Touchablece85ea54}
                              >
                                <View style={styles(theme).View0e0c9f4d}>
                                  <IconButton
                                    size={32}
                                    icon={'MaterialIcons/live-tv'}
                                  />
                                  <Text style={styles(theme).Text394e855d}>
                                    {'Live Now: '}
                                    {event_name}
                                  </Text>
                                </View>
                              </Touchable>
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* CreateRow */}
                    <View style={styles(theme).View8878edf0}>
                      {/* LeftAligned */}
                      <View style={styles(theme).View2f4270eb}>
                        {/* Button Solid */}
                        <Button
                          onPress={() => {
                            try {
                              navigation.navigate('CreateFeedPostScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={styles(theme).Buttonf7e16baa}
                          title={'+ Add Post '}
                        />
                      </View>
                      {/* RightAligned */}
                      <View style={styles(theme).Viewb7a4585c}>
                        <IconButton
                          onPress={() => {
                            try {
                              setIsFilterModal(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={40}
                          icon={'Foundation/filter'}
                          color={theme.colors.primary}
                        />
                      </View>
                    </View>
                    <Divider
                      style={styles(theme).Dividerde11d607}
                      color={theme.colors.lightest}
                    />
                    {/* FeedFetch */}
                    <RisingCoachesApi.FetchGetFeedWithFilterGET
                      category={selectedCategory}
                    >
                      {({ loading, error, data, refetchGetFeedWithFilter }) => {
                        const feedFetchData = data;
                        if (!feedFetchData || loading) {
                          return <ActivityIndicator />;
                        }

                        if (error) {
                          return (
                            <Text style={{ textAlign: 'center' }}>
                              There was a problem fetching this data
                            </Text>
                          );
                        }

                        return (
                          <>
                            {/* Filter Modal */}
                            <>
                              {!isFilterModal ? null : (
                                <Modal
                                  visible={isFilterModal}
                                  animationType={'none'}
                                >
                                  <View style={styles(theme).Viewc7bc6b86}>
                                    <RadioButtonGroup
                                      onValueChange={newRadioButtonGroupValue => {
                                        try {
                                          setRadioButtonGroupValue(
                                            newRadioButtonGroupValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={
                                        styles(theme).RadioButtonGroup74763ff2
                                      }
                                      value={radioButtonGroupValue}
                                    >
                                      <RadioButtonRow
                                        label={'All'}
                                        value={'All'}
                                        color={theme.colors.primary}
                                        unselectedColor={theme.colors.primary}
                                        direction={'row'}
                                      />
                                      <RadioButtonRow
                                        label={'Scheduling'}
                                        value={'Scheduling'}
                                        color={theme.colors.primary}
                                        unselectedColor={theme.colors.primary}
                                        direction={'row'}
                                      />
                                      <RadioButtonRow
                                        label={'Strategy/Concepts'}
                                        value={'Strategy/Concepts'}
                                        color={theme.colors.primary}
                                        unselectedColor={theme.colors.primary}
                                      />
                                      <RadioButtonRow
                                        label={'Motivation/Leadership'}
                                        value={'Motivation/Leadership'}
                                        color={theme.colors.primary}
                                        unselectedColor={theme.colors.primary}
                                      />
                                      <RadioButtonRow
                                        label={'Recruiting'}
                                        value={'Recruiting'}
                                        color={theme.colors.primary}
                                        unselectedColor={theme.colors.primary}
                                      />
                                      <RadioButtonRow
                                        label={'Player Development'}
                                        value={'Player Development'}
                                        color={theme.colors.primary}
                                        unselectedColor={theme.colors.primary}
                                      />
                                      <RadioButtonRow
                                        label={'Jobs'}
                                        value={'Jobs'}
                                        color={theme.colors.primary}
                                        unselectedColor={theme.colors.primary}
                                      />
                                    </RadioButtonGroup>
                                    {/* Button Solid */}
                                    <Button
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            setSelectedCategory(
                                              radioButtonGroupValue
                                            );
                                            await refetchGetFeedWithFilter();
                                            setIsFilterModal(false);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      style={styles(theme).Button80c1eba2}
                                      title={'Filter'}
                                    />
                                  </View>
                                </Modal>
                              )}
                            </>
                            {/* newsFeedList */}
                            <FlatList
                              data={feedFetchData?.items}
                              listKey={'YcGlM0Hp'}
                              keyExtractor={newsFeedListData =>
                                newsFeedListData?.id ||
                                newsFeedListData?.uuid ||
                                JSON.stringify(newsFeedListData)
                              }
                              renderItem={({ item }) => {
                                const newsFeedListData = item;
                                return (
                                  <>
                                    {/* Post Touchable */}
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'PostDetailsScreen',
                                            {
                                              feed_post_id:
                                                newsFeedListData?.id,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={styles(theme).Touchabledf29e2e2}
                                    >
                                      {/* NewFeedCard */}
                                      <Surface
                                        style={styles(theme).Surface07fe4853}
                                        elevation={1}
                                      >
                                        {/* PostHeader */}
                                        <View
                                          style={styles(theme).View7963ad69}
                                        >
                                          {/* LeftAligned */}
                                          <View
                                            style={styles(theme).Viewce4accf0}
                                          >
                                            <Touchable
                                              onPress={() => {
                                                try {
                                                  navigation.navigate(
                                                    'MemberDetailScreen',
                                                    {
                                                      navigate_user_Id:
                                                        newsFeedListData?.author
                                                          ?.id,
                                                    }
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              {/* Light BG */}
                                              <View
                                                style={
                                                  styles(theme).Viewff6e3680
                                                }
                                              >
                                                <CircleImage
                                                  source={{
                                                    uri: `${newsFeedListData?.author?.profile_image?.url}`,
                                                  }}
                                                  size={50}
                                                />
                                              </View>
                                            </Touchable>

                                            <View
                                              style={styles(theme).View48c1b760}
                                            >
                                              {/* Coach Name */}
                                              <Text
                                                style={
                                                  styles(theme).Textbba75e6f
                                                }
                                              >
                                                {newsFeedListData?.author?.name}
                                              </Text>
                                              {/* Coach Title */}
                                              <>
                                                {!'author.position' ? null : (
                                                  <Text
                                                    style={
                                                      styles(theme).Text3d1f55dd
                                                    }
                                                  >
                                                    {
                                                      newsFeedListData?.author
                                                        ?.job_title
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                            </View>
                                          </View>
                                          {/* RightAligned */}
                                          <View
                                            style={styles(theme).View967fd4f5}
                                          >
                                            <>
                                              {newsFeedListData?.isMine ? null : (
                                                <View>
                                                  <>
                                                    {Constants[
                                                      'is_loading'
                                                    ] ? null : (
                                                      <View
                                                        style={
                                                          styles(theme)
                                                            .View3b52982a
                                                        }
                                                      >
                                                        {/* Button Outline */}
                                                        <>
                                                          {!newsFeedListData?.isFollowed ? null : (
                                                            <Button
                                                              onPress={() => {
                                                                const handler =
                                                                  async () => {
                                                                    try {
                                                                      setGlobalVariableValue(
                                                                        {
                                                                          key: 'is_loading',
                                                                          value: true,
                                                                        }
                                                                      );
                                                                      await risingCoachesUnfollowUserDELETE.mutateAsync(
                                                                        {
                                                                          followed_id:
                                                                            newsFeedListData
                                                                              ?.author
                                                                              ?.id,
                                                                        }
                                                                      );
                                                                      await refetchGetFeedWithFilter();
                                                                      setGlobalVariableValue(
                                                                        {
                                                                          key: 'is_loading',
                                                                          value: false,
                                                                        }
                                                                      );
                                                                    } catch (err) {
                                                                      console.error(
                                                                        err
                                                                      );
                                                                    }
                                                                  };
                                                                handler();
                                                              }}
                                                              style={
                                                                styles(theme)
                                                                  .Button332d1d98
                                                              }
                                                              title={
                                                                'Following'
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                        {/* Button Solid */}
                                                        <>
                                                          {newsFeedListData?.isFollowed ? null : (
                                                            <Button
                                                              onPress={() => {
                                                                const handler =
                                                                  async () => {
                                                                    try {
                                                                      setGlobalVariableValue(
                                                                        {
                                                                          key: 'is_loading',
                                                                          value: true,
                                                                        }
                                                                      );
                                                                      await risingCoachesFollowUserPOST.mutateAsync(
                                                                        {
                                                                          followed_id:
                                                                            newsFeedListData
                                                                              ?.author
                                                                              ?.id,
                                                                        }
                                                                      );
                                                                      await refetchGetFeedWithFilter();
                                                                      setGlobalVariableValue(
                                                                        {
                                                                          key: 'is_loading',
                                                                          value: false,
                                                                        }
                                                                      );
                                                                    } catch (err) {
                                                                      console.error(
                                                                        err
                                                                      );
                                                                    }
                                                                  };
                                                                handler();
                                                              }}
                                                              style={
                                                                styles(theme)
                                                                  .Button35fe6c70
                                                              }
                                                              title={'Follow'}
                                                            />
                                                          )}
                                                        </>
                                                      </View>
                                                    )}
                                                  </>
                                                  {/* Button Loading */}
                                                  <>
                                                    {!Constants[
                                                      'is_loading'
                                                    ] ? null : (
                                                      <Button
                                                        style={
                                                          styles(theme)
                                                            .Button35fe6c70
                                                        }
                                                        loading={true}
                                                        disabled={true}
                                                        title={''}
                                                      />
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                            {/* all Setting Button */}
                                            <>
                                              {newsFeedListData?.isMine ? null : (
                                                <IconButton
                                                  onPress={() => {
                                                    try {
                                                      setSelectedPost(
                                                        newsFeedListData?.id
                                                      );
                                                      setIsSettings(true);

                                                      const valueycjVSII7 =
                                                        newsFeedListData;
                                                      setSelectedFeedPost(
                                                        valueycjVSII7
                                                      );
                                                      const selectedFeedObject =
                                                        valueycjVSII7;
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  icon={
                                                    'MaterialCommunityIcons/dots-horizontal-circle'
                                                  }
                                                  size={32}
                                                  color={theme.colors.secondary}
                                                />
                                              )}
                                            </>
                                            {/* isMine Setting Button */}
                                            <>
                                              {!newsFeedListData?.isMine ? null : (
                                                <IconButton
                                                  onPress={() => {
                                                    try {
                                                      setSelectedPost(
                                                        newsFeedListData?.id
                                                      );
                                                      setIsMineSettings(true);

                                                      const valueClg5JmCa =
                                                        newsFeedListData;
                                                      setSelectedFeedPost(
                                                        valueClg5JmCa
                                                      );
                                                      const selectedFeedObject =
                                                        valueClg5JmCa;
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  size={32}
                                                  icon={
                                                    'MaterialCommunityIcons/dots-horizontal-circle'
                                                  }
                                                  color={theme.colors.secondary}
                                                />
                                              )}
                                            </>
                                          </View>
                                        </View>
                                        {/* Description */}
                                        <View
                                          style={styles(theme).View02d962cd}
                                        >
                                          <Text
                                            style={styles(theme).Text4fcd48ae}
                                          >
                                            {newsFeedListData?.content_title}
                                          </Text>
                                        </View>
                                        {/* Description */}
                                        <View
                                          style={styles(theme).Viewd6931f8d}
                                        >
                                          <Text
                                            style={styles(theme).Text82b6dc17}
                                          >
                                            {newsFeedListData?.text_content}
                                          </Text>
                                        </View>
                                        {/* FeaturedImageTag */}
                                        <View
                                          style={styles(theme).View1760c769}
                                        >
                                          <>
                                            {!newsFeedListData?.isFeatured ? null : (
                                              <View
                                                style={
                                                  styles(theme).View18b201ee
                                                }
                                              >
                                                <View
                                                  style={
                                                    styles(theme).Viewc992f941
                                                  }
                                                >
                                                  <Text
                                                    style={
                                                      styles(theme).Text9dc707f6
                                                    }
                                                  >
                                                    {'Featured'}
                                                  </Text>
                                                </View>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                        {/* SponsoredImageTag */}
                                        <View
                                          style={styles(theme).View1760c769}
                                        >
                                          <>
                                            {!newsFeedListData?.isSponsored ? null : (
                                              <View
                                                style={
                                                  styles(theme).Viewde26ea2b
                                                }
                                              >
                                                <View
                                                  style={
                                                    styles(theme).Viewc992f941
                                                  }
                                                >
                                                  <Text
                                                    style={
                                                      styles(theme).Text9dc707f6
                                                    }
                                                  >
                                                    {'Sponsored'}
                                                  </Text>
                                                </View>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                        {/* Content Image */}
                                        <>
                                          {!newsFeedListData?.isImage ? null : (
                                            <View
                                              style={styles(theme).View4849e8f4}
                                            >
                                              <ImageBackground
                                                style={
                                                  styles(theme)
                                                    .ImageBackground708e07cf
                                                }
                                                resizeMode={'contain'}
                                                source={{
                                                  uri: `${newsFeedListData?.image?.url}`,
                                                }}
                                              >
                                                <View
                                                  style={
                                                    styles(theme).View323c3df1
                                                  }
                                                />
                                              </ImageBackground>
                                            </View>
                                          )}
                                        </>
                                        {/* Content Video */}
                                        <>
                                          {!newsFeedListData?.isVideo ? null : (
                                            <View
                                              style={styles(theme).Viewf5230af4}
                                            >
                                              <View
                                                style={
                                                  styles(theme).View6248e6e5
                                                }
                                              />
                                              <WebView
                                                style={
                                                  styles(theme).WebView5b46b428
                                                }
                                                source={{
                                                  uri: `${newsFeedListData?.youtube_render_link}`,
                                                }}
                                              />
                                            </View>
                                          )}
                                        </>
                                        {/* SponsoredFileTag */}
                                        <>
                                          {!newsFeedListData?.isFile ? null : (
                                            <View
                                              style={styles(theme).View5e5adc27}
                                            >
                                              <>
                                                {!newsFeedListData?.isSponsored ? null : (
                                                  <View
                                                    style={
                                                      styles(theme).Viewde26ea2b
                                                    }
                                                  >
                                                    <View
                                                      style={
                                                        styles(theme)
                                                          .Viewc992f941
                                                      }
                                                    >
                                                      <Text
                                                        style={
                                                          styles(theme)
                                                            .Text9dc707f6
                                                        }
                                                      >
                                                        {'Sponsored'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </View>
                                          )}
                                        </>
                                        {/* Content File */}
                                        <>
                                          {!newsFeedListData?.isFile ? null : (
                                            <View
                                              style={styles(theme).View7237486d}
                                            >
                                              <WebView
                                                style={
                                                  styles(theme).WebView4a0d7678
                                                }
                                                source={{
                                                  uri: `${newsFeedListData?.file?.url}`,
                                                }}
                                              />
                                            </View>
                                          )}
                                        </>
                                        {/* CommentView */}
                                        <View
                                          style={styles(theme).View2b6258ba}
                                        >
                                          <FlatList
                                            data={
                                              newsFeedListData
                                                ?.last_two_comments?.items
                                            }
                                            listKey={JSON.stringify(
                                              newsFeedListData
                                                ?.last_two_comments?.items
                                            )}
                                            keyExtractor={listData =>
                                              listData?.id ||
                                              listData?.uuid ||
                                              JSON.stringify(listData)
                                            }
                                            renderItem={({ item }) => {
                                              const listData = item;
                                              return (
                                                <>
                                                  <Divider
                                                    style={
                                                      styles(theme)
                                                        .Divider192bcda6
                                                    }
                                                    color={theme.colors.divider}
                                                  />
                                                  {/* CommentView */}
                                                  <View
                                                    style={
                                                      styles(theme).View612957e4
                                                    }
                                                  >
                                                    {/* Light BG */}
                                                    <View
                                                      style={
                                                        styles(theme)
                                                          .Viewff672992
                                                      }
                                                    >
                                                      <CircleImage
                                                        size={30}
                                                        source={{
                                                          uri: `${getImageUrl(
                                                            listData
                                                              ?.comment_author
                                                              ?.profile_image
                                                          )}`,
                                                        }}
                                                      />
                                                    </View>

                                                    <View
                                                      style={
                                                        styles(theme)
                                                          .Viewa548e856
                                                      }
                                                    >
                                                      {/* CommentorName */}
                                                      <Text
                                                        style={
                                                          styles(theme)
                                                            .Text20cad67c
                                                        }
                                                      >
                                                        {
                                                          listData
                                                            ?.comment_author
                                                            ?.name
                                                        }
                                                      </Text>

                                                      <Text
                                                        style={
                                                          styles(theme)
                                                            .Text0ffa4cd0
                                                        }
                                                      >
                                                        {listData?.comment_text}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </>
                                              );
                                            }}
                                            style={
                                              styles(theme).FlatListdf29e2e2
                                            }
                                            contentContainerStyle={
                                              styles(theme)
                                                .FlatListc992f941Content
                                            }
                                            numColumns={1}
                                          />
                                        </View>
                                        {/* Engagement */}
                                        <View
                                          style={styles(theme).Viewb7197252}
                                        >
                                          {/* LeftAligned */}
                                          <View
                                            style={styles(theme).Viewdebd3207}
                                          >
                                            {/* Loading Likes */}
                                            <>
                                              {!Constants[
                                                'is_loading'
                                              ] ? null : (
                                                <View
                                                  style={
                                                    styles(theme).Viewdebd3207
                                                  }
                                                >
                                                  <Touchable>
                                                    <Icon
                                                      style={
                                                        styles(theme)
                                                          .Icon2cd37c54
                                                      }
                                                      color={theme.colors.error}
                                                      name={'AntDesign/sync'}
                                                      size={20}
                                                    />
                                                  </Touchable>

                                                  <Text
                                                    style={
                                                      styles(theme).Texte9f9757c
                                                    }
                                                  >
                                                    {'-'}
                                                  </Text>
                                                </View>
                                              )}
                                            </>
                                            {/* Likes */}
                                            <>
                                              {Constants[
                                                'is_loading'
                                              ] ? null : (
                                                <View
                                                  style={
                                                    styles(theme).Viewdebd3207
                                                  }
                                                >
                                                  <>
                                                    {newsFeedListData?.liked ? null : (
                                                      <Touchable
                                                        onPress={() => {
                                                          const handler =
                                                            async () => {
                                                              try {
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: true,
                                                                  }
                                                                );
                                                                await risingCoachesAddLikePOST.mutateAsync(
                                                                  {
                                                                    feed_post_id:
                                                                      newsFeedListData?.id,
                                                                  }
                                                                );
                                                                await refetchGetFeedWithFilter();
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: false,
                                                                  }
                                                                );
                                                              } catch (err) {
                                                                console.error(
                                                                  err
                                                                );
                                                              }
                                                            };
                                                          handler();
                                                        }}
                                                      >
                                                        <>
                                                          {newsFeedListData?.liked ? null : (
                                                            <Icon
                                                              style={
                                                                styles(theme)
                                                                  .Icon2cd37c54
                                                              }
                                                              size={20}
                                                              name={
                                                                'AntDesign/hearto'
                                                              }
                                                              color={
                                                                theme.colors
                                                                  .error
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                      </Touchable>
                                                    )}
                                                  </>
                                                  <>
                                                    {!newsFeedListData?.liked ? null : (
                                                      <Touchable
                                                        onPress={() => {
                                                          const handler =
                                                            async () => {
                                                              try {
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: true,
                                                                  }
                                                                );
                                                                await risingCoachesRemoveLikeDELETE.mutateAsync(
                                                                  {
                                                                    post_id:
                                                                      newsFeedListData?.id,
                                                                  }
                                                                );
                                                                await refetchGetFeedWithFilter();
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: false,
                                                                  }
                                                                );
                                                              } catch (err) {
                                                                console.error(
                                                                  err
                                                                );
                                                              }
                                                            };
                                                          handler();
                                                        }}
                                                      >
                                                        <>
                                                          {!newsFeedListData?.liked ? null : (
                                                            <Icon
                                                              style={
                                                                styles(theme)
                                                                  .Icon2cd37c54
                                                              }
                                                              name={
                                                                'AntDesign/heart'
                                                              }
                                                              size={20}
                                                              color={
                                                                theme.colors
                                                                  .error
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                      </Touchable>
                                                    )}
                                                  </>
                                                  <Text
                                                    style={
                                                      styles(theme).Texte9f9757c
                                                    }
                                                  >
                                                    {
                                                      newsFeedListData?.like_count
                                                    }
                                                  </Text>
                                                </View>
                                              )}
                                            </>
                                            {/* Comments */}
                                            <View
                                              style={styles(theme).Viewdebd3207}
                                            >
                                              <Icon
                                                style={
                                                  styles(theme).Icon2cd37c54
                                                }
                                                size={20}
                                                name={
                                                  'Ionicons/chatbox-ellipses'
                                                }
                                                color={theme.colors.medium}
                                              />
                                              <Text
                                                style={
                                                  styles(theme).Texte9f9757c
                                                }
                                              >
                                                {
                                                  newsFeedListData?.number_of_comments_of_news_feed_posts
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                          {/* Right Aligned */}
                                          <View
                                            style={styles(theme).Viewa81770e9}
                                          >
                                            <Text
                                              style={styles(theme).Text00433c9a}
                                            >
                                              {convertDate(
                                                newsFeedListData?.created_at
                                              )}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* See More */}
                                        <View>
                                          {/* Make a Comment */}
                                          <Text
                                            style={styles(theme).Text5eb21b5e}
                                          >
                                            {'See more'}
                                          </Text>
                                        </View>
                                        {/* SponsoredFileTag */}
                                        <>
                                          {!newsFeedListData?.isFile ? null : (
                                            <View
                                              style={styles(theme).View5e5adc27}
                                            >
                                              <>
                                                {!newsFeedListData?.isSponsored ? null : (
                                                  <View
                                                    style={
                                                      styles(theme).Viewde26ea2b
                                                    }
                                                  >
                                                    <View
                                                      style={
                                                        styles(theme)
                                                          .Viewc992f941
                                                      }
                                                    >
                                                      <Text
                                                        style={
                                                          styles(theme)
                                                            .Text9dc707f6
                                                        }
                                                      >
                                                        {'Sponsored'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </View>
                                          )}
                                        </>
                                      </Surface>
                                    </Touchable>
                                  </>
                                );
                              }}
                              style={styles(theme).FlatListdf29e2e2}
                              contentContainerStyle={
                                styles(theme).FlatListda3b4048Content
                              }
                              numColumns={1}
                              horizontal={false}
                            />
                          </>
                        );
                      }}
                    </RisingCoachesApi.FetchGetFeedWithFilterGET>
                  </KeyboardAwareScrollView>
                </View>
              </View>
              <>
                {!isFolderModal ? null : (
                  <Modal
                    visible={isFolderModal}
                    presentationStyle={'overFullScreen'}
                    animationType={'slide'}
                  >
                    <View style={styles(theme).View64fcd6ea}>
                      <IconButton
                        onPress={() => {
                          try {
                            setIsFolderModal(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                        icon={'AntDesign/closecircleo'}
                        color={theme.colors.error}
                      />
                    </View>

                    <View style={styles(theme).Viewc53c672f}>
                      <Text style={styles(theme).Text46b70f57}>
                        {'Select Which Folder to Save Post To'}
                      </Text>

                      <ScrollView
                        style={styles(theme).ScrollView989db244}
                        contentContainerStyle={
                          styles(theme).ScrollViewb1683a67Content
                        }
                        showsVerticalScrollIndicator={true}
                        bounces={true}
                      >
                        {/* Folder Fetch */}
                        <RisingCoachesApi.FetchGetUserFoldersGET>
                          {({
                            loading,
                            error,
                            data,
                            refetchGetUserFolders,
                          }) => {
                            const folderFetchData = data;
                            if (!folderFetchData || loading) {
                              return <ActivityIndicator />;
                            }

                            if (error) {
                              return (
                                <Text style={{ textAlign: 'center' }}>
                                  There was a problem fetching this data
                                </Text>
                              );
                            }

                            return (
                              <>
                                <FlatList
                                  data={folderFetchData}
                                  listKey={'Nyk2D0xp'}
                                  keyExtractor={listData =>
                                    listData?.id ||
                                    listData?.uuid ||
                                    JSON.stringify(listData)
                                  }
                                  renderItem={({ item }) => {
                                    const listData = item;
                                    return (
                                      <View style={styles(theme).View28f9c811}>
                                        {/* Button Outline */}
                                        <Button
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                await risingCoachesSaveFeedToFolderPOST.mutateAsync(
                                                  {
                                                    content:
                                                      selectedFeedPost?.image,
                                                    content_title:
                                                      selectedFeedPost?.content_title,
                                                    file_content:
                                                      selectedFeedPost?.file,
                                                    folder_id: listData?.id,
                                                    isFile:
                                                      selectedFeedPost?.isFile,
                                                    isImage:
                                                      selectedFeedPost?.isImage,
                                                    isShared:
                                                      selectedFeedPost?.isShared,
                                                    isVideo:
                                                      selectedFeedPost?.isVideo,
                                                    tags: selectedFeedPost?.tags,
                                                    text_content:
                                                      selectedFeedPost?.text_content,
                                                    uploader_id:
                                                      selectedFeedPost?.author_user_id,
                                                    youtubeId:
                                                      selectedFeedPost?.youtubeId,
                                                  }
                                                );
                                                setIsFolderModal(false);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          style={styles(theme).Buttona76b22b6}
                                          title={`${listData?.name}`}
                                        />
                                      </View>
                                    );
                                  }}
                                  style={styles(theme).FlatList989db244}
                                  contentContainerStyle={
                                    styles(theme).FlatListc992f941Content
                                  }
                                  numColumns={1}
                                />
                                <>
                                  {!isEmpty(folderFetchData) ? null : (
                                    <View style={styles(theme).View2dda0b3c}>
                                      <Text style={styles(theme).Texte9f9757c}>
                                        {'You have not created a folder yet. '}
                                      </Text>
                                      {/* Button Outline */}
                                      <Button
                                        onPress={() => {
                                          try {
                                            setIsFolderModal(false);
                                            navigation.navigate(
                                              'ContentRepositoryScreen',
                                              { nav_show_modal: true }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={styles(theme).Buttone68dd1ee}
                                        title={'Create Folder'}
                                      />
                                    </View>
                                  )}
                                </>
                              </>
                            );
                          }}
                        </RisingCoachesApi.FetchGetUserFoldersGET>
                      </ScrollView>
                    </View>
                  </Modal>
                )}
              </>
              {/* Scoreboard Filter Modal */}
              <>
                {!isScoreFilter ? null : (
                  <Modal visible={isScoreFilter} animationType={'none'}>
                    <View style={styles(theme).Viewc7bc6b86}>
                      <RadioButtonGroup
                        onValueChange={newRadioButtonGroupValue => {
                          try {
                            setRadioButtonGroupValue(newRadioButtonGroupValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).RadioButtonGroup74763ff2}
                      >
                        <RadioButtonRow
                          label={'NCAAM'}
                          value={1}
                          color={theme.colors.primary}
                          unselectedColor={theme.colors.primary}
                          direction={'row'}
                        />
                        <RadioButtonRow
                          label={'NCAAW'}
                          value={2}
                          color={theme.colors.primary}
                          unselectedColor={theme.colors.primary}
                          direction={'row'}
                        />
                        <RadioButtonRow
                          label={'NBA'}
                          value={20}
                          color={theme.colors.primary}
                          unselectedColor={theme.colors.primary}
                        />
                        <RadioButtonRow
                          label={'WNBA'}
                          value={10}
                          color={theme.colors.primary}
                          unselectedColor={theme.colors.primary}
                        />
                        <RadioButtonRow
                          label={'G League'}
                          value={21}
                          color={theme.colors.primary}
                          unselectedColor={theme.colors.primary}
                        />
                      </RadioButtonGroup>
                      {/* Button Solid */}
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setLeague_id(radioButtonGroupValue);
                              changeScoreTag(radioButtonGroupValue);
                              setIsScoreFilter(false);
                              await refetchGetScores();
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={styles(theme).Button80c1eba2}
                        title={'Filter'}
                      />
                    </View>
                  </Modal>
                )}
              </>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetStoriesGET>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    ActionSheetItemc521608b: { textAlign: 'center' },
    Button0e987294: {
      backgroundColor: 'transparent',
      borderRadius: 8,
      borderWidth: 1,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      textAlign: 'center',
    },
    Button332d1d98: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    Button35fe6c70: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontSize: 12,
      textAlign: 'center',
    },
    Button80c1eba2: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginLeft: 30,
      marginRight: 30,
      textAlign: 'center',
    },
    Buttona76b22b6: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Buttone68dd1ee: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginTop: 20,
      textAlign: 'center',
    },
    Buttonf7e16baa: {
      backgroundColor: theme.colors.secondary,
      borderRadius: 8,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    CircleImagee0bcd704: { height: 20, width: 20 },
    Divider192bcda6: { height: 1, marginTop: 10, width: '100%' },
    Dividerde11d607: { height: 1 },
    Fetch431eb058: { minHeight: 40 },
    FlatList2fbd360fContent: { flex: 1, marginLeft: 10, marginRight: 10 },
    FlatList55a10db3Content: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    FlatListc992f941Content: { flex: 1 },
    FlatListda3b4048Content: {
      alignContent: 'center',
      alignSelf: 'stretch',
      flex: 1,
    },
    FlatListdf29e2e2: { width: '100%' },
    Icon2cd37c54: { marginLeft: 10, marginRight: 5 },
    Image056264fa: { height: 88, width: 100 },
    Image08e5e149: { height: 83, overflow: 'hidden', width: 100 },
    Image31d6c46d: { height: 25, marginLeft: 5, width: 25 },
    ImageBackground708e07cf: {
      height: '100%',
      maxHeight: 230,
      minWidth: 320,
      width: '100%',
    },
    RadioButtonGroup74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    ScrollView86250d6fContent: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    ScrollViewb1683a67Content: { justifyContent: 'space-around' },
    ScrollViewb284e5b0: { flexGrow: 1 },
    ScrollViewdebd3207Content: { flexDirection: 'row' },
    Surface07fe4853: {
      alignContent: 'stretch',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: theme.colors.surface,
      borderRadius: 20,
      justifyContent: 'center',
      marginBottom: 10,
      marginTop: 10,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
      width: '90%',
    },
    Surface3493a395: {
      backgroundColor: theme.colors.surface,
      flex: 2,
      height: '100%',
      position: 'absolute',
      top: 0,
      width: '80%',
      zIndex: 5,
    },
    Surface6822e2d0: {
      backgroundColor: theme.colors.primary,
      borderRadius: 6,
      height: 71,
      justifyContent: 'space-around',
      marginLeft: 15,
      minHeight: 40,
      paddingBottom: 5,
      paddingTop: 5,
      width: 71,
    },
    Surface94216a62: {
      alignItems: 'flex-start',
      backgroundColor: theme.colors.surface,
      borderRadius: 6,
      height: 71,
      justifyContent: 'space-around',
      minHeight: 40,
      width: 200,
    },
    Text00433c9a: { alignSelf: 'flex-end', color: theme.colors.strong },
    Text0d54818a: {
      alignSelf: 'center',
      color: theme.colors.strong,
      fontFamily: 'Manrope_400Regular',
      fontSize: 8,
      textAlign: 'center',
    },
    Text0ffa4cd0: { color: theme.colors.strong, textAlign: 'left' },
    Text1dae38a6: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontSize: 16,
      fontWeight: '400',
      marginLeft: 8,
    },
    Text20cad67c: {
      color: theme.colors.secondary,
      fontFamily: 'Roboto_700Bold',
      marginBottom: 2,
      marginTop: 5,
      textAlign: 'left',
    },
    Text2be49fc2: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_400Regular',
      fontSize: 9,
    },
    Text3060bf70: {
      alignSelf: 'center',
      color: theme.colors.surface,
      fontFamily: 'Roboto_700Bold',
      fontSize: 18,
    },
    Text3171c9b1: {
      color: theme.colors.surface,
      fontFamily: 'System',
      fontSize: 18,
      fontWeight: '400',
    },
    Text394e855d: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
      marginLeft: 5,
    },
    Text3d1f55dd: {
      color: theme.colors.light,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
    },
    Text4505c294: {
      color: theme.colors['Light'],
      fontFamily: 'Manrope_400Regular',
    },
    Text46b70f57: {
      color: theme.colors.strong,
      fontFamily: 'Mulish_700Bold',
      fontSize: 16,
    },
    Text4fcd48ae: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_600SemiBold',
    },
    Text5eb21b5e: {
      color: theme.colors.primary,
      fontFamily: 'Roboto_500Medium',
      fontSize: 12,
      marginTop: 10,
      textAlign: 'left',
    },
    Text5fe97391: {
      alignSelf: 'center',
      color: theme.colors.surface,
      fontFamily: 'Roboto_500Medium',
      fontSize: 16,
    },
    Text82b6dc17: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_400Regular',
    },
    Text9c03629e: {
      color: theme.colors.custom_rgb255_255_255,
      flex: 1,
      fontFamily: 'System',
      fontSize: 20,
      fontWeight: '600',
      marginLeft: 6,
    },
    Text9dc707f6: {
      alignSelf: 'center',
      color: theme.colors.surface,
      fontFamily: 'Poppins_500Medium',
      fontSize: 16,
      textAlign: 'center',
    },
    Textb17d301e: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    Textbba75e6f: {
      color: theme.colors.medium,
      fontFamily: 'Roboto_700Bold',
      fontSize: 16,
      marginBottom: 2,
    },
    Textde3925a8: { color: theme.colors.medium, marginLeft: 5 },
    Texte86f7e03: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      fontSize: 12,
      marginBottom: 5,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 10,
    },
    Texte9f9757c: { color: theme.colors.strong },
    Textfa827c01: { color: theme.colors.strong, marginLeft: 5 },
    Touchablece85ea54: {
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
    },
    Touchabledf29e2e2: { width: '100%' },
    View02d962cd: {
      alignContent: 'stretch',
      alignSelf: 'flex-start',
      marginBottom: 3,
      marginTop: 3,
    },
    View04e5be62: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginBottom: 10,
      marginLeft: 5,
      marginRight: 5,
      marginTop: 10,
    },
    View0e0c9f4d: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    View10eb8625: {
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 10,
    },
    View1760c769: {
      alignItems: 'flex-end',
      justifyContent: 'center',
      paddingBottom: 3,
      width: '100%',
    },
    View18b201ee: {
      alignItems: 'center',
      backgroundColor: theme.colors['Secondary'],
      borderRadius: 8,
      flexDirection: 'row',
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 6,
    },
    View19b57072: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 15,
    },
    View1c24b933: { alignSelf: 'flex-end', marginLeft: 5 },
    View28f9c811: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 10,
      marginTop: 10,
    },
    View2b6258ba: { paddingBottom: 5, width: '100%' },
    View2dda0b3c: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 50,
    },
    View2f4270eb: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      maxWidth: 400,
      minWidth: 200,
    },
    View323c3df1: { maxWidth: '40%', minHeight: 50 },
    View3817e2ab: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginBottom: 10,
      marginTop: 5,
    },
    View3b52982a: { flexDirection: 'row', marginRight: 5 },
    View4849e8f4: {
      alignContent: 'center',
      alignSelf: 'stretch',
      borderRadius: 12,
      height: 230,
      justifyContent: 'space-around',
      marginBottom: 8,
      marginTop: 5,
      maxHeight: 800,
      maxWidth: '100%',
      minHeight: 230,
      minWidth: 280,
      overflow: 'hidden',
    },
    View48c1b760: { marginLeft: 10, maxWidth: 140 },
    View56fa1414: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      flex: 1,
    },
    View5e5adc27: {
      alignItems: 'center',
      bottom: 1,
      justifyContent: 'center',
      maxWidth: '40%',
      minHeight: 50,
      position: 'absolute',
      right: 3,
      width: '40%',
    },
    View612957e4: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      marginTop: 8,
    },
    View6248e6e5: {
      bottom: 1,
      maxWidth: '40%',
      minHeight: 50,
      position: 'absolute',
      right: 3,
    },
    View64fcd6ea: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 20,
      marginTop: 20,
    },
    View65b17fb5: { alignItems: 'center', flexDirection: 'row', marginTop: 5 },
    View70e3169e: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '40%',
    },
    View7237486d: {
      alignContent: 'center',
      alignSelf: 'stretch',
      borderRadius: 12,
      justifyContent: 'space-around',
      marginBottom: 8,
      marginTop: 5,
      maxHeight: 300,
      maxWidth: '100%',
      minHeight: 180,
      minWidth: 280,
      overflow: 'hidden',
    },
    View74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    View77b39bc7: {
      alignItems: 'center',
      backgroundColor: theme.colors.secondary,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 20,
      paddingRight: 20,
    },
    View7963ad69: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 3,
    },
    View7edaa38d: {
      alignItems: 'center',
      height: 20,
      justifyContent: 'center',
      marginBottom: 3,
      overflow: 'hidden',
      width: 110,
    },
    View81bf3e2f: {
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 15,
      marginTop: 12,
      maxWidth: 50,
    },
    View8878edf0: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 0,
    },
    View8d4fc31a: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 40,
    },
    View8e23439a: { flex: 1, marginLeft: 12 },
    View967fd4f5: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    View98b256f7: { backgroundColor: theme.colors.background, flex: 1 },
    Viewa2f3c3e0: {
      alignItems: 'center',
      flexDirection: 'row',
      paddingBottom: 12,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 12,
    },
    Viewa46b0be4: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 10,
      marginTop: 5,
    },
    Viewa548e856: { alignSelf: 'stretch', maxWidth: 270 },
    Viewa7cc7886: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginBottom: 10,
    },
    Viewa81770e9: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    Viewb579045b: {
      alignContent: 'center',
      alignSelf: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    Viewb7197252: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      marginTop: 3,
    },
    Viewb7a4585c: {
      alignContent: 'stretch',
      alignItems: 'flex-end',
      alignSelf: 'stretch',
      justifyContent: 'center',
    },
    Viewb990361f: {
      backgroundColor: theme.colors.secondary,
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 72,
    },
    Viewbf609868: { alignSelf: 'flex-start', marginLeft: 5 },
    Viewc53c672f: {
      alignContent: 'stretch',
      alignItems: 'center',
      alignSelf: 'stretch',
      justifyContent: 'space-around',
      marginTop: 100,
    },
    Viewc5ec2460: {
      alignItems: 'center',
      borderRadius: 12,
      height: 85,
      overflow: 'hidden',
      width: 110,
    },
    Viewc7bc6b86: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      justifyContent: 'space-around',
      marginTop: 100,
    },
    Viewc992f941: { flex: 1 },
    Viewce4accf0: { alignItems: 'center', flexDirection: 'row' },
    Viewd6931f8d: {
      alignContent: 'stretch',
      alignSelf: 'flex-start',
      marginBottom: 10,
      marginTop: 1,
    },
    Viewde26ea2b: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      flexDirection: 'row',
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 6,
    },
    Viewdebd3207: { flexDirection: 'row' },
    Viewf5230af4: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      borderRadius: 12,
      height: 165,
      justifyContent: 'space-around',
      marginBottom: 8,
      marginTop: 5,
      maxHeight: 165,
      maxWidth: '100%',
      minHeight: 165,
      minWidth: 280,
      overflow: 'hidden',
      width: '100%',
    },
    Viewf82d10d1: { flex: 1, paddingBottom: 16, paddingTop: 16 },
    Viewff672992: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 35,
      justifyContent: 'center',
      marginRight: 5,
      width: 35,
    },
    Viewff6e3680: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 65,
      justifyContent: 'center',
      width: 65,
    },
    WebView4a0d7678: { flex: 1, minHeight: '100%' },
    WebView5b46b428: { flex: 1, width: '80%' },
    screen: { backgroundColor: theme.colors.surface, flexDirection: 'row' },
  });

export default withTheme(NewsFeedScreen);
