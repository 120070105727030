import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { parseBoolean } from '../utils';
import {
  Button,
  CheckboxRow,
  DatePicker,
  ScreenContainer,
  Spacer,
  withTheme,
} from '@draftbit/ui';
import {
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';

const AddJobScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesAddJobToTimelinePOST =
    RisingCoachesApi.useAddJobToTimelinePOST();

  const [descriptionInput, setDescriptionInput] = React.useState('');
  const [endDateInput, setEndDateInput] = React.useState(new Date());
  const [isCurrentInput, setIsCurrentInput] = React.useState(false);
  const [organizationInput, setOrganizationInput] = React.useState('');
  const [titleInput, setTitleInput] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Content Avoiding View */}
      <KeyboardAvoidingView
        style={styles(theme).KeyboardAvoidingView42343016}
        keyboardVerticalOffset={60}
        enabled={true}
        behavior={'padding'}
      >
        {/* Header Wrapper */}
        <View style={styles(theme).View3bf8db95}>
          {/* Screen Title */}
          <Text style={styles(theme).Text61d3fddc}>
            {'Add a Job to your History'}
          </Text>
          {/* Screen Description */}
          <Text style={styles(theme).Textde6fc30a}>
            {'Tell us more about your role.'}
          </Text>
          {/* Organization */}
          <View>
            <Spacer top={12} right={8} bottom={12} left={8} />
            <Text style={styles(theme).Text1755c43e}>{'Organization'}</Text>
            <TextInput
              onChangeText={newTextInputValue => {
                try {
                  setOrganizationInput(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={styles(theme).TextInput251dd284}
              placeholder={'Organization...'}
            />
            <Spacer top={12} right={8} bottom={12} left={8} />
          </View>
          {/* Title */}
          <View>
            {/* Title */}
            <Text style={styles(theme).Text1755c43e}>{'Title'}</Text>
            {/* Title Input */}
            <TextInput
              onChangeText={newTitleInputValue => {
                try {
                  setTitleInput(newTitleInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={styles(theme).TextInput251dd284}
              placeholder={'Title...'}
            />
            <Spacer top={12} right={8} bottom={12} left={8} />
          </View>
          {/* Title */}
          <View>
            {/* Desc */}
            <Text style={styles(theme).Text1755c43e}>{'Description'}</Text>
            <TextInput
              onChangeText={newTextAreaValue => {
                try {
                  setDescriptionInput(newTextAreaValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={styles(theme).TextInput251dd284}
              placeholder={
                'Talk about the role your served in and what you were able to accomplish...'
              }
              numberOfLines={4}
              multiline={true}
            />
            <Spacer top={12} right={8} bottom={12} left={8} />
          </View>
        </View>

        <View style={styles(theme).View4cf123d7}>
          <DatePicker
            onDateChange={newDatePickerValue => {
              try {
                setEndDateInput(newDatePickerValue);
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles(theme).DatePicker5942f196}
            label={'End Date'}
            mode={'date'}
            leftIconMode={'inset'}
            type={'underline'}
          />
          <Spacer top={12} right={8} bottom={12} left={8} />
          <CheckboxRow
            onPress={newCheckboxRowValue => {
              try {
                setIsCurrentInput(newCheckboxRowValue);
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles(theme).CheckboxRow74faf84e}
            label={'This is my current role'}
          />
        </View>
        <Spacer top={12} right={8} bottom={12} left={8} />
        <Spacer top={12} right={8} bottom={12} left={8} />
        {/* Content Wrapper */}
        <View style={styles(theme).View8facfa50}>
          {/* Add Job */}
          <Button
            onPress={() => {
              const handler = async () => {
                try {
                  navigation.navigate('JobTimelineScreen');
                  await risingCoachesAddJobToTimelinePOST.mutateAsync({
                    end_date: new Date(endDateInput),
                    isCurrentJob: parseBoolean(isCurrentInput),
                    job_description: descriptionInput,
                    job_title: titleInput,
                    organization: organizationInput,
                    user_id: Constants['user_id'],
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={styles(theme).Button30c97cb8}
            type={'solid'}
            title={'Add'}
          >
            {'Submit'}
          </Button>
        </View>
        {/* Footer Wrapper */}
        <View />
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button30c97cb8: {
      color: theme.colors['Background'],
      height: 48,
      minWidth: 120,
      opacity: 1,
    },
    CheckboxRow74faf84e: { marginLeft: 30, marginRight: 30, minHeight: 50 },
    DatePicker5942f196: { marginLeft: 20, marginRight: 20 },
    KeyboardAvoidingView42343016: {
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    Text1755c43e: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_500Medium',
      marginBottom: 3,
      marginLeft: 20,
      marginRight: 20,
    },
    Text61d3fddc: {
      color: theme.colors.strong,
      textAlign: 'center',
      typography: theme.typography.headline4,
    },
    TextInput251dd284: {
      backgroundColor: theme.colors.surface,
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      marginLeft: 20,
      marginRight: 20,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Textde6fc30a: {
      color: theme.colors.medium,
      marginTop: 16,
      textAlign: 'center',
      typography: theme.typography.body1,
    },
    View3bf8db95: {
      flexGrow: 1,
      flexShrink: 0,
      marginLeft: 18,
      marginRight: 18,
      marginTop: 36,
    },
    View4cf123d7: { justifyContent: 'center', marginLeft: 20, marginRight: 20 },
    View8facfa50: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
      flexGrow: 1,
      flexShrink: 0,
      justifyContent: 'space-around',
      marginLeft: 18,
      marginRight: 18,
      paddingBottom: 24,
    },
  });

export default withTheme(AddJobScreen);
