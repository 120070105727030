import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const PostDetailsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj.url;
  };

  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const { theme } = props;

  const risingCoachesCreateCommentPOST =
    RisingCoachesApi.useCreateCommentPOST();

  const [new_comment, setNew_comment] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={false}
    >
      <RisingCoachesApi.FetchGetFeedPostGET
        method={'GET'}
        post_id={props.route?.params?.feed_post_id ?? 2}
      >
        {({ loading, error, data, refetchGetFeedPost }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <KeyboardAvoidingView enabled={true} behavior={'position'}>
              {/* File View */}
              <>
                {!fetchData?.isFile ? null : (
                  <View style={styles(theme).View70b89981}>
                    <WebView
                      style={styles(theme).WebViewc992f941}
                      source={{ uri: `${fetchData?.file?.url}` }}
                    />
                    <View style={styles(theme).View291ac3f1}>
                      <View style={styles(theme).Viewfa68522b}>
                        <View style={styles(theme).Viewc992f941}>
                          <Text style={styles(theme).Text8be633e3}>
                            {fetchData?.content_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
              {/* Video View */}
              <>
                {!fetchData?.isVideo ? null : (
                  <View style={styles(theme).View70b89981}>
                    <WebView
                      style={styles(theme).WebViewc992f941}
                      source={{ uri: `${fetchData?.youtube_render_link}` }}
                    />
                    <View style={styles(theme).View291ac3f1}>
                      <View style={styles(theme).Viewfa68522b}>
                        <View style={styles(theme).Viewc992f941}>
                          <Text style={styles(theme).Text8be633e3}>
                            {fetchData?.content_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
              {/* Image View */}
              <>
                {!fetchData?.isImage ? null : (
                  <View style={styles(theme).View70b89981}>
                    <ImageBackground
                      style={styles(theme).ImageBackground4ebf21b5}
                      source={{ uri: `${fetchData?.image?.url}` }}
                      resizeMode={'cover'}
                    >
                      <View style={styles(theme).View291ac3f1}>
                        <View style={styles(theme).Viewfa68522b}>
                          <View style={styles(theme).Viewc992f941}>
                            <Text style={styles(theme).Text8be633e3}>
                              {fetchData?.content_title}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </ImageBackground>
                  </View>
                )}
              </>
              {/* Text View */}
              <>
                {!fetchData?.isText ? null : (
                  <View style={styles(theme).Viewdf29e2e2}>
                    <View style={styles(theme).View291ac3f1}>
                      <View style={styles(theme).Viewfa68522b}>
                        <View style={styles(theme).Viewc992f941}>
                          <Text style={styles(theme).Text8be633e3}>
                            {fetchData?.content_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
              <View>
                <View style={styles(theme).Viewc1eab173}>
                  <Text style={styles(theme).Text00ef0353}>
                    {fetchData?.city}
                  </Text>

                  <Text
                    style={styles(theme).Text8cfc6364}
                    textBreakStrategy={'highQuality'}
                    ellipsizeMode={'tail'}
                    allowFontScaling={true}
                    numberOfLines={2}
                  >
                    {fetchData?.name}
                  </Text>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  <View>
                    <View style={styles(theme).Viewdebd3207}>
                      <View style={styles(theme).View11851b7e}>
                        <Icon
                          size={24}
                          color={theme.colors.primary}
                          name={'AntDesign/heart'}
                        />
                        <Spacer right={2} left={2} />
                        <Text style={styles(theme).Textde21574d}>
                          {fetchData?.like_count}
                        </Text>
                      </View>
                      <Spacer top={8} right={6} bottom={8} left={6} />
                      <View style={styles(theme).View11851b7e}>
                        <Icon
                          size={20}
                          color={theme.colors.primary}
                          name={'FontAwesome/commenting'}
                        />
                        <Spacer right={2} left={2} />
                        <Text style={styles(theme).Textde21574d}>
                          {fetchData?.number_of_comments_of_news_feed_posts}
                        </Text>
                      </View>
                      <Spacer top={8} right={6} bottom={8} left={6} />
                      <View style={styles(theme).View0efbb706}>
                        <Spacer right={2} left={2} />
                        <Text style={styles(theme).Textde21574d}>
                          {convertDate(fetchData?.created_at)}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <Spacer top={12} right={8} bottom={12} left={8} />
                  <View>
                    <Spacer top={6} right={8} bottom={6} left={8} />
                    <Text style={styles(theme).Textfe0e0a4d}>
                      {fetchData?.text_content}
                    </Text>
                  </View>
                  <Spacer top={12} right={8} bottom={12} left={8} />
                  {/* Comments */}
                  <View>
                    <Text style={styles(theme).Text3f9840b9}>{'Comments'}</Text>
                    <Spacer top={6} right={8} bottom={6} left={8} />
                    <View style={styles(theme).View88c44c3e}>
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setNew_comment(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInpute4874d73}
                        value={new_comment}
                        placeholder={'Leave a comment...'}
                        multiline={true}
                      />
                      {/* Button Solid */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: true,
                                  });
                                  setNew_comment('');
                                  await risingCoachesCreateCommentPOST.mutateAsync(
                                    {
                                      author_user_id: Constants['user_id'],
                                      comment_text: new_comment,
                                      feed_post_id:
                                        props.route?.params?.feed_post_id ?? 2,
                                    }
                                  );
                                  await refetchGetFeedPost();
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: false,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={styles(theme).Button8ce897e6}
                            title={'Submit'}
                          />
                        )}
                      </>
                      {/* Button Solid */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            style={styles(theme).Button7715656a}
                            title={''}
                            disabled={true}
                            loading={true}
                          />
                        )}
                      </>
                    </View>
                    <FlatList
                      data={
                        fetchData && fetchData['_comments_of_news_feed_posts']
                      }
                      listKey={'ac1YHAl5'}
                      keyExtractor={listData =>
                        listData?.id ||
                        listData?.uuid ||
                        JSON.stringify(listData)
                      }
                      renderItem={({ item }) => {
                        const listData = item;
                        return (
                          <>
                            <Divider
                              style={styles(theme).Divider192bcda6}
                              color={theme.colors.divider}
                            />
                            {/* CommentView */}
                            <View style={styles(theme).View612957e4}>
                              {/* Light BG */}
                              <View style={styles(theme).Viewff672992}>
                                <CircleImage
                                  source={{
                                    uri: `${listData?.commenting_user?.profile_image?.url}`,
                                  }}
                                  size={30}
                                />
                              </View>

                              <View style={styles(theme).View557e67a1}>
                                {/* CommentorName */}
                                <Text style={styles(theme).Text20cad67c}>
                                  {listData?.commenting_user?.name}
                                </Text>

                                <Text style={styles(theme).Texte9f9757c}>
                                  {listData?.comment_text}
                                </Text>
                              </View>
                            </View>
                          </>
                        );
                      }}
                      style={styles(theme).FlatListdf29e2e2}
                      contentContainerStyle={
                        styles(theme).FlatListc992f941Content
                      }
                      numColumns={1}
                    />
                    <Divider
                      style={styles(theme).Dividera72a5a5e}
                      color={theme.colors.lightest}
                    />
                  </View>
                  <Spacer top={16} right={8} bottom={16} left={8} />
                </View>
              </View>
            </KeyboardAvoidingView>
          );
        }}
      </RisingCoachesApi.FetchGetFeedPostGET>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button7715656a: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
      width: 80,
    },
    Button8ce897e6: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Divider192bcda6: { height: 1, marginTop: 10, width: '100%' },
    Dividera72a5a5e: { height: 1, marginBottom: 12, marginTop: 12 },
    FlatListc992f941Content: { flex: 1 },
    FlatListdf29e2e2: { width: '100%' },
    ImageBackground4ebf21b5: {
      height: '100%',
      justifyContent: 'flex-end',
      width: '100%',
    },
    Text00ef0353: {
      color: theme.colors.light,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
    },
    Text20cad67c: {
      color: theme.colors.secondary,
      fontFamily: 'Roboto_700Bold',
      marginBottom: 2,
      marginTop: 5,
      textAlign: 'left',
    },
    Text3f9840b9: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 16,
    },
    Text8be633e3: {
      color: theme.colors.surface,
      fontFamily: 'Poppins_500Medium',
      fontSize: 16,
    },
    Text8cfc6364: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 22,
    },
    TextInpute4874d73: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      width: '70%',
    },
    Textde21574d: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
    },
    Texte9f9757c: { color: theme.colors.strong },
    Textfe0e0a4d: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 14,
      lineHeight: 26,
      textAlign: 'left',
    },
    View0efbb706: {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View11851b7e: {
      alignItems: 'center',
      alignSelf: 'stretch',
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View291ac3f1: {
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    View557e67a1: { alignSelf: 'flex-start' },
    View612957e4: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      marginTop: 8,
    },
    View70b89981: { height: 420, width: '100%' },
    View88c44c3e: { flexDirection: 'row', justifyContent: 'space-between' },
    Viewc1eab173: {
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 0,
    },
    Viewc992f941: { flex: 1 },
    Viewdebd3207: { flexDirection: 'row' },
    Viewdf29e2e2: { width: '100%' },
    Viewfa68522b: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      flexDirection: 'row',
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 8,
      paddingTop: 6,
    },
    Viewff672992: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 35,
      justifyContent: 'center',
      marginRight: 5,
      width: 35,
    },
    WebViewc992f941: { flex: 1 },
  });

export default withTheme(PostDetailsScreen);
