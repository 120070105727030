// This import is required if you are defining react components in this module.
import React, { useState } from 'react';
import { Text, View } from 'react-native';
import * as GlobalVariableContext from '../config/GlobalVariableContext';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

export const GooglePlacesInput = ({
  geo,
  setGeo,
  isSetLocation,
  setIsSetLocation,
  address,
  setAddress,
}) => {
  const [locationPoint, setLocationPoint] = useState();

  return (
    <GooglePlacesAutocomplete
      style={{ color: 'black' }}
      placeholder="Search location"
      fetchDetails={true}
      onPress={(data, details = null) => {
        // 'details' is provided when fetchDetails = true
        setGeo(
          {
            type: 'point',
            data: {
              lng: details.geometry.location.lng,
              lat: details.geometry.location.lat,
            },
          },
          setIsSetLocation(false),
          setAddress(data.description)
        );
        // setGlobalVariable({
        //   key: 'map_search',
        //   value: {
        //     type: 'point',
        //     data: {
        //       lng: details.geometry.location.lng,
        //       lat: details.geometry.location.lat,
        //     },
        //   },
        // });
      }}
      query={{
        key: 'AIzaSyC1wlrq6ycPOQSRNoeqToId8VWdDuvh4vY',
        language: 'en',
      }}
      requestUrl={{
        useOnPlatform: 'web', // or "all"
        url: 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
      }}
    />
  );
};
