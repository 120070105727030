import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import formatDate from '../global-functions/formatDate';
import isEmpty from '../global-functions/isEmpty';
import {
  CircleImage,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const NotificationsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesDeleteNotificationDELETE =
    RisingCoachesApi.useDeleteNotificationDELETE();

  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [menuTab1, setMenuTab1] = React.useState(true);
  const [menuTab2, setMenuTab2] = React.useState(false);
  const [menuTab3, setMenuTab3] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Scroll Content Frame */}
      <ScrollView
        style={styles(theme).ScrollViewb284e5b0}
        contentContainerStyle={styles(theme).ScrollView4e34f380Content}
        showsVerticalScrollIndicator={true}
        bounces={true}
      >
        {/* Following List Frame */}
        <View style={styles(theme).Viewf8304bf6}>
          {/* List Content Frame */}
          <View>
            <RisingCoachesApi.FetchGetNotificationsGET>
              {({ loading, error, data, refetchGetNotifications }) => {
                const fetchData = data;
                if (!fetchData || loading) {
                  return <ActivityIndicator />;
                }

                if (error) {
                  return (
                    <Text style={{ textAlign: 'center' }}>
                      There was a problem fetching this data
                    </Text>
                  );
                }

                return (
                  <>
                    <>
                      {!isEmpty(fetchData?.itemsReceived) ? null : (
                        <View style={styles(theme).View8c951c00}>
                          <Text style={styles(theme).Texta6b8270e}>
                            {"You don't have any notifications right now."}
                          </Text>
                        </View>
                      )}
                    </>
                    <FlatList
                      data={fetchData?.items}
                      listKey={'3atyFvUw'}
                      keyExtractor={listData => listData?.id}
                      renderItem={({ item }) => {
                        const listData = item;
                        return (
                          <>
                            {/* isComment Frame */}
                            <>
                              {!listData?.isComment ? null : (
                                <View style={styles(theme).Viewf8304bf6}>
                                  {/* Flex Touchable */}
                                  <View style={styles(theme).Viewf1746056}>
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'PostDetailsScreen',
                                              {
                                                feed_post_id:
                                                  listData?.content_reference_id,
                                              }
                                            );
                                            await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                              { notifications_id: listData?.id }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View style={styles(theme).Viewb2cd8b01}>
                                        {/* Left Side Frame */}
                                        <View
                                          style={styles(theme).Viewe09f063e}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={styles(theme).Viewb2425505}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={styles(theme).View09162134}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              style={styles(theme).Texta10a092d}
                                              ellipsizeMode={'tail'}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={styles(theme).Viewb143a85e}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={styles(theme).Texte88e1c80}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={styles(theme).View0aa36c3c}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={styles(theme).Text49682267}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={styles(theme).Viewa8a94d9a}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                    {
                                                      notifications_id:
                                                        listData?.id,
                                                    }
                                                  );
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={styles(theme).Viewa8da3065}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={
                                                  styles(theme).Viewfe2670fd
                                                }
                                              >
                                                <Icon
                                                  name={'MaterialIcons/clear'}
                                                  color={theme.colors.medium}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isLiked */}
                            <>
                              {!listData?.isLike ? null : (
                                <View style={styles(theme).Viewf8304bf6}>
                                  {/* Flex Touchable */}
                                  <View style={styles(theme).Viewf1746056}>
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'PostDetailsScreen',
                                              {
                                                feed_post_id:
                                                  listData?.content_reference_id,
                                              }
                                            );
                                            await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                              { notifications_id: listData?.id }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View style={styles(theme).Viewb2cd8b01}>
                                        {/* Left Side Frame */}
                                        <View
                                          style={styles(theme).Viewe09f063e}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                            size={64}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={styles(theme).Viewb2425505}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={styles(theme).View09162134}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              style={styles(theme).Texta10a092d}
                                              ellipsizeMode={'tail'}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={styles(theme).Viewb143a85e}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={styles(theme).Texte88e1c80}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={styles(theme).View0aa36c3c}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={styles(theme).Text49682267}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={styles(theme).Viewa8a94d9a}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                    {
                                                      notifications_id:
                                                        listData?.id,
                                                    }
                                                  );
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={styles(theme).Viewa8da3065}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={
                                                  styles(theme).Viewfe2670fd
                                                }
                                              >
                                                <Icon
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                  color={theme.colors.medium}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isNewPost */}
                            <>
                              {!listData?.isNewPost ? null : (
                                <View style={styles(theme).Viewf8304bf6}>
                                  {/* Flex Touchable */}
                                  <View style={styles(theme).Viewf1746056}>
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'PostDetailsScreen',
                                              {
                                                feed_post_id:
                                                  listData?.content_reference_id,
                                              }
                                            );
                                            await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                              { notifications_id: listData?.id }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View style={styles(theme).Viewb2cd8b01}>
                                        {/* Left Side Frame */}
                                        <View
                                          style={styles(theme).Viewe09f063e}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                            size={64}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={styles(theme).Viewb2425505}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={styles(theme).View09162134}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              style={styles(theme).Texta10a092d}
                                              ellipsizeMode={'tail'}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={styles(theme).Viewb143a85e}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={styles(theme).Texte88e1c80}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={styles(theme).View0aa36c3c}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={styles(theme).Text49682267}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={styles(theme).Viewa8a94d9a}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                    {
                                                      notifications_id:
                                                        listData?.id,
                                                    }
                                                  );
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={styles(theme).Viewa8da3065}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={
                                                  styles(theme).Viewfe2670fd
                                                }
                                              >
                                                <Icon
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                  color={theme.colors.medium}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isSharedFolder */}
                            <>
                              {!listData?.isShared ? null : (
                                <View style={styles(theme).Viewf8304bf6}>
                                  {/* Flex Touchable */}
                                  <View style={styles(theme).Viewf1746056}>
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'ContentRepositoryScreen'
                                            );
                                            await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                              { notifications_id: listData?.id }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View style={styles(theme).Viewb2cd8b01}>
                                        {/* Left Side Frame */}
                                        <View
                                          style={styles(theme).Viewe09f063e}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                            size={64}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={styles(theme).Viewb2425505}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={styles(theme).View09162134}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              style={styles(theme).Texta10a092d}
                                              ellipsizeMode={'tail'}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={styles(theme).Viewb143a85e}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={styles(theme).Texte88e1c80}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={styles(theme).View0aa36c3c}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={styles(theme).Text49682267}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={styles(theme).Viewa8a94d9a}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                    {
                                                      notifications_id:
                                                        listData?.id,
                                                    }
                                                  );
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={styles(theme).Viewa8da3065}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={
                                                  styles(theme).Viewfe2670fd
                                                }
                                              >
                                                <Icon
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                  color={theme.colors.medium}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isFollow */}
                            <>
                              {!listData?.isFollowed ? null : (
                                <View style={styles(theme).Viewf8304bf6}>
                                  {/* Flex Touchable */}
                                  <View style={styles(theme).Viewf1746056}>
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'MemberDetailScreen',
                                              {
                                                navigate_user_Id:
                                                  listData &&
                                                  listData['_sender_details']
                                                    ?.id,
                                              }
                                            );
                                            await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                              { notifications_id: listData?.id }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View style={styles(theme).Viewb2cd8b01}>
                                        {/* Left Side Frame */}
                                        <View
                                          style={styles(theme).Viewe09f063e}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                            size={64}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={styles(theme).Viewb2425505}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={styles(theme).View09162134}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              style={styles(theme).Texta10a092d}
                                              ellipsizeMode={'tail'}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={styles(theme).Viewb143a85e}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={styles(theme).Texte88e1c80}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={styles(theme).View0aa36c3c}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={styles(theme).Text49682267}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={styles(theme).Viewa8a94d9a}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                    {
                                                      notifications_id:
                                                        listData?.id,
                                                    }
                                                  );
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={styles(theme).Viewa8da3065}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={
                                                  styles(theme).Viewfe2670fd
                                                }
                                              >
                                                <Icon
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                  color={theme.colors.medium}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isGlobal Frame */}
                            <>
                              {!listData?.isGlobal ? null : (
                                <View style={styles(theme).Viewf8304bf6}>
                                  {/* Flex Touchable */}
                                  <View style={styles(theme).Viewf1746056}>
                                    <Touchable>
                                      {/* Record Item Frame */}
                                      <View style={styles(theme).Viewb2cd8b01}>
                                        {/* Left Side Frame */}
                                        <View
                                          style={styles(theme).Viewe09f063e}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={styles(theme).Viewb2425505}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={styles(theme).View09162134}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              style={styles(theme).Texta10a092d}
                                              ellipsizeMode={'tail'}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={styles(theme).Viewb143a85e}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={styles(theme).Texte88e1c80}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={styles(theme).View0aa36c3c}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={styles(theme).Text49682267}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={styles(theme).Viewa8a94d9a}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                    {
                                                      notifications_id:
                                                        listData?.id,
                                                    }
                                                  );
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={styles(theme).Viewa8da3065}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={
                                                  styles(theme).Viewfe2670fd
                                                }
                                              >
                                                <Icon
                                                  name={'MaterialIcons/clear'}
                                                  color={theme.colors.medium}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                          </>
                        );
                      }}
                      style={styles(theme).FlatList989db244}
                      contentContainerStyle={
                        styles(theme).FlatListc992f941Content
                      }
                      numColumns={1}
                    />
                  </>
                );
              }}
            </RisingCoachesApi.FetchGetNotificationsGET>
          </View>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Fetch431eb058: { minHeight: 40 },
    FlatListc992f941Content: { flex: 1 },
    ScrollView4e34f380Content: { flexShrink: 0 },
    ScrollViewb284e5b0: { flexGrow: 1 },
    Text49682267: {
      color: theme.colors.strong,
      fontFamily: 'OpenSans_400Regular',
      fontSize: 12,
      lineHeight: 18,
    },
    Texta10a092d: {
      color: theme.colors.strong,
      fontFamily: 'OpenSans_700Bold',
      paddingBottom: 9,
    },
    Texta6b8270e: { color: theme.colors.light },
    Texte88e1c80: {
      color: theme.colors.custom_rgb153_153_153,
      fontFamily: 'OpenSans_600SemiBold',
      fontSize: 10,
      lineHeight: 16,
    },
    View09162134: { marginRight: 12 },
    View0aa36c3c: { alignItems: 'center', flexDirection: 'row', marginTop: 6 },
    View8c951c00: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      marginTop: 100,
    },
    Viewa8a94d9a: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    Viewa8da3065: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: theme.colors.custom_rgb97_116_246,
      borderRadius: 12,
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: 'center',
      paddingBottom: 6,
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 6,
    },
    Viewb143a85e: {
      alignItems: 'center',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
    },
    Viewb2425505: {
      flexGrow: 1,
      flexShrink: 0,
      justifyContent: 'center',
      marginLeft: 18,
      maxWidth: 200,
    },
    Viewb2cd8b01: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 12,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      paddingBottom: 12,
      paddingTop: 12,
    },
    Viewe09f063e: {
      alignItems: 'center',
      borderBottomLeftRadius: 12,
      borderTopLeftRadius: 12,
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: 'center',
      paddingLeft: 12,
    },
    Viewf1746056: { marginBottom: 12, marginLeft: 12, marginRight: 12 },
    Viewf8304bf6: { marginTop: 12 },
    Viewfe2670fd: { marginRight: 3 },
  });

export default withTheme(NotificationsScreen);
