import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import { parseBoolean } from '../utils';
import {
  Checkbox,
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const MemberDirectoryScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const FilterList = list => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    const newList = list.filter(item =>
      item.name.toLowerCase().includes(textInputValue.toLowerCase())
    );

    return newList;
  };

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesFollowUserPOST = RisingCoachesApi.useFollowUserPOST();
  const risingCoachesUnfollowUserDELETE =
    RisingCoachesApi.useUnfollowUserDELETE();

  const [isAll, setIsAll] = React.useState(true);
  const [isCoaches, setIsCoaches] = React.useState(false);
  const [isFollowed, setIsFollowed] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} hasTopSafeArea={false}>
      {/* Header View Frame */}
      <View style={styles(theme).Viewdbf79098}>
        {/* Navigation Bar Component */}
        <View style={styles(theme).View4c0cb905}>
          {/* Left Side Frame */}
          <View style={styles(theme).View8911d375}>
            <>
              {!isSearch ? null : (
                <TextInput
                  onChangeText={newTextInputValue => {
                    try {
                      setTextInputValue(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={styles(theme).TextInputfc7bdd6e}
                  value={textInputValue}
                  placeholder={'Find a user...'}
                  autoFocus={true}
                />
              )}
            </>
          </View>
          {/* Right Side Frame */}
          <View style={styles(theme).View2b224da5}>
            {/* Right Icon Touchable 2 */}
            <>
              {isSearch ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setIsSearch(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Right Icon 2 */}
                  <Icon
                    size={18}
                    name={'AntDesign/search1'}
                    color={theme.colors.custom_rgb189_198_212}
                  />
                </Touchable>
              )}
            </>
            {/* Right Icon Touchable 2 */}
            <>
              {!isSearch ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setIsSearch(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Right Icon 2 */}
                  <Icon
                    size={18}
                    color={theme.colors.custom_rgb189_198_212}
                    name={'Feather/x'}
                  />
                </Touchable>
              )}
            </>
          </View>
        </View>
        {/* Nav Bar Additional Configuration */}
        <View style={styles(theme).Viewff15e86f}>
          {/* Left Side Frame */}
          <View style={styles(theme).Viewdf6ad9a1}>
            <Touchable
              onPress={() => {
                try {
                  setIsAll(true);
                  setIsFollowed(false);
                  setIsCoaches(false);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Tab 1 All */}
              <Text style={styles(theme).Texta0584073}>{'All Users'}</Text>
              <>
                {!isAll ? null : (
                  <Divider
                    style={styles(theme).Divider5b4057da}
                    color={theme.colors.primary}
                  />
                )}
              </>
            </Touchable>

            <Touchable
              onPress={() => {
                try {
                  setIsAll(false);
                  setIsFollowed(false);
                  setIsCoaches(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Tab 1 Text */}
              <Text style={styles(theme).Texta0584073}>{'All Coaches'}</Text>
              <>
                {!isCoaches ? null : (
                  <Divider
                    style={styles(theme).Divider5b4057da}
                    color={theme.colors.primary}
                  />
                )}
              </>
            </Touchable>

            <Touchable
              onPress={() => {
                try {
                  setIsFollowed(true);
                  setIsAll(false);
                  setIsCoaches(false);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Tab 1 Text */}
              <Text style={styles(theme).Texta0584073}>{'Followed'}</Text>
              <>
                {!isFollowed ? null : (
                  <Divider
                    style={styles(theme).Divider5b4057da}
                    color={theme.colors.primary}
                  />
                )}
              </>
            </Touchable>
          </View>
        </View>
        {/* Divider Frame */}
        <View>
          {/* Divider List */}
          <View style={styles(theme).Viewd662ea72} />
        </View>
      </View>
      {/* All Scroll View Frame */}
      <>
        {!isAll ? null : (
          <ScrollView
            style={styles(theme).ScrollView989db244}
            contentContainerStyle={styles(theme).ScrollViewb313e3e6Content}
            showsVerticalScrollIndicator={true}
            bounces={true}
          >
            <RisingCoachesApi.FetchGetAllUsersGET>
              {({ loading, error, data, refetchGetAllUsers }) => {
                const fetchData = data;
                if (!fetchData || loading) {
                  return <ActivityIndicator />;
                }

                if (error) {
                  return (
                    <Text style={{ textAlign: 'center' }}>
                      There was a problem fetching this data
                    </Text>
                  );
                }

                return (
                  <FlatList
                    data={FilterList(fetchData?.items)}
                    listKey={'jEb8vloU'}
                    keyExtractor={listData =>
                      listData?.id || listData?.uuid || JSON.stringify(listData)
                    }
                    renderItem={({ item }) => {
                      const listData = item;
                      return (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('MemberDetailScreen', {
                                navigate_user_Id: listData?.id,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* List Record Item Wrapper */}
                          <View style={styles(theme).View93a16d91}>
                            {/* Left Side Wrapper */}
                            <View style={styles(theme).View43b593eb}>
                              {/* Light BG */}
                              <View style={styles(theme).Viewff6e3680}>
                                {/* List Item Image */}
                                <Image
                                  style={styles(theme).Image8dbb3d0d}
                                  source={{
                                    uri: `${listData?.profile_image?.url}`,
                                  }}
                                  resizeMode={'cover'}
                                />
                              </View>
                              {/* Record Details Frame */}
                              <View style={styles(theme).Viewfcd7183b}>
                                {/* List Item Title */}
                                <Text
                                  style={styles(theme).Text026ce2fc}
                                  ellipsizeMode={'tail'}
                                  numberOfLines={1}
                                >
                                  {listData?.name}
                                </Text>
                                {/* List Item Color */}
                                <Text
                                  style={styles(theme).Text50b798ca}
                                  ellipsizeMode={'tail'}
                                  numberOfLines={1}
                                >
                                  {listData?.job_title}
                                </Text>
                                {/* List Item Color */}
                                <Text
                                  style={styles(theme).Text50b798ca}
                                  ellipsizeMode={'tail'}
                                  numberOfLines={1}
                                >
                                  {listData?.current_university}
                                </Text>
                              </View>
                            </View>
                            {/* Icon Flex Properties Flex End */}
                            <View style={styles(theme).Viewee33c0b7}>
                              {/* Heart Checkbox */}
                              <Checkbox
                                onCheck={() => {
                                  const handler = async () => {
                                    try {
                                      await risingCoachesFollowUserPOST.mutateAsync(
                                        { followed_id: listData?.id }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                onUncheck={() => {
                                  const handler = async () => {
                                    try {
                                      await risingCoachesUnfollowUserDELETE.mutateAsync(
                                        { followed_id: listData?.id }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                checkedIcon={'AntDesign/heart'}
                                uncheckedIcon={'AntDesign/hearto'}
                                size={18}
                                uncheckedColor={theme.colors.primary}
                                color={theme.colors.primary}
                                defaultValue={listData?.isFollowed}
                              />
                            </View>
                          </View>
                          <Divider
                            style={styles(theme).Dividerde11d607}
                            color={theme.colors.divider}
                          />
                        </Touchable>
                      );
                    }}
                    numColumns={1}
                  />
                );
              }}
            </RisingCoachesApi.FetchGetAllUsersGET>
          </ScrollView>
        )}
      </>
      {/* Followed Scroll View Frame */}
      <>
        {!isFollowed ? null : (
          <ScrollView
            style={styles(theme).ScrollView989db244}
            contentContainerStyle={styles(theme).ScrollViewb313e3e6Content}
            showsVerticalScrollIndicator={true}
            bounces={true}
          >
            <RisingCoachesApi.FetchGetAllUsersGET>
              {({ loading, error, data, refetchGetAllUsers }) => {
                const fetchData = data;
                if (!fetchData || loading) {
                  return <ActivityIndicator />;
                }

                if (error) {
                  return (
                    <Text style={{ textAlign: 'center' }}>
                      There was a problem fetching this data
                    </Text>
                  );
                }

                return (
                  <FlatList
                    data={FilterList(fetchData?.items)}
                    listKey={'igxr2HoV'}
                    keyExtractor={listData =>
                      listData?.id || listData?.uuid || JSON.stringify(listData)
                    }
                    renderItem={({ item }) => {
                      const listData = item;
                      return (
                        <>
                          {!listData?.isFollowed ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('MemberDetailScreen', {
                                    navigate_user_Id: listData?.id,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* List Record Item Wrapper */}
                              <View style={styles(theme).View93a16d91}>
                                {/* Left Side Wrapper */}
                                <View style={styles(theme).View43b593eb}>
                                  {/* Light BG */}
                                  <View style={styles(theme).Viewff6e3680}>
                                    {/* List Item Image */}
                                    <Image
                                      style={styles(theme).Image8dbb3d0d}
                                      source={{
                                        uri: `${listData?.profile_image?.url}`,
                                      }}
                                      resizeMode={'cover'}
                                    />
                                  </View>
                                  {/* Record Details Frame */}
                                  <View style={styles(theme).Viewfcd7183b}>
                                    {/* List Item Title */}
                                    <Text
                                      style={styles(theme).Text026ce2fc}
                                      ellipsizeMode={'tail'}
                                      numberOfLines={1}
                                    >
                                      {listData?.name}
                                    </Text>
                                    {/* List Item Color */}
                                    <Text
                                      style={styles(theme).Text50b798ca}
                                      ellipsizeMode={'tail'}
                                      numberOfLines={1}
                                    >
                                      {listData?.job_title}
                                    </Text>
                                    {/* List Item Color */}
                                    <Text
                                      style={styles(theme).Text50b798ca}
                                      ellipsizeMode={'tail'}
                                      numberOfLines={1}
                                    >
                                      {listData?.current_university}
                                    </Text>
                                  </View>
                                </View>
                                {/* Icon Flex Properties Flex End */}
                                <View style={styles(theme).Viewee33c0b7}>
                                  {/* Heart Checkbox */}
                                  <Checkbox
                                    onCheck={() => {
                                      const handler = async () => {
                                        try {
                                          await risingCoachesFollowUserPOST.mutateAsync(
                                            { followed_id: listData?.id }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    onUncheck={() => {
                                      const handler = async () => {
                                        try {
                                          await risingCoachesUnfollowUserDELETE.mutateAsync(
                                            { followed_id: listData?.id }
                                          );
                                          await refetchGetAllUsers();
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    checkedIcon={'AntDesign/heart'}
                                    uncheckedIcon={'AntDesign/hearto'}
                                    size={18}
                                    uncheckedColor={theme.colors.primary}
                                    color={theme.colors.primary}
                                    defaultValue={parseBoolean(
                                      listData?.isFollowed
                                    )}
                                  />
                                </View>
                              </View>
                              <Divider
                                style={styles(theme).Dividerde11d607}
                                color={theme.colors.divider}
                              />
                            </Touchable>
                          )}
                        </>
                      );
                    }}
                    numColumns={1}
                  />
                );
              }}
            </RisingCoachesApi.FetchGetAllUsersGET>
          </ScrollView>
        )}
      </>
      {/* Coaches Scroll View Frame */}
      <>
        {!isCoaches ? null : (
          <ScrollView
            style={styles(theme).ScrollView989db244}
            contentContainerStyle={styles(theme).ScrollViewb313e3e6Content}
            showsVerticalScrollIndicator={true}
            bounces={true}
          >
            {/* CoachesFetch */}
            <RisingCoachesApi.FetchSearchDirectoryGET input={textInputValue}>
              {({ loading, error, data, refetchSearchDirectory }) => {
                const coachesFetchData = data;
                if (!coachesFetchData || loading) {
                  return <ActivityIndicator />;
                }

                if (error) {
                  return (
                    <Text style={{ textAlign: 'center' }}>
                      There was a problem fetching this data
                    </Text>
                  );
                }

                return (
                  <FlatList
                    data={coachesFetchData?.items}
                    listKey={'tjixL71J'}
                    keyExtractor={listData =>
                      listData?.id || listData?.uuid || JSON.stringify(listData)
                    }
                    renderItem={({ item }) => {
                      const listData = item;
                      return (
                        <>
                          {/* List Record Item Wrapper */}
                          <View style={styles(theme).View93a16d91}>
                            {/* Left Side Wrapper */}
                            <View style={styles(theme).View43b593eb}>
                              {/* Light BG */}
                              <View style={styles(theme).Viewff6e3680}>
                                {/* List Item Image */}
                                <Image
                                  style={styles(theme).Image8dbb3d0d}
                                  resizeMode={'cover'}
                                  source={Images.File1cabff}
                                />
                              </View>
                              {/* Record Details Frame */}
                              <View style={styles(theme).View9a649d55}>
                                {/* List Item Title */}
                                <Text
                                  style={styles(theme).Text026ce2fc}
                                  ellipsizeMode={'tail'}
                                  numberOfLines={1}
                                >
                                  {listData?.Name}
                                </Text>
                                {/* List Item Color */}
                                <Text
                                  style={styles(theme).Text50b798ca}
                                  ellipsizeMode={'tail'}
                                  numberOfLines={1}
                                >
                                  {listData?.Title}
                                </Text>
                                {/* List Item Color */}
                                <Text
                                  style={styles(theme).Text50b798ca}
                                  ellipsizeMode={'tail'}
                                  numberOfLines={1}
                                >
                                  {listData?.School}
                                </Text>
                              </View>
                            </View>
                            {/* Icon Flex Properties Flex End */}
                            <View style={styles(theme).View6a955cc3}>
                              <>
                                {!listData?.Email ? null : (
                                  <Text style={styles(theme).Text9a0529e6}>
                                    {listData?.Email}
                                  </Text>
                                )}
                              </>
                              <Text style={styles(theme).Text9a0529e6}>
                                {listData?.Phone_Number}
                              </Text>
                            </View>
                          </View>
                        </>
                      );
                    }}
                    numColumns={1}
                  />
                );
              }}
            </RisingCoachesApi.FetchSearchDirectoryGET>
          </ScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Divider5b4057da: { height: 2, marginRight: 15, marginTop: 3 },
    Dividerde11d607: { height: 1 },
    Fetch431eb058: { minHeight: 40 },
    Image8dbb3d0d: { height: 50, width: 50 },
    ScrollViewb313e3e6Content: { paddingBottom: 24 },
    Text026ce2fc: {
      color: theme.colors.primaryTitleUiBaeg,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 11,
    },
    Text50b798ca: {
      color: theme.colors.custom_rgb149_158_172,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 9,
      marginTop: 3,
    },
    Text9a0529e6: {
      color: theme.colors.strong,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 8,
      textAlign: 'right',
    },
    TextInputfc7bdd6e: {
      backgroundColor: theme.colors.surface,
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Texta0584073: {
      color: theme.colors.custom_rgb149_158_172,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 10,
      marginRight: 18,
      textTransform: 'uppercase',
    },
    View2b224da5: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: 'center',
      maxWidth: '10%',
      minWidth: '10%',
    },
    View43b593eb: { flexDirection: 'row', flexGrow: 1, flexShrink: 0 },
    View4c0cb905: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: 24,
      paddingLeft: 18,
      paddingTop: 24,
    },
    View6a955cc3: { justifyContent: 'center' },
    View701d2f5c: {
      backgroundColor: theme.colors.custom_rgb191_191_191,
      height: 1,
    },
    View8911d375: {
      alignSelf: 'stretch',
      flexGrow: 0,
      flexShrink: 0,
      minWidth: '70%',
    },
    View93a16d91: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      flexGrow: 1,
      flexShrink: 0,
      marginBottom: 9,
      marginLeft: 12,
      marginRight: 12,
      marginTop: 9,
    },
    View9a649d55: {
      flexGrow: 1,
      flexShrink: 0,
      justifyContent: 'center',
      marginLeft: 18,
      maxWidth: 150,
    },
    Viewc178c3fa: {
      backgroundColor: theme.colors.custom_rgb223_223_223,
      height: 6,
    },
    Viewd503657d: {
      backgroundColor: theme.colors.custom_rgb223_223_223,
      height: 3,
    },
    Viewd662ea72: {
      backgroundColor: theme.colors.custom_rgb223_223_223,
      height: 1,
    },
    Viewdbf79098: { flexGrow: 0, flexShrink: 0 },
    Viewdf6ad9a1: {
      alignItems: 'center',
      flexDirection: 'row',
      marginLeft: 12,
    },
    Viewee33c0b7: { justifyContent: 'flex-end' },
    Viewfcd7183b: {
      flexGrow: 1,
      flexShrink: 0,
      justifyContent: 'center',
      marginLeft: 18,
    },
    Viewff15e86f: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 12,
      paddingTop: 12,
    },
    Viewff6e3680: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 65,
      justifyContent: 'center',
      width: 65,
    },
  });

export default withTheme(MemberDirectoryScreen);
