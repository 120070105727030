import React from 'react';
import * as CustomCode from '../CustomCode';
import * as Chat from '../custom-files/Chat';
import * as Utils from '../utils';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { StyleSheet, View } from 'react-native';

const ChatScreen = props => {
  const { theme } = props;
  const { navigation } = props;

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={false}
      hasTopSafeArea={true}
    >
      <View>
        {/* Button Outline */}
        <Button
          onPress={() => {
            try {
              navigation.navigate('InboxScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={styles(theme).Buttonc979107c}
          title={'Back to Inbox'}
        />
      </View>
      <Utils.CustomCodeErrorBoundary>
        <Chat.ChatComponent
          chat_id={props.route?.params?.chatroom_id}
          navigation={props.navigation}
        />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Buttonc979107c: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginTop: 10,
      textAlign: 'center',
    },
  });

export default withTheme(ChatScreen);
