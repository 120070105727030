import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const JobsBoardScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const isWinner = custom => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if ((custom = '')) {
      return true;
    } else false;
  };

  // Converts a date
  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const todayDate = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return CustomCode.moment().format('MMM Do');
  };

  const formatScoreDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return CustomCode.moment(dateString).format('MMM Do');
  };

  // Takes image obj and returns the url within
  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj.url;
  };

  const { theme } = props;
  const { navigation } = props;

  const [isCompany, setIsCompany] = React.useState(false);
  const [showNav, setShowNav] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      style={styles(theme).screen}
      hasTopSafeArea={false}
      hasSafeArea={false}
    >
      {/* Main Body */}
      <>
        {!Constants['user_paying'] ? null : (
          <View style={styles(theme).Viewc992f941}>
            {/* Body */}
            <View style={styles(theme).View98b256f7}>
              <KeyboardAwareScrollView
                style={styles(theme).KeyboardAwareScrollView989db244}
                contentContainerStyle={
                  styles(theme).KeyboardAwareScrollView39912261Content
                }
                showsVerticalScrollIndicator={true}
                keyboardShouldPersistTaps={'never'}
              >
                {/* Button Outline */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <Button
                      style={styles(theme).Button1e9377d5}
                      title={''}
                      disabled={true}
                      loading={true}
                    />
                  )}
                </>
                <RisingCoachesApi.FetchGetOpportunitiesGET>
                  {({ loading, error, data, refetchGetOpportunities }) => {
                    const fetchData = data;
                    if (!fetchData || loading) {
                      return <ActivityIndicator />;
                    }

                    if (error) {
                      return (
                        <Text style={{ textAlign: 'center' }}>
                          There was a problem fetching this data
                        </Text>
                      );
                    }

                    return (
                      <FlatList
                        data={fetchData}
                        listKey={'oYAy5u4i'}
                        keyExtractor={listData =>
                          listData?.id ||
                          listData?.uuid ||
                          JSON.stringify(listData)
                        }
                        renderItem={({ item }) => {
                          const listData = item;
                          return (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'OpportunityDetailsScreen',
                                    { opportunity_id: listData?.id }
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* NewFeedCard */}
                              <Surface
                                style={styles(theme).Surfaceb83606e0}
                                elevation={1}
                              >
                                {/* PostHeader */}
                                <View style={styles(theme).View7963ad69}>
                                  {/* LeftAligned */}
                                  <View style={styles(theme).Viewce4accf0}>
                                    {/* Light BG */}
                                    <View style={styles(theme).Viewff6e3680}>
                                      <Image
                                        style={styles(theme).Image8dbb3d0d}
                                        source={{
                                          uri: `${listData?.company_logo?.url}`,
                                        }}
                                        resizeMode={'contain'}
                                      />
                                    </View>

                                    <View style={styles(theme).Viewd48fe752}>
                                      {/* Comp Name */}
                                      <Text style={styles(theme).Textbba75e6f}>
                                        {listData?.company_name}
                                      </Text>
                                    </View>
                                  </View>
                                  {/* RightAligned */}
                                  <View style={styles(theme).View967fd4f5}>
                                    <Link
                                      style={styles(theme).Link918ce548}
                                      title={'View Details'}
                                    />
                                  </View>
                                </View>
                                {/* Details */}
                                <View style={styles(theme).View7237486d}>
                                  {/* First Row */}
                                  <View style={styles(theme).View396d0aa4}>
                                    {/* LeftSide */}
                                    <View>
                                      <Text style={styles(theme).Textc36ed116}>
                                        {listData?.opportunity_title}
                                      </Text>
                                    </View>
                                  </View>
                                  {/* Second Row */}
                                  <View style={styles(theme).View396d0aa4}>
                                    {/* LeftSide */}
                                    <View style={styles(theme).Viewce4accf0}>
                                      <Icon
                                        style={styles(theme).Iconfe2670fd}
                                        name={'FontAwesome/money'}
                                        color={theme.colors.light}
                                        size={20}
                                      />
                                      <Text style={styles(theme).Text98a40b93}>
                                        {listData?.price_range}{' '}
                                        {listData?.compensation_rate}
                                      </Text>
                                    </View>
                                  </View>
                                  {/* Third Row */}
                                  <View style={styles(theme).View396d0aa4}>
                                    {/* LeftSide */}
                                    <View style={styles(theme).Viewce4accf0}>
                                      <Icon
                                        style={styles(theme).Iconfe2670fd}
                                        color={theme.colors.light}
                                        size={20}
                                        name={'FontAwesome/map-marker'}
                                      />
                                      <Text style={styles(theme).Texta3059d3e}>
                                        {listData?.city}
                                        {', '}
                                        {listData?.state}
                                      </Text>
                                    </View>
                                    {/* RightSide */}
                                    <View>
                                      <Text style={styles(theme).Text1f3d5812}>
                                        {listData?.opportunity_type}
                                      </Text>
                                    </View>
                                  </View>
                                  {/* Fourth Row */}
                                  <View style={styles(theme).View396d0aa4}>
                                    {/* LeftSide */}
                                    <View style={styles(theme).View4d1ff5de}>
                                      <Icon
                                        style={styles(theme).Iconfe2670fd}
                                        size={20}
                                        name={'Feather/briefcase'}
                                        color={theme.colors.light}
                                      />
                                      <Text style={styles(theme).Text5e591866}>
                                        {listData?.minimum_education}
                                      </Text>
                                    </View>
                                    {/* RightSide */}
                                    <View>
                                      <Text style={styles(theme).Text9cf1a5d1}>
                                        {'2 Days Left'}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </Surface>
                            </Touchable>
                          );
                        }}
                        style={styles(theme).FlatListdf29e2e2}
                        contentContainerStyle={
                          styles(theme).FlatLista1614b10Content
                        }
                        numColumns={1}
                        horizontal={false}
                      />
                    );
                  }}
                </RisingCoachesApi.FetchGetOpportunitiesGET>
              </KeyboardAwareScrollView>
            </View>
          </View>
        )}
      </>
      {/* NotPaying */}
      <>
        {Constants['user_paying'] ? null : (
          <View>
            {/* PayWall */}
            <View style={styles(theme).View74763ff2}>
              <Text style={styles(theme).Textb17d301e}>
                {
                  'This feature is only available to Rising Coaches members. Join our membership program to take advantage of everything Rising Coaches has to offer.'
                }
              </Text>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://www.risingcoaches.com/membership'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button47b42b4a}
                title={'Subscribe'}
              />
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button1e9377d5: {
      backgroundColor: 'transparent',
      borderRadius: 8,
      borderWidth: 1,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Button47b42b4a: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      textAlign: 'center',
    },
    Buttonf7e16baa: {
      backgroundColor: theme.colors.secondary,
      borderRadius: 8,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    Dividerde11d607: { height: 1 },
    Fetch431eb058: { minHeight: 40 },
    FlatLista1614b10Content: {
      alignContent: 'center',
      alignSelf: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    FlatListdf29e2e2: { width: '100%' },
    Iconfe2670fd: { marginRight: 3 },
    Image8dbb3d0d: { height: 50, width: 50 },
    KeyboardAwareScrollView39912261Content: { alignItems: 'center' },
    Link918ce548: {
      color: theme.colors.primary,
      fontFamily: 'Roboto_700Bold',
      textDecorationLine: 'underline',
    },
    Surfaceb83606e0: {
      alignContent: 'stretch',
      alignItems: 'center',
      alignSelf: 'stretch',
      backgroundColor: theme.colors.surface,
      borderRadius: 20,
      justifyContent: 'space-around',
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
    },
    Text1f3d5812: {
      color: theme.colors.primary,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
    },
    Text5e591866: { color: theme.colors.light, fontSize: 12 },
    Text98a40b93: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
    },
    Text9cf1a5d1: { color: theme.colors.error, fontSize: 12 },
    TextInputb2cb2d6f: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Texta3059d3e: { color: theme.colors.strong, fontSize: 12 },
    Textb17d301e: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    Textbba75e6f: {
      color: theme.colors.medium,
      fontFamily: 'Roboto_700Bold',
      fontSize: 16,
      marginBottom: 2,
    },
    Textc36ed116: { color: theme.colors.strong, fontFamily: 'Roboto_700Bold' },
    Textd8d33cc8: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
    },
    View026619d6: {
      alignContent: 'stretch',
      alignItems: 'flex-end',
      alignSelf: 'stretch',
      justifyContent: 'center',
      width: '30%',
    },
    View396d0aa4: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: 5,
      marginRight: 5,
    },
    View4d1ff5de: { alignItems: 'center', flexDirection: 'row', maxWidth: 150 },
    View7237486d: {
      alignContent: 'center',
      alignSelf: 'stretch',
      borderRadius: 12,
      justifyContent: 'space-around',
      marginBottom: 8,
      marginTop: 5,
      maxHeight: 300,
      maxWidth: '100%',
      minHeight: 180,
      minWidth: 280,
      overflow: 'hidden',
    },
    View74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    View7963ad69: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 3,
    },
    View967fd4f5: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    View98b256f7: { backgroundColor: theme.colors.background, flex: 1 },
    Viewb0908538: {
      backgroundColor: theme.colors.background,
      borderRadius: 10,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Viewc2a4dc71: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginBottom: 10,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 20,
    },
    Viewc992f941: { flex: 1 },
    Viewce4accf0: { alignItems: 'center', flexDirection: 'row' },
    Viewd48fe752: { marginLeft: 10, maxWidth: 125 },
    Viewfbae23e8: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      width: '70%',
    },
    Viewff6e3680: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 65,
      justifyContent: 'center',
      width: 65,
    },
    screen: { backgroundColor: theme.colors.surface },
  });

export default withTheme(JobsBoardScreen);
