import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  Circle,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const JobTimelineScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesDeleteJobDELETE = RisingCoachesApi.useDeleteJobDELETE();

  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [datePickerValue2, setDatePickerValue2] = React.useState(new Date());
  const [isAddNew, setIsAddNew] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={false}
    >
      <RisingCoachesApi.FetchGetJobTimelineGET
        user_id={props.route?.params?.nav_user_id ?? 17}
      >
        {({ loading, error, data, refetchGetJobTimeline }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              <>
                {!(props.route?.params?.nav_is_me ?? true) ? null : (
                  <View style={styles(theme).View318c81a0}>
                    <IconButton
                      onPress={() => {
                        try {
                          navigation.navigate('AddJobScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      icon={'AntDesign/pluscircle'}
                      color={theme.colors.primary}
                    />
                  </View>
                )}
              </>
              <FlatList
                data={fetchData?.items}
                listKey={'gD1EaMb6'}
                keyExtractor={listData =>
                  listData?.id || listData?.uuid || JSON.stringify(listData)
                }
                renderItem={({ item }) => {
                  const listData = item;
                  return (
                    <View>
                      <View style={styles(theme).Viewa690aa80}>
                        <View style={styles(theme).View30e24ae3}>
                          <Text style={styles(theme).Text1d8c8cac}>
                            {listData?.end_date}
                          </Text>
                          <>
                            {!(
                              props.route?.params?.nav_is_me ?? true
                            ) ? null : (
                              <Touchable>
                                <Icon
                                  style={styles(theme).Icon655583c0}
                                  size={24}
                                  name={'MaterialCommunityIcons/briefcase-edit'}
                                  color={theme.colors.secondary}
                                />
                              </Touchable>
                            )}
                          </>
                          <>
                            {!(
                              props.route?.params?.nav_is_me ?? true
                            ) ? null : (
                              <Touchable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await risingCoachesDeleteJobDELETE.mutateAsync(
                                        { job_timeline_id: listData?.id }
                                      );
                                      await refetchGetJobTimeline();
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                              >
                                <Icon
                                  style={styles(theme).Icon4e397499}
                                  size={24}
                                  name={
                                    'MaterialCommunityIcons/briefcase-remove'
                                  }
                                  color={theme.colors.error}
                                />
                              </Touchable>
                            )}
                          </>
                        </View>

                        <View style={styles(theme).Viewbfdbee05}>
                          <Circle size={16} bgColor={theme.colors.primary} />
                          <View style={styles(theme).View109372cc} />
                        </View>

                        <View style={styles(theme).View5fa5612a}>
                          <Text style={styles(theme).Text55c860fb}>
                            {listData?.organization}
                            {': '}
                            {listData?.job_title}
                          </Text>
                          <Spacer top={4} right={8} bottom={4} left={8} />
                          <Text style={styles(theme).Text3b5f4cc0}>
                            {listData?.job_description}
                          </Text>
                          <Spacer top={12} right={8} bottom={12} left={8} />
                        </View>
                      </View>
                    </View>
                  );
                }}
                style={styles(theme).FlatList989db244}
                contentContainerStyle={styles(theme).FlatList7efec157Content}
                numColumns={1}
              />
            </>
          );
        }}
      </RisingCoachesApi.FetchGetJobTimelineGET>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Fetch431eb058: { minHeight: 40 },
    FlatList7efec157Content: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flex: 1,
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    Icon4e397499: { marginBottom: 50 },
    Icon655583c0: { marginBottom: 5, marginTop: 5 },
    Text1d8c8cac: {
      color: theme.colors.primary,
      fontFamily: 'Roboto_700Bold',
      fontSize: 9,
    },
    Text3b5f4cc0: {
      color: theme.colors.medium,
      fontFamily: 'System',
      fontWeight: '400',
    },
    Text55c860fb: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontSize: 16,
      fontWeight: '600',
      lineHeight: 16,
    },
    View109372cc: { backgroundColor: theme.colors.primary, flex: 1, width: 4 },
    View30e24ae3: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'space-between',
    },
    View318c81a0: {
      alignContent: 'center',
      alignItems: 'center',
      marginTop: 15,
    },
    View5fa5612a: { flex: 4 },
    Viewa690aa80: { flexDirection: 'row', minHeight: 150 },
    Viewbfdbee05: { alignItems: 'center', flex: 1 },
  });

export default withTheme(JobTimelineScreen);
