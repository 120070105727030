// This import is required if you are defining react components in this module.
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native';
import moment from 'moment';
import { CircleImage } from '@draftbit/ui';
import React, { useState, useEffect, useCallback } from 'react';
import { Agenda } from 'react-native-calendars';
import * as GlobalVariableContext from '../config/GlobalVariableContext';

// export const CalendarComponent = () => {
//   return (
//     <Calendar
//       onDayPress={day => {
//         console.log('selected day', day);
//       }}
//       onDayLongPress={day => {
//         console.log('selected day', day);
//       }}
//       onMonthChange={month => {
//         console.log('month changed', month);
//       }}
//       hideExtraDays={true}
//       firstDay={1}
//       onPressArrowLeft={subtractMonth => subtractMonth()}
//       onPressArrowRight={addMonth => addMonth()}
//     />
//   );
// };

export const AgendaComponent = props => {
  const { navigation } = props;
  const variables = GlobalVariableContext.useValues();
  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  const renderItem = (item, isFirst) => {
    const fontSize = isFirst ? 16 : 14;
    const color = isFirst ? 'black' : '#43515c';

    return (
      <TouchableOpacity
        style={[
          styles.item,
          { backgroundColor: '#ffffff', borderRadius: 12, height: item.height },
        ]}
        onPress={() => {
          try {
            navigation.navigate('EventDetailsScreen', {
              navigate_event_id: item?.id,
            });
          } catch (err) {
            console.error(err);
          }
        }}
      >
        <View style={styles.Viewaa}>
          <Text style={[styles.TextVc, { color: 'black' }]}>
            {moment(item?.datetime).format('h:mm')}
          </Text>

          <Text style={[styles.Textsu, { color: 'black' }]}>
            {moment(item?.datetime).format('A')}
          </Text>
        </View>

        <View style={styles.View_39}>
          <Text style={{ fontSize, color }}>{item?.event_name}</Text>

          <View style={styles.ViewXm}>
            <CircleImage
              source={{
                uri: `${item?.user_details?.profile_image?.url}`,
              }}
              size={20}
            />
            <Text style={[styles.Texttq, { color: 'black' }]}>
              {item?.user_details.name}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderEmptyDate = () => {
    return (
      <View style={styles.emptyDate}>
        <Text>No events found {todayDate}</Text>
      </View>
    );
  };

  return (
    <Agenda
      items={variables.return_calendar}
      renderItem={item => {
        return renderItem(item);
      }}
      selected={todayDate}
      // selected={'2022-08-07'}
      renderEmptyData={renderEmptyDate}
      // rowHasChanged={this.rowHasChanged}
      showClosingKnob={true}
      theme={{
        agendaKnobColor: '#F16834', // knob color
        // agendaTodayColor: '#F16834', // today in list
        // textSectionTitleColor: "#F16834",
        selectedDayBackgroundColor: '#011145', // calendar sel date
        selectedDotColor: '#FFFFFF', // dots
        dotColor: '#F16834', // dots
        todayTextColor: '#F16834',
        selectedDayTextColor: '#FFFFFF',
      }}
    />
  );
};

const styles = StyleSheet.create({
  keyboardContainer: {
    flex: 1,
  },
  customTagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    backgroundColor: '#efeaea',
    width: 300,
  },
  item: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
    marginTop: 17,
    flexDirection: 'row',
  },
  emptyDate: {
    height: 15,
    flex: 1,
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ButtonSolidKk: {
    borderRadius: 8,
    fontFamily: 'System',
    marginBottom: 50,
    fontWeight: '700',
    width: 150,
    textAlign: 'center',
    backgroundColor: '#F16834',
  },
  customTag: {
    backgroundColor: '#F16834',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    marginLeft: 5,
    marginTop: 5,
    borderRadius: 30,
    padding: 8,
  },
  autoContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  header: {
    backgroundColor: '#011145',
    height: 80,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 15,
    marginBottom: 10,
  },
  autocompleteContainer: {
    flex: 1,
    left: 20,
    position: 'absolute',
    right: 20,
    top: 100,
    zIndex: 1,
  },
  label: {
    color: '#614b63',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    // marginTop: 160,
    height: 200,
    // alignSelf: 'stretch',
    // marginLeft: 20,
    // marginRight: 20,
  },
  message: {
    backgroundColor: '#efeaea',
    height: 200,
    textAlignVertical: 'top',
  },
  TextVc: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
  },
  Textsu: {
    fontSize: 14,
    fontFamily: 'Roboto_400Regular',
  },
  Viewaa: {
    width: '20%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Textub: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 18,
  },
  Texttq: {
    marginLeft: 5,
    fontFamily: 'Roboto_400Regular',
  },
  ViewXm: {
    flexDirection: 'row',
  },
  View_39: {
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
  ViewLm: {
    flexDirection: 'row',
    marginLeft: 30,
    marginTop: 50,
    marginRight: 30,
    paddingTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 15,
  },
});
