import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Calendar from '../custom-files/Calendar';
import * as Utils from '../utils';
import {
  CircleImage,
  Divider,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const CalendarScreen_coaa8c4F = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const response = await RisingCoachesApi.getCalendarGET(Constants);
        setGlobalVariableValue({
          key: 'return_calendar',
          value: response,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [isEvents, setIsEvents] = React.useState(true);

  return (
    <ScreenContainer
      style={styles(theme).screen}
      scrollable={false}
      hasSafeArea={false}
    >
      {/* Add Event */}
      <View style={styles(theme).View1c024723}>
        <Text style={styles(theme).Text617f8071}>{'Add Event'}</Text>
        <IconButton
          onPress={() => {
            try {
              navigation.navigate('CalendarAddScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'AntDesign/pluscircle'}
          color={theme.colors.primary}
        />
      </View>
      {/* Nav Bar Additional Configuration */}
      <View style={styles(theme).Viewb71b2743}>
        {/* Left Side Frame */}
        <View style={styles(theme).Viewdf6ad9a1}>
          <Touchable
            onPress={() => {
              try {
                setIsEvents(true);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Tab 1 All */}
            <Text style={styles(theme).Texta0584073}>{'Events'}</Text>
            <>
              {!isEvents ? null : (
                <Divider
                  style={styles(theme).Divider5b4057da}
                  color={theme.colors.primary}
                />
              )}
            </>
          </Touchable>

          <Touchable
            onPress={() => {
              try {
                setIsEvents(false);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Tab 1 Text */}
            <Text style={styles(theme).Texta0584073}>{'Birthdays'}</Text>
            <>
              {isEvents ? null : (
                <Divider
                  style={styles(theme).Divider5b4057da}
                  color={theme.colors.primary}
                />
              )}
            </>
          </Touchable>
        </View>
      </View>
      <>
        {!isEvents ? null : (
          <Utils.CustomCodeErrorBoundary>
            <Calendar.AgendaComponent navigation={props.navigation} />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
      {/* Birthdays */}
      <>
        {isEvents ? null : (
          <View style={styles(theme).View5871cdd2}>
            <Text style={styles(theme).Text837e1062}>
              {"This Month's Birthdays"}
            </Text>

            <RisingCoachesApi.FetchGetBirthdaysGET>
              {({ loading, error, data, refetchGetBirthdays }) => {
                const fetchData = data;
                if (!fetchData || loading) {
                  return <ActivityIndicator />;
                }

                if (error) {
                  return (
                    <Text style={{ textAlign: 'center' }}>
                      There was a problem fetching this data
                    </Text>
                  );
                }

                return (
                  <>
                    {/* No Data */}
                    <>
                      {fetchData?.items?.length ? null : (
                        <View style={styles(theme).View95cfd3d1}>
                          <Text style={styles(theme).Text97a79954}>
                            {'There are no birthdays this month.'}
                          </Text>
                        </View>
                      )}
                    </>
                    <ScrollView
                      showsVerticalScrollIndicator={true}
                      bounces={true}
                    >
                      <FlatList
                        data={fetchData?.items}
                        listKey={'cRscIJql'}
                        keyExtractor={listData =>
                          listData?.id ||
                          listData?.uuid ||
                          JSON.stringify(listData)
                        }
                        renderItem={({ item }) => {
                          const listData = item;
                          return (
                            <>
                              {/* BirthdayView */}
                              <View style={styles(theme).View21f897a7}>
                                {/* Light BG */}
                                <View style={styles(theme).Viewff672992}>
                                  <CircleImage
                                    source={{
                                      uri: `${listData?.profile_image?.url}`,
                                    }}
                                    size={30}
                                  />
                                </View>

                                <View style={styles(theme).Viewa548e856}>
                                  {/* CommentorName */}
                                  <Text style={styles(theme).Text20cad67c}>
                                    {listData?.name}
                                  </Text>

                                  <Text style={styles(theme).Text0ffa4cd0}>
                                    {listData?.birthday}
                                  </Text>
                                </View>
                              </View>
                            </>
                          );
                        }}
                        style={styles(theme).FlatList989db244}
                        contentContainerStyle={
                          styles(theme).FlatListc992f941Content
                        }
                        numColumns={1}
                      />
                    </ScrollView>
                  </>
                );
              }}
            </RisingCoachesApi.FetchGetBirthdaysGET>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Divider5b4057da: { height: 2, marginRight: 15, marginTop: 3 },
    Fetch431eb058: { minHeight: 40 },
    FlatListc992f941Content: { flex: 1 },
    Text0ffa4cd0: { color: theme.colors.strong, textAlign: 'left' },
    Text20cad67c: {
      color: theme.colors.secondary,
      fontFamily: 'Roboto_700Bold',
      marginBottom: 2,
      marginTop: 5,
      textAlign: 'left',
    },
    Text617f8071: { color: theme.colors.strong, marginRight: 8 },
    Text837e1062: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      fontSize: 16,
      marginBottom: 5,
      marginTop: 20,
    },
    Text97a79954: { color: theme.colors.light, textAlign: 'center' },
    Texta0584073: {
      color: theme.colors.custom_rgb149_158_172,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 10,
      marginRight: 18,
      textTransform: 'uppercase',
    },
    View1c024723: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 20,
      marginTop: 12,
      paddingBottom: 10,
    },
    View21f897a7: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      marginBottom: 10,
      marginTop: 10,
    },
    View5871cdd2: {
      alignItems: 'center',
      backgroundColor: theme.colors['Surface'],
      justifyContent: 'center',
    },
    View95cfd3d1: {
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 50,
    },
    Viewa548e856: { alignSelf: 'stretch', maxWidth: 270 },
    Viewb71b2743: {
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 12,
      paddingTop: 12,
    },
    Viewdf6ad9a1: {
      alignItems: 'center',
      flexDirection: 'row',
      marginLeft: 12,
    },
    Viewff672992: {
      alignItems: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      height: 35,
      justifyContent: 'center',
      marginRight: 5,
      width: 35,
    },
    screen: { backgroundColor: theme.colors.surface },
  });

export default withTheme(CalendarScreen_coaa8c4F);
