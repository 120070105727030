import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEmpty from '../global-functions/isEmpty';
import {
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const FolderContentsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const FilterList = list => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    const newList = list.filter(item =>
      item.content_title.toLowerCase().includes(textInputValue2.toLowerCase())
    );

    return newList;
  };

  const { theme } = props;
  const { navigation } = props;

  const [isSearch, setIsSearch] = React.useState(false);
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer scrollable={true} hasTopSafeArea={true}>
      {/* Add Folder View */}
      <>
        {props.route?.params?.isShared ?? false ? null : (
          <View style={styles(theme).View23608970}>
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('ShareFolderScreen', {
                    navigation_folder_id: props.route?.params?.folder_id ?? 35,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View style={styles(theme).View66f1b932}>
                <IconButton
                  onPress={() => {
                    try {
                      navigation.navigate('ShareFolderScreen', {
                        navigation_folder_id:
                          props.route?.params?.folder_id ?? 35,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'MaterialIcons/folder-shared'}
                />
                {/* Share Folder */}
                <Text style={styles(theme).Text3a00bd5e}>
                  {'Share this folder'}
                </Text>
              </View>
            </Touchable>

            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('UploadContentScreen', {
                    navigation_folder_id: props.route?.params?.folder_id ?? 35,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View style={styles(theme).View66f1b932}>
                {/* Add New Content */}
                <Text style={styles(theme).Text8ee82204}>{'Add new item'}</Text>
                <IconButton
                  onPress={() => {
                    try {
                      navigation.navigate('UploadContentScreen', {
                        navigation_folder_id:
                          props.route?.params?.folder_id ?? 35,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  icon={'Ionicons/add-circle-sharp'}
                  size={32}
                />
              </View>
            </Touchable>
          </View>
        )}
      </>
      {/* Search Bar Component */}
      <View style={styles(theme).View4c0cb905}>
        {/* Left Side Frame */}
        <View style={styles(theme).Viewb4229bb3}>
          <>
            {!isSearch ? null : (
              <TextInput
                onChangeText={newTextInputValue => {
                  try {
                    setTextInputValue2(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={styles(theme).TextInput7a13d5df}
                value={textInputValue2}
                placeholder={'Search Content'}
                autoFocus={true}
              />
            )}
          </>
        </View>
        {/* Right Side Frame */}
        <View style={styles(theme).View2b224da5}>
          {/* Right Icon Touchable 2 */}
          <>
            {isSearch ? null : (
              <Touchable
                onPress={() => {
                  try {
                    setIsSearch(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Right Icon 2 */}
                <Icon
                  name={'AntDesign/search1'}
                  size={18}
                  color={theme.colors.custom_rgb189_198_212}
                />
              </Touchable>
            )}
          </>
          {/* Right Icon Touchable 2 */}
          <>
            {!isSearch ? null : (
              <Touchable
                onPress={() => {
                  try {
                    setIsSearch(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Right Icon 2 */}
                <Icon
                  name={'Feather/x'}
                  size={18}
                  color={theme.colors.custom_rgb189_198_212}
                />
              </Touchable>
            )}
          </>
        </View>
      </View>
      <Divider
        style={styles(theme).Dividerde11d607}
        color={theme.colors.divider}
      />
      {/* ContentsFetch */}
      <RisingCoachesApi.FetchGetUserFolderContentsGET
        folder_id={props.route?.params?.folder_id ?? 35}
      >
        {({ loading, error, data, refetchGetUserFolderContents }) => {
          const contentsFetchData = data;
          if (!contentsFetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              {/* No Data */}
              <>
                {!isEmpty(contentsFetchData) ? null : (
                  <View style={styles(theme).Viewa7e3583d}>
                    <Text style={styles(theme).Text5f190d3b}>
                      {'There is no content yet. Upload your first file. '}
                    </Text>
                  </View>
                )}
              </>
              <FlatList
                data={FilterList(contentsFetchData)}
                listKey={'oYq7Q04L'}
                keyExtractor={listData =>
                  listData?.id || listData?.uuid || JSON.stringify(listData)
                }
                renderItem={({ item }) => {
                  const listData = item;
                  return (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContentDetailsScreen', {
                            navigation_content_id: listData?.id,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View style={styles(theme).Viewdebd3207}>
                        {/* File View */}
                        <>
                          {!listData?.isFile ? null : (
                            <View style={styles(theme).View98691c40}>
                              <Icon
                                style={styles(theme).Icon37fe153e}
                                name={'FontAwesome/file-text-o'}
                                size={45}
                                color={theme.colors.surface}
                              />
                            </View>
                          )}
                        </>
                        {/* Image View */}
                        <>
                          {!listData?.isImage ? null : (
                            <View style={styles(theme).View4aa3bd0f}>
                              <Image
                                style={styles(theme).Image115e9468}
                                source={{ uri: `${listData?.image?.url}` }}
                                resizeMode={'cover'}
                              />
                            </View>
                          )}
                        </>
                        {/* Video View */}
                        <>
                          {!listData?.isVideo ? null : (
                            <View style={styles(theme).View4aa3bd0f}>
                              <Image
                                style={styles(theme).Image115e9468}
                                source={{
                                  uri: `${listData?.youtube_thumbnail}`,
                                }}
                                resizeMode={'cover'}
                              />
                            </View>
                          )}
                        </>
                        <View style={styles(theme).Viewef40d3e7}>
                          <View style={styles(theme).Viewc992f941}>
                            <Text style={styles(theme).Text89f46c03}>
                              {listData?.content_type}
                            </Text>

                            <Text
                              style={styles(theme).Textc15d2f48}
                              ellipsizeMode={'tail'}
                              numberOfLines={2}
                            >
                              {listData?.content_title}
                            </Text>

                            <Text style={styles(theme).Text132f22ec}>
                              {'Uploaded By '}
                              {listData?.user_details?.name}
                            </Text>
                          </View>

                          <View style={styles(theme).View59b1f5da}>
                            <Icon
                              name={'Feather/chevron-right'}
                              size={24}
                              color={theme.colors.light}
                            />
                          </View>
                        </View>
                      </View>
                    </Touchable>
                  );
                }}
                style={styles(theme).FlatList989db244}
                contentContainerStyle={styles(theme).FlatListc992f941Content}
                numColumns={1}
              />
            </>
          );
        }}
      </RisingCoachesApi.FetchGetUserFolderContentsGET>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Dividerde11d607: { height: 1 },
    Fetch431eb058: { minHeight: 40 },
    FlatListc992f941Content: { flex: 1 },
    Icon37fe153e: { height: 100, minHeight: 100, minWidth: 100, width: 100 },
    Image115e9468: { height: 100, width: 100 },
    Text132f22ec: {
      color: theme.colors.primary,
      fontFamily: 'System',
      fontWeight: '400',
      marginTop: 4,
    },
    Text3a00bd5e: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
      marginLeft: 5,
    },
    Text5f190d3b: {
      alignSelf: 'center',
      color: theme.colors['Light'],
      fontFamily: 'Manrope_400Regular',
      fontSize: 16,
      textAlign: 'center',
    },
    Text89f46c03: {
      color: theme.colors.light,
      fontFamily: 'System',
      fontSize: 10,
      fontWeight: '400',
    },
    Text8ee82204: {
      color: theme.colors.strong,
      fontFamily: 'Roboto_400Regular',
      fontSize: 12,
      marginRight: 5,
    },
    TextInput7a13d5df: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      width: '100%',
    },
    Textc15d2f48: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontWeight: '600',
      marginTop: 2,
    },
    View23608970: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 5,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 5,
    },
    View2b224da5: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: 'center',
      maxWidth: '10%',
      minWidth: '10%',
    },
    View4aa3bd0f: { borderBottomWidth: 1, borderColor: theme.colors.strong },
    View4c0cb905: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: 24,
      paddingLeft: 18,
      paddingTop: 24,
    },
    View59b1f5da: { marginLeft: 8 },
    View66f1b932: {
      alignItems: 'center',
      alignSelf: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    View98691c40: {
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: theme.colors.secondary,
      borderBottomWidth: 1,
      borderColor: theme.colors.strong,
    },
    Viewa7e3583d: { alignSelf: 'center', marginTop: 20, width: 200 },
    Viewb4229bb3: {
      alignItems: 'flex-start',
      alignSelf: 'flex-start',
      flexGrow: 0,
      flexShrink: 0,
      width: '70%',
    },
    Viewc992f941: { flex: 1 },
    Viewdebd3207: { flexDirection: 'row' },
    Viewef40d3e7: {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      flex: 1,
      flexDirection: 'row',
      paddingLeft: 12,
      paddingRight: 12,
    },
  });

export default withTheme(FolderContentsScreen);
