import React from 'react';
import * as PivotAnalysisApi from '../apis/PivotAnalysisApi.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Utils from '../utils';
import { Button, Link, ScreenContainer, Spacer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const SignInScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const pivotAnalysisGetTokenPOST = PivotAnalysisApi.useGetTokenPOST();
  const risingCoachesUpdatePushTokenPOST =
    RisingCoachesApi.useUpdatePushTokenPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['auth_header']) {
        return;
      }
      navigation.navigate('NewsFeedScreen');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [emailInput, setEmailInput] = React.useState('');
  const [passwordInput, setPasswordInput] = React.useState('');
  const [styledTextFieldValue, setStyledTextFieldValue] = React.useState('');
  const [styledTextFieldValue2, setStyledTextFieldValue2] = React.useState('');

  return (
    <ScreenContainer hasTopSafeArea={false}>
      <KeyboardAwareScrollView
        style={styles(theme).KeyboardAwareScrollView989db244}
        contentContainerStyle={
          styles(theme).KeyboardAwareScrollView5cf968baContent
        }
      >
        {/* Header */}
        <View>
          {/* Title */}
          <Text style={styles(theme).Text0b899493}>{'Rising Coaches'}</Text>
          {/* Subtitle */}
          <Text style={styles(theme).Textf13dab33}>
            {'Log in to get started'}
          </Text>
        </View>
        {/* Register Form */}
        <View style={styles(theme).View985fb6a8}>
          {/* Error Message */}
          <Text style={styles(theme).Text23ad6e29}>
            {Constants['error_message']}
          </Text>
          {/* Email Input */}
          <TextInput
            onChangeText={newEmailInputValue => {
              try {
                setEmailInput(newEmailInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles(theme).TextInputd5b677c9}
            placeholder={'Email'}
            autoCapitalize={'none'}
            keyboardType={'email-address'}
            textContentType={'emailAddress'}
            placeholderTextColor={theme.colors.light}
          />
          <Spacer top={12} right={8} bottom={8} left={8} />
          {/* Password Input */}
          <TextInput
            onChangeText={newPasswordInputValue => {
              try {
                setPasswordInput(newPasswordInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles(theme).TextInputd5b677c9}
            placeholder={'Password'}
            secureTextEntry={true}
            autoCapitalize={'none'}
            textContentType={'password'}
            returnKeyType={'done'}
            placeholderTextColor={theme.colors.light}
          />
          <Spacer top={24} right={8} bottom={24} left={8} />
          {/* Sign In Button */}
          <>
            {Constants['is_loading'] ? null : (
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: true,
                      });
                      const response = await RisingCoachesApi.loginPOST(
                        Constants,
                        { email: emailInput, password: passwordInput }
                      );
                      const message = response.message;
                      const authDetails = response.authDetails;
                      setGlobalVariableValue({
                        key: 'error_message',
                        value: message,
                      });
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: false,
                      });
                      const authTokenFinal = authDetails.authToken;
                      if (!authTokenFinal) {
                        return;
                      }
                      const user_details = authDetails.user;
                      const profile_image = user_details.profile_image;
                      const url = profile_image.url;
                      setGlobalVariableValue({
                        key: 'user_image',
                        value: url,
                      });
                      const user_id = user_details.id;
                      const name = user_details.name;
                      const email = user_details.email;
                      setGlobalVariableValue({
                        key: 'user_email',
                        value: email,
                      });
                      setGlobalVariableValue({
                        key: 'auth_header',
                        value: authTokenFinal,
                      });
                      setGlobalVariableValue({
                        key: 'user_id',
                        value: user_id,
                      });
                      setGlobalVariableValue({
                        key: 'user_name',
                        value: name,
                      });
                      setEmailInput('');
                      navigation.navigate('NewsFeedScreen');
                      setPasswordInput('');
                      setGlobalVariableValue({
                        key: 'error_message',
                        value: '',
                      });
                      const pivotAuthResponse =
                        await pivotAnalysisGetTokenPOST.mutateAsync();
                      const pivotAuth = pivotAuthResponse.access;
                      setGlobalVariableValue({
                        key: 'pivot_auth_header',
                        value: 'Bearer ' + pivotAuth,
                      });
                      const push_token = await Utils.getPushToken({});
                      await risingCoachesUpdatePushTokenPOST.mutateAsync({
                        expo_push_token: push_token,
                        user_id: user_id,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button413ac881}
                title={'Sign In'}
              >
                {'Sign Up'}
              </Button>
            )}
          </>
          {/* Sign Up Button */}
          <>
            {!Constants['is_loading'] ? null : (
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('NewsFeedScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={styles(theme).Button413ac881}
                title={''}
                disabled={true}
                loading={true}
              >
                {'Sign Up'}
              </Button>
            )}
          </>
          <Spacer top={16} right={8} bottom={16} left={8} />
          <View style={styles(theme).View863e7c01}>
            <Text style={styles(theme).Texte38f1dad}>
              {"Don't have an account?"}
            </Text>
            <Spacer top={8} right={2} bottom={8} left={2} />
            {/* Sign In Link */}
            <Link
              onPress={() => {
                try {
                  navigation.navigate('SignUpScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={styles(theme).Linkd3707c9f}
              title={'Sign up.'}
            />
          </View>

          <View style={styles(theme).View5e0bfbf9}>
            <Text style={styles(theme).Texte38f1dad}>
              {'Forgot your password?'}
            </Text>
            <Spacer top={8} right={2} bottom={8} left={2} />
            {/* Sign In Link */}
            <Link
              onPress={() => {
                try {
                  navigation.navigate('PasswordRecoveryScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={styles(theme).Linkd3707c9f}
              title={'Reset Password.'}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button413ac881: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      textAlign: 'center',
    },
    KeyboardAwareScrollView5cf968baContent: {
      flex: 1,
      justifyContent: 'center',
    },
    Linkd3707c9f: { color: theme.colors.primary },
    Text0b899493: {
      fontFamily: 'System',
      fontSize: 36,
      fontWeight: '600',
      textAlign: 'center',
    },
    Text23ad6e29: {
      color: theme.colors.error,
      fontSize: 12,
      marginBottom: 16,
      textAlign: 'center',
    },
    TextInputd5b677c9: {
      borderBottomWidth: 1,
      borderColor: theme.colors.light,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
    },
    Texte38f1dad: { color: theme.colors.strong, marginRight: 2 },
    Textf13dab33: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontWeight: '400',
      marginTop: 4,
      textAlign: 'center',
    },
    View5e0bfbf9: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 20,
    },
    View863e7c01: { flexDirection: 'row', justifyContent: 'center' },
    View985fb6a8: {
      alignSelf: 'center',
      marginTop: 24,
      maxWidth: 500,
      paddingLeft: 36,
      paddingRight: 36,
    },
  });

export default withTheme(SignInScreen);
