import React from 'react';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { StyleSheet } from 'react-native';

const FeaturedVideoScreen = props => {
  const { theme } = props;

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <WebView
        style={styles(theme).WebViewc992f941}
        javaScriptEnabled={true}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        cacheEnabled={true}
        source={{
          uri: `${
            props.route?.params?.video_url ??
            'https://www.youtube.com/embed/x92WW8GdSnM'
          }`,
        }}
      />
    </ScreenContainer>
  );
};

const styles = theme => StyleSheet.create({ WebViewc992f941: { flex: 1 } });

export default withTheme(FeaturedVideoScreen);
