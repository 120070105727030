import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  IconButton,
  ScreenContainer,
  Spacer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const ContentDetailsScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj.url;
  };

  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesDeleteContentItemDELETE =
    RisingCoachesApi.useDeleteContentItemDELETE();

  const [isActionMenu, setIsActionMenu] = React.useState(false);
  const [new_comment, setNew_comment] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={false}
    >
      <RisingCoachesApi.FetchGetContentDetailsGET
        method={'GET'}
        folder_content_id={props.route?.params?.navigation_content_id ?? ''}
        onData={fetchData => {
          const handler = async () => {
            try {
              await RisingCoachesApi.getContentDetailsGET(Constants, {
                folder_content_id:
                  props.route?.params?.navigation_content_id ?? '',
              });
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
      >
        {({ loading, error, data, refetchGetContentDetails }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              {/* File View */}
              <>
                {!fetchData?.isFile ? null : (
                  <View style={styles(theme).View70b89981}>
                    <>
                      {!fetchData?.isFile ? null : (
                        <WebView
                          style={styles(theme).WebViewc992f941}
                          source={{ uri: `${fetchData?.file?.url}` }}
                        />
                      )}
                    </>
                  </View>
                )}
              </>
              {/* Video View */}
              <>
                {!fetchData?.isVideo ? null : (
                  <View style={styles(theme).View70b89981}>
                    <>
                      {!fetchData?.isVideo ? null : (
                        <WebView
                          style={styles(theme).WebViewc992f941}
                          source={{ uri: `${fetchData?.youtube_render_link}` }}
                        />
                      )}
                    </>
                  </View>
                )}
              </>
              {/* Image View */}
              <>
                {!fetchData?.isImage ? null : (
                  <View style={styles(theme).View70b89981}>
                    <ImageBackground
                      style={styles(theme).ImageBackground4ebf21b5}
                      source={{ uri: `${fetchData?.image?.url}` }}
                      resizeMode={'contain'}
                    />
                  </View>
                )}
              </>
              <Spacer top={12} right={8} bottom={12} left={8} />
              <View>
                <View style={styles(theme).Viewc1eab173}>
                  <Text style={styles(theme).Text00ef0353}>
                    {fetchData?.content_type}
                  </Text>

                  <Text
                    style={styles(theme).Text8cfc6364}
                    textBreakStrategy={'highQuality'}
                    ellipsizeMode={'tail'}
                    allowFontScaling={true}
                    numberOfLines={2}
                  >
                    {fetchData?.content_title}
                  </Text>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  <View>
                    <View style={styles(theme).Viewdebd3207}>
                      <View style={styles(theme).View11851b7e}>
                        <Text style={styles(theme).Text4e9b4f62}>
                          {'Uploaded by '}
                          {fetchData && fetchData['_user']?.name}
                        </Text>
                      </View>
                      <Spacer top={8} right={6} bottom={8} left={6} />
                      <View style={styles(theme).View0efbb706}>
                        <Spacer right={2} left={2} />
                        <Text style={styles(theme).Textde21574d}>
                          {convertDate(fetchData?.created_at)}
                        </Text>
                      </View>

                      <View style={styles(theme).Viewb726a5b4}>
                        <IconButton
                          onPress={() => {
                            try {
                              setIsActionMenu(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={32}
                          icon={'Entypo/dots-three-horizontal'}
                          color={theme.colors.darken}
                        />
                      </View>
                    </View>
                  </View>
                  <Spacer top={12} right={8} bottom={12} left={8} />
                  <View>
                    <Text style={styles(theme).Text3f9840b9}>
                      {'Description'}
                    </Text>
                    <Spacer top={6} right={8} bottom={6} left={8} />
                    <Text style={styles(theme).Textfe0e0a4d}>
                      {fetchData?.text_content}
                    </Text>
                  </View>
                  <Spacer top={12} right={8} bottom={12} left={8} />
                </View>
              </View>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetContentDetailsGET>
      <ActionSheet visible={isActionMenu}>
        {/* Delete Action */}
        <ActionSheetItem
          onPress={() => {
            const handler = async () => {
              try {
                await risingCoachesDeleteContentItemDELETE.mutateAsync({
                  folder_content_id:
                    props.route?.params?.navigation_content_id ?? '',
                });
                setIsActionMenu(false);
                navigation.navigate('ContentRepositoryScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={styles(theme).ActionSheetItemc521608b}
          color={theme.colors.error}
          label={'Delete Item'}
        />
        <ActionSheetCancel
          onPress={() => {
            try {
              setIsActionMenu(false);
            } catch (err) {
              console.error(err);
            }
          }}
          label={'Cancel'}
        />
      </ActionSheet>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    ActionSheetItemc521608b: { textAlign: 'center' },
    ImageBackground4ebf21b5: {
      height: '100%',
      justifyContent: 'flex-end',
      width: '100%',
    },
    Text00ef0353: {
      color: theme.colors.light,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
    },
    Text3f9840b9: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 16,
    },
    Text4e9b4f62: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
      textAlign: 'center',
    },
    Text8cfc6364: {
      color: theme.colors.strong,
      fontFamily: 'Poppins_600SemiBold',
      fontSize: 22,
    },
    Textde21574d: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 12,
    },
    Textfe0e0a4d: {
      color: theme.colors.medium,
      fontFamily: 'Poppins_400Regular',
      fontSize: 14,
      lineHeight: 26,
      textAlign: 'left',
    },
    View0efbb706: {
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View11851b7e: {
      alignItems: 'center',
      alignSelf: 'stretch',
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    View70b89981: { height: 420, width: '100%' },
    Viewb726a5b4: { marginLeft: 30 },
    Viewc1eab173: {
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 0,
    },
    Viewdebd3207: { flexDirection: 'row' },
    WebViewc992f941: { flex: 1 },
  });

export default withTheme(ContentDetailsScreen);
