import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Utils from '../utils';
import {
  Button,
  ScreenContainer,
  Spacer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const ResumeScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;

  const risingCoachesUpdateResumePOST = RisingCoachesApi.useUpdateResumePOST();

  return (
    <ScreenContainer
      style={styles(theme).screen}
      hasSafeArea={false}
      scrollable={false}
    >
      <RisingCoachesApi.FetchGetUserDetailGET
        user_id={props.route?.params?.nav_user_id ?? ''}
      >
        {({ loading, error, data, refetchGetUserDetail }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              <Spacer right={8} left={8} top={8} bottom={8} />
              <View style={styles(theme).View863e7c01}>
                {/* Button Solid */}
                <>
                  {!fetchData?.isMe ? null : (
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const base_64 = await Utils.selectFile({});
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: true,
                            });
                            await risingCoachesUpdateResumePOST.mutateAsync({
                              file_base_64: base_64,
                              user_id: Constants['user_id'],
                            });
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: false,
                            });
                            await refetchGetUserDetail();
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={styles(theme).Button62f11bd3}
                      title={'Update Resume'}
                    />
                  )}
                </>
              </View>
              <Spacer right={8} left={8} top={8} bottom={8} />
              <>
                {!fetchData?.isResume ? null : (
                  <WebView
                    style={styles(theme).WebViewc992f941}
                    source={{ uri: `${fetchData?.resume_file?.url}` }}
                  />
                )}
              </>
              {/* No Resume View */}
              <>
                {fetchData?.isResume ? null : (
                  <View style={styles(theme).View99e98608}>
                    <Text style={styles(theme).Textfd5e5cb9}>
                      {'No Resume Available'}
                    </Text>
                  </View>
                )}
              </>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetUserDetailGET>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button62f11bd3: {
      backgroundColor: theme.colors.secondary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
    Fetch431eb058: { minHeight: 40 },
    Textfd5e5cb9: { alignSelf: 'center', color: theme.colors.surface },
    View863e7c01: { flexDirection: 'row', justifyContent: 'center' },
    View99e98608: {
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    WebViewc992f941: { flex: 1 },
    screen: { backgroundColor: theme.colors.pDFGrey },
  });

export default withTheme(ResumeScreen);
