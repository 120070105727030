import React from 'react';
import Images from '../config/Images';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { ImageBackground, StyleSheet, Text, View } from 'react-native';

const WelcomeScreen = props => {
  const { theme } = props;
  const { navigation } = props;

  return (
    <ScreenContainer
      style={styles(theme).screen}
      scrollable={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
    >
      <ImageBackground
        style={styles(theme).ImageBackgroundcec728d8}
        source={Images.ScreenShot20220627At64622AM}
        resizeMode={'cover'}
      >
        <View>
          <Text style={styles(theme).Text55be32e5}>{'Welcome'}</Text>

          <Text style={styles(theme).Text81d0c1fb}>{'Lets get started.'}</Text>
        </View>
        <View />
        <View style={styles(theme).View9ded7bbf}>
          {/* Button Solid */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('SignUpScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles(theme).Button7fb527be}
            title={'Sign Up'}
          >
            {'Sign Up'}
          </Button>
          {/* Button Outline */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('SignInScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles(theme).Buttondeeb001e}
            title={'Log In'}
          >
            {'Log In'}
          </Button>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button7fb527be: {
      backgroundColor: theme.colors.primary,
      borderRadius: 64,
      fontFamily: 'System',
      fontWeight: '700',
      maxWidth: 250,
      minWidth: 150,
      textAlign: 'center',
    },
    Buttondeeb001e: {
      backgroundColor: 'transparent',
      borderColor: theme.colors.lightInverse,
      borderRadius: 64,
      borderWidth: 1,
      color: theme.colors.strongInverse,
      fontFamily: 'System',
      fontWeight: '700',
      marginTop: 16,
      maxWidth: 250,
      minWidth: 150,
      textAlign: 'center',
    },
    ImageBackgroundcec728d8: {
      alignSelf: 'stretch',
      height: '100%',
      justifyContent: 'space-around',
      paddingLeft: 16,
      paddingRight: 16,
    },
    Text55be32e5: {
      color: theme.colors.strongInverse,
      fontFamily: 'System',
      fontSize: 36,
      fontWeight: '700',
      marginBottom: 8,
      textAlign: 'center',
    },
    Text81d0c1fb: {
      color: theme.colors.strongInverse,
      fontFamily: 'System',
      fontSize: 16,
      fontWeight: '500',
      textAlign: 'center',
    },
    View9ded7bbf: { alignItems: 'center', width: '100%' },
    screen: { alignContent: 'stretch', alignSelf: 'stretch' },
  });

export default withTheme(WelcomeScreen);
