import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Utils from '../utils';
import {
  Button,
  CircleImage,
  Icon,
  Picker,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  Image,
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';

const UploadAdminContentScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const risingCoachesCreateAdminContentPOST =
    RisingCoachesApi.useCreateAdminContentPOST();

  const [descriptionInput, setDescriptionInput] = React.useState('');
  const [fileBase, setFileBase] = React.useState('');
  const [imageBase, setImageBase] = React.useState('');
  const [pickerOptions, setPickerOptions] = React.useState([
    'Scheduling',
    'Strategy/Concepts',
    'Motivation/Leadership',
    'Recruiting',
    'Player Development',
    'Jobs',
  ]);
  const [pickerValue, setPickerValue] = React.useState('');
  const [showFile, setShowFile] = React.useState(false);
  const [showImage, setShowImage] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(true);
  const [titleTextInput, setTitleTextInput] = React.useState('');
  const [videoLinkInput, setVideoLinkInput] = React.useState('');

  return (
    <ScreenContainer scrollable={false} hasSafeArea={false}>
      <KeyboardAvoidingView enabled={true} behavior={'position'}>
        <View>
          <View style={styles(theme).Viewba976a13}>
            <View style={styles(theme).Viewc992f941}>
              {/* GridButtonActive */}
              <>
                {!showVideo ? null : (
                  <Button
                    style={styles(theme).Button30b595d1}
                    title={'Video'}
                  />
                )}
              </>
              {/* GridButtonInactive */}
              <>
                {showVideo ? null : (
                  <Button
                    onPress={() => {
                      try {
                        setShowImage(false);
                        setShowFile(false);
                        setShowVideo(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={styles(theme).Button41cde2db}
                    title={'Video'}
                  />
                )}
              </>
            </View>

            <View style={styles(theme).Viewc992f941}>
              {/* ListButtonActive */}
              <>
                {!showImage ? null : (
                  <Button
                    style={styles(theme).Button30b595d1}
                    title={'Image'}
                  />
                )}
              </>
              {/* ListButtonInactive */}
              <>
                {showImage ? null : (
                  <Button
                    onPress={() => {
                      try {
                        setShowVideo(false);
                        setShowImage(true);
                        setShowFile(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={styles(theme).Button41cde2db}
                    title={'Image'}
                  />
                )}
              </>
            </View>

            <View style={styles(theme).Viewc992f941}>
              {/* ListButtonActive */}
              <>
                {!showFile ? null : (
                  <Button style={styles(theme).Button30b595d1} title={'File'} />
                )}
              </>
              {/* ListButtonInactive */}
              <>
                {showFile ? null : (
                  <Button
                    onPress={() => {
                      try {
                        setShowFile(true);
                        setShowVideo(false);
                        setShowImage(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={styles(theme).Button41cde2db}
                    title={'File'}
                  />
                )}
              </>
            </View>
          </View>
        </View>

        <View style={styles(theme).View69c8f9d6}>
          <View style={styles(theme).View4571e987}>
            {/* File Input */}
            <>
              {!showFile ? null : (
                <View style={styles(theme).Vieweb8a4c38}>
                  <Text style={styles(theme).Texta9275998}>
                    {'Upload Your File'}
                  </Text>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const file_base = await Utils.selectFile({});
                          setFileBase(file_base);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    <View>
                      <>
                        {!imageBase ? null : (
                          <Image
                            style={styles(theme).Image636e6176}
                            source={{ uri: `${fileBase}` }}
                            resizeMode={'cover'}
                          />
                        )}
                      </>
                      <>
                        {fileBase ? null : (
                          <Icon
                            name={'AntDesign/addfile'}
                            size={80}
                            color={theme.colors.secondary}
                          />
                        )}
                      </>
                    </View>
                  </Touchable>
                </View>
              )}
            </>
            {/* Image Input */}
            <>
              {!showImage ? null : (
                <View style={styles(theme).Vieweb8a4c38}>
                  <Text style={styles(theme).Texta9275998}>
                    {'Upload Your Image'}
                  </Text>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const base64_image = await Utils.openImagePicker({
                            mediaTypes: 'Images',
                          });
                          setImageBase(base64_image);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    <View>
                      <>
                        {!imageBase ? null : (
                          <Image
                            style={styles(theme).Image636e6176}
                            source={{ uri: `${imageBase}` }}
                            resizeMode={'cover'}
                          />
                        )}
                      </>
                      <>
                        {imageBase ? null : (
                          <CircleImage source={Images.UploadIcon} size={100} />
                        )}
                      </>
                    </View>
                  </Touchable>
                </View>
              )}
            </>
            <Spacer top={8} right={8} bottom={8} left={8} />
            {/* Video Input */}
            <>
              {!showVideo ? null : (
                <View>
                  <Text style={styles(theme).Text057c17d2}>
                    {'YouTube Video URL Link'}
                  </Text>
                  <TextInput
                    onChangeText={newTextInputValue => {
                      try {
                        setVideoLinkInput(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={styles(theme).TextInputdfe62fea}
                    value={videoLinkInput}
                    placeholder={'Enter the YouTube link...'}
                    placeholderTextColor={theme.colors.light}
                  />
                  <Spacer top={8} right={8} bottom={8} left={8} />
                </View>
              )}
            </>
            <View>
              <Text style={styles(theme).Text057c17d2}>{'Category'}</Text>
              <Picker
                onValueChange={newPickerValue => {
                  try {
                    setPickerValue(newPickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={styles(theme).Pickerb3447f5b}
                options={pickerOptions}
                placeholder={'Select an option'}
                leftIconMode={'inset'}
                type={'solid'}
                iconSize={24}
                placeholderTextColor={theme.colors.light}
              />
            </View>
            <Spacer top={8} right={8} bottom={8} left={8} />
            <View>
              <Text style={styles(theme).Text057c17d2}>{'Post Title'}</Text>
              <TextInput
                onChangeText={newTextInputValue => {
                  try {
                    setTitleTextInput(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={styles(theme).TextInputdfe62fea}
                value={titleTextInput}
                placeholder={'Enter a title...'}
                placeholderTextColor={theme.colors.light}
              />
              <Spacer top={8} right={8} bottom={8} left={8} />
            </View>

            <View>
              <Text style={styles(theme).Text057c17d2}>
                {'Post Description'}
              </Text>
              <TextInput
                onChangeText={newTextAreaValue => {
                  try {
                    setDescriptionInput(newTextAreaValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={styles(theme).TextInputdfe62fea}
                placeholder={'Insert your post details here...'}
                value={descriptionInput}
                multiline={true}
                numberOfLines={4}
                placeholderTextColor={theme.colors.light}
              />
            </View>
            <Spacer top={8} right={8} bottom={8} left={8} />
          </View>

          <View style={styles(theme).View932040af}>
            {/* Button Solid */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    setGlobalVariableValue({
                      key: 'is_loading',
                      value: true,
                    });
                    const response =
                      await risingCoachesCreateAdminContentPOST.mutateAsync({
                        content_title: titleTextInput,
                        content_type: 'Video',
                        content_uploader_id: Constants['user_id'],
                        file_content: fileBase,
                        folder_id:
                          props.route?.params?.navigation_folder_id ?? 1,
                        image_content: imageBase,
                        isFile: showFile,
                        isImage: showImage,
                        isShared: true,
                        isVideo: showVideo,
                        tags: pickerValue,
                        text_content: descriptionInput,
                        youtubeID: videoLinkInput,
                      });
                    navigation.goBack();
                    setGlobalVariableValue({
                      key: 'is_loading',
                      value: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={styles(theme).Buttonb69ef275}
              title={'Add Content'}
            />
          </View>
        </View>
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button30b595d1: {
      backgroundColor: theme.colors.primary,
      borderRadius: 0,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    Button41cde2db: {
      backgroundColor: theme.colors.divider,
      borderRadius: 0,
      color: theme.colors.light,
      fontFamily: 'Roboto_700Bold',
      fontSize: 12,
      textAlign: 'center',
    },
    Buttonb69ef275: {
      backgroundColor: theme.colors.secondary,
      borderRadius: 64,
      fontFamily: 'System',
      fontWeight: '700',
      paddingBottom: 16,
      paddingTop: 16,
      textAlign: 'center',
    },
    Image636e6176: { height: 150, width: 150 },
    Pickerb3447f5b: { fontSize: 12, marginTop: 10, maxHeight: 40 },
    Text057c17d2: {
      color: theme.colors.strong,
      fontFamily: 'System',
      fontWeight: '600',
    },
    TextInputdfe62fea: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      marginTop: 10,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    Texta9275998: {
      alignSelf: 'flex-start',
      color: theme.colors.strong,
      fontFamily: 'System',
      fontWeight: '600',
    },
    View4571e987: { paddingLeft: 40, paddingRight: 40 },
    View69c8f9d6: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      height: '100%',
      justifyContent: 'space-around',
    },
    View932040af: {
      marginBottom: 40,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 10,
    },
    Viewba976a13: {
      alignItems: 'center',
      backgroundColor: theme.colors.divider,
      borderBottomWidth: 1,
      borderColor: theme.colors.lightest,
      borderLeftWidth: 1,
      borderRadius: 14,
      borderRightWidth: 1,
      borderTopWidth: 1,
      flexDirection: 'row',
    },
    Viewc992f941: { flex: 1 },
    Vieweb8a4c38: { alignItems: 'center', justifyContent: 'center' },
  });

export default withTheme(UploadAdminContentScreen);
