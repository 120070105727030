import React from 'react';
import * as CustomCode from '../CustomCode';
import * as AblyChatApi from '../apis/AblyChatApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEmpty from '../global-functions/isEmpty';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const InboxScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const FilterList = list => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    const newList = list.filter(item =>
      item.members_string.toLowerCase().includes(textInputValue2.toLowerCase())
    );

    return newList;
  };

  const { theme } = props;
  const { navigation } = props;

  const [isAll, setIsAll] = React.useState(true);
  const [isSearch, setIsSearch] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} hasTopSafeArea={false}>
      {/* isPaying */}
      <>
        {!Constants['user_paying'] ? null : (
          <View>
            {/* Header View Frame */}
            <View style={styles(theme).Viewdbf79098}>
              {/* Search Bar Component */}
              <View style={styles(theme).View4c0cb905}>
                {/* Left Side Frame */}
                <View style={styles(theme).Viewb4229bb3}>
                  <>
                    {!isSearch ? null : (
                      <TextInput
                        onChangeText={newTextInputValue => {
                          try {
                            setTextInputValue2(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles(theme).TextInput7a13d5df}
                        value={textInputValue2}
                        placeholder={'Find a conversation...'}
                        autoFocus={true}
                      />
                    )}
                  </>
                </View>
                {/* Right Side Frame */}
                <View style={styles(theme).View2b224da5}>
                  {/* Right Icon Touchable 2 */}
                  <>
                    {isSearch ? null : (
                      <Touchable
                        onPress={() => {
                          try {
                            setIsSearch(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Right Icon 2 */}
                        <Icon
                          name={'AntDesign/search1'}
                          size={18}
                          color={theme.colors.custom_rgb189_198_212}
                        />
                      </Touchable>
                    )}
                  </>
                  {/* Right Icon Touchable 2 */}
                  <>
                    {!isSearch ? null : (
                      <Touchable
                        onPress={() => {
                          try {
                            setIsSearch(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Right Icon 2 */}
                        <Icon
                          name={'Feather/x'}
                          size={18}
                          color={theme.colors.custom_rgb189_198_212}
                        />
                      </Touchable>
                    )}
                  </>
                </View>
              </View>
              {/* Nav Bar Additional Configuration */}
              <View style={styles(theme).Viewff15e86f}>
                {/* Left Side Frame */}
                <View style={styles(theme).Viewdf6ad9a1}>
                  <Touchable
                    onPress={() => {
                      try {
                        setIsAll(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Tab 1 All */}
                    <Text style={styles(theme).Texta0584073}>
                      {'Chat Threads'}
                    </Text>
                    <>
                      {!isAll ? null : (
                        <Divider
                          style={styles(theme).Divider5b4057da}
                          color={theme.colors.primary}
                        />
                      )}
                    </>
                  </Touchable>
                </View>
                {/* Right Side Frame */}
                <View style={styles(theme).View9af43e84}>
                  {/* Start New Chat */}
                  <Text style={styles(theme).Text31aee4b3}>
                    {'Start New Chat'}
                  </Text>
                  <IconButton
                    onPress={() => {
                      try {
                        navigation.navigate('StartNewChatScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    icon={'AntDesign/pluscircle'}
                  />
                </View>
              </View>
              {/* Divider Frame */}
              <View>
                {/* Divider List */}
                <View style={styles(theme).Viewd662ea72} />
              </View>
            </View>
            {/* All Scroll View Frame */}
            <>
              {!isAll ? null : (
                <ScrollView
                  style={styles(theme).ScrollView989db244}
                  contentContainerStyle={
                    styles(theme).ScrollViewb313e3e6Content
                  }
                  showsVerticalScrollIndicator={true}
                  bounces={true}
                >
                  <AblyChatApi.FetchGetUserChatroomsGET>
                    {({ loading, error, data, refetchGetUserChatrooms }) => {
                      const fetchData = data;
                      if (!fetchData || loading) {
                        return <ActivityIndicator />;
                      }

                      if (error) {
                        return (
                          <Text style={{ textAlign: 'center' }}>
                            There was a problem fetching this data
                          </Text>
                        );
                      }

                      return (
                        <>
                          {/* No Data */}
                          <>
                            {!isEmpty(fetchData) ? null : (
                              <View style={styles(theme).View4787e944}>
                                <Text style={styles(theme).Text97a79954}>
                                  {'You have not created a folder yet.'}
                                </Text>
                              </View>
                            )}
                          </>
                          <FlatList
                            data={FilterList(fetchData?.items)}
                            listKey={'SE4P84Zo'}
                            keyExtractor={listData =>
                              listData?.id ||
                              listData?.uuid ||
                              JSON.stringify(listData)
                            }
                            renderItem={({ item }) => {
                              const listData = item;
                              return (
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate('ChatScreen', {
                                        chatroom_id: listData?.id,
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* List Record Item Wrapper */}
                                  <View style={styles(theme).View7652c2e9}>
                                    {/* Left Side Wrapper */}
                                    <View style={styles(theme).View69cf6ca2}>
                                      <View>
                                        <View
                                          style={styles(theme).Viewdc2cb7b1}
                                        >
                                          {/* Last Message */}
                                          <Text
                                            style={styles(theme).Text235b407b}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={1}
                                          >
                                            {listData?.members_string}
                                          </Text>
                                        </View>
                                        <FlatList
                                          data={listData?.not_me_members}
                                          listKey={JSON.stringify(
                                            listData?.not_me_members
                                          )}
                                          keyExtractor={listData =>
                                            listData?.id ||
                                            listData?.uuid ||
                                            JSON.stringify(listData)
                                          }
                                          renderItem={({ item }) => {
                                            const listData = item;
                                            return (
                                              <View
                                                style={
                                                  styles(theme).Viewdebd3207
                                                }
                                              >
                                                {/* Light BG */}
                                                <View
                                                  style={
                                                    styles(theme).View60514230
                                                  }
                                                >
                                                  {/* List Item Image */}
                                                  <Image
                                                    style={
                                                      styles(theme)
                                                        .Image8dbb3d0d
                                                    }
                                                    source={{
                                                      uri: `${listData?.profile_image?.url}`,
                                                    }}
                                                    resizeMode={'cover'}
                                                  />
                                                </View>
                                              </View>
                                            );
                                          }}
                                          style={styles(theme).FlatList989db244}
                                          contentContainerStyle={
                                            styles(theme)
                                              .FlatListdc2cb7b1Content
                                          }
                                          numColumns={1}
                                          horizontal={true}
                                        />
                                      </View>
                                      {/* Record Details Frame */}
                                      <View style={styles(theme).View8ac9e7fa}>
                                        {/* Chatroom Name */}
                                        <Text
                                          style={styles(theme).Text026ce2fc}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                        >
                                          {listData?.last_chat_message?.message}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                  <Divider
                                    style={styles(theme).Dividerde11d607}
                                    color={theme.colors.divider}
                                  />
                                </Touchable>
                              );
                            }}
                            numColumns={1}
                          />
                        </>
                      );
                    }}
                  </AblyChatApi.FetchGetUserChatroomsGET>
                </ScrollView>
              )}
            </>
          </View>
        )}
      </>
      {/* NotPaying */}
      <>
        {Constants['user_paying'] ? null : (
          <View>
            {/* PayWall */}
            <View style={styles(theme).View74763ff2}>
              <Text style={styles(theme).Textb17d301e}>
                {
                  'This feature is only available to Rising Coaches members. Join our membership program to take advantage of everything Rising Coaches has to offer.'
                }
              </Text>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://www.risingcoaches.com/membership'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={styles(theme).Button47b42b4a}
                title={'Subscribe'}
              />
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

const styles = theme =>
  StyleSheet.create({
    Button47b42b4a: {
      backgroundColor: theme.colors['Primary'],
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      textAlign: 'center',
    },
    Divider5b4057da: { height: 2, marginRight: 15, marginTop: 3 },
    Dividerde11d607: { height: 1 },
    Fetch431eb058: { minHeight: 40 },
    FlatListdc2cb7b1Content: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
    },
    Image8dbb3d0d: { height: 50, width: 50 },
    ScrollViewb313e3e6Content: { paddingBottom: 24 },
    Text026ce2fc: {
      color: theme.colors.primaryTitleUiBaeg,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 11,
    },
    Text235b407b: {
      alignSelf: 'stretch',
      color: theme.colors.custom_rgb149_158_172,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 9,
      marginTop: 3,
      textAlign: 'center',
    },
    Text31aee4b3: {
      color: theme.colors.strong,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 10,
      marginRight: 10,
    },
    Text97a79954: { color: theme.colors.light, textAlign: 'center' },
    TextInput7a13d5df: {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      width: '100%',
    },
    Texta0584073: {
      color: theme.colors.custom_rgb149_158_172,
      fontFamily: 'Cantarell_400Regular',
      fontSize: 10,
      marginRight: 18,
      textTransform: 'uppercase',
    },
    Textb17d301e: {
      color: theme.colors.strong,
      fontFamily: 'Manrope_700Bold',
      textAlign: 'center',
    },
    View2b224da5: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: 'center',
      maxWidth: '10%',
      minWidth: '10%',
    },
    View4787e944: {
      alignContent: 'center',
      alignSelf: 'center',
      marginTop: 10,
    },
    View4c0cb905: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: 24,
      paddingLeft: 18,
      paddingTop: 24,
    },
    View60514230: {
      alignContent: 'stretch',
      alignItems: 'flex-start',
      alignSelf: 'center',
      backgroundColor: theme.colors.custom_rgb245_245_247,
      borderRadius: 10,
      flexDirection: 'row',
      height: 50,
      width: 50,
    },
    View69cf6ca2: {
      flex: 1,
      flexDirection: 'row',
      flexGrow: 1,
      flexShrink: 0,
      justifyContent: 'space-between',
    },
    View701d2f5c: {
      backgroundColor: theme.colors.custom_rgb191_191_191,
      height: 1,
    },
    View74763ff2: {
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 30,
    },
    View7652c2e9: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      flexGrow: 1,
      flexShrink: 0,
      justifyContent: 'space-between',
      marginBottom: 9,
      marginLeft: 12,
      marginRight: 12,
      marginTop: 9,
    },
    View8ac9e7fa: {
      alignItems: 'flex-end',
      flexGrow: 1,
      flexShrink: 0,
      justifyContent: 'center',
      marginLeft: 18,
    },
    View9af43e84: {
      alignItems: 'center',
      flexDirection: 'row',
      marginRight: 5,
    },
    Viewb4229bb3: {
      alignItems: 'flex-start',
      alignSelf: 'flex-start',
      flexGrow: 0,
      flexShrink: 0,
      width: '70%',
    },
    Viewc178c3fa: {
      backgroundColor: theme.colors.custom_rgb223_223_223,
      height: 6,
    },
    Viewd503657d: {
      backgroundColor: theme.colors.custom_rgb223_223_223,
      height: 3,
    },
    Viewd662ea72: {
      backgroundColor: theme.colors.custom_rgb223_223_223,
      height: 1,
    },
    Viewdbf79098: { flexGrow: 0, flexShrink: 0 },
    Viewdc2cb7b1: {
      alignContent: 'stretch',
      alignSelf: 'stretch',
      flexDirection: 'row',
    },
    Viewdebd3207: { flexDirection: 'row' },
    Viewdf6ad9a1: {
      alignItems: 'center',
      flexDirection: 'row',
      marginLeft: 12,
    },
    Viewff15e86f: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 12,
      paddingTop: 12,
    },
  });

export default withTheme(InboxScreen);
